import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from '../../Util/CommonUtils';
import ViewTransaction from '../Invitations/ViewTransaction';

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        overflowY: 'clip',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        overflowY: 'visible',
        borderRadius: '20px',
        minWidth: '80vh',
        height: '400px',
        opacity: 1,
        backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
    },
    '& .MuiDialogContent-root ': {
        padding: '16px 24px',
        position: 'relative',
        bottom: '80px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function TransactionHistory(props) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const dataMap = props.rowData.visitorTransactions;
    const [data, setData] = useState([]);
    const { t } = useTranslation()
    const { darkMode } = useContext(ThemeContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const scrollbarStyle = {
        height: '258px',
    };

    useEffect(() => {
        axios.get('/web/transaction/list/' + props.rowData.id).then((res) => {
            if (res.data.code === 'CVAI0000') {
                setData(res.data.data);
            }
        });
    }, [open, props.rowData.id]);

    const dateFormat = props.date;

    return (
        <div>
            <div onClick={handleClickOpen}>
                <ViewTransaction />
            </div>
            <Grid>
                <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} darkMode={darkMode}
                    BackdropProps={{
                        style: {
                            backdropFilter: 'blur(8px)',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
                        },
                    }}>
                    <BootstrapDialogTitle onClose={handleClose}>
                        <div className={darkMode ? "CssTextProfileViewTransaction1" : "CssTextProfileViewTransaction"}>{t('VIEWPAGEDEALERTRANSACTIONHISTORY')}</div>
                    </BootstrapDialogTitle>
                    <Divider variant="inset" className="MuiDividerCssTransaction" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
                    <DialogContent style={{ marginLeft: '70px', marginRight: '70px', bottom: '-50px' }}>
                        <PerfectScrollbar options={{ wheelPropagation: true }} style={scrollbarStyle}>
                            <Grid container spacing={2} className="CssTextProfileViewPageContentTransaction" marginTop={0.5} style={{ color: darkMode ? '#fff' : '' }}>
                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={6} className="CssTextProfileViewPageContentValueTransaction1" style={{ whiteSpace: 'nowrap' }}>
                                        {t('DATE_TIME')}
                                    </Grid>
                                    <Grid item xs={6} className="CssTextProfileViewPageContentValueTransaction" style={{ whiteSpace: 'nowrap' }}>
                                        {t('CHECK_IN_AND_CHECK_OUT')}
                                    </Grid>
                                </Grid>
                                {Array.isArray(data) &&
                                    data
                                        .sort((a, b) => moment(a.transactionDateAndTime).valueOf() - moment(b.transactionDateAndTime).valueOf())
                                        .map((transaction) => (
                                            <Grid container spacing={20} p={1.25} key={transaction.id}>
                                                <Grid item xs={6} style={{ whiteSpace: 'nowrap', right: '75px' }}>
                                                    {dateFormat === 'MM-dd-yyyy HH:mm' ? moment(CommonUtil.getLocalDateTimeInYearFormat(transaction.transactionDateAndTime)).format('MM-DD-YYYY HH:mm')
                                                        : dateFormat === 'dd-MM-yyyy HH:mm' ? moment(CommonUtil.getLocalDateTimeInYearFormat(transaction.transactionDateAndTime)).format('DD-MM-YYYY HH:mm')
                                                            : dateFormat === 'yyyy-MM-dd HH:mm' ? moment(CommonUtil.getLocalDateTimeInYearFormat(transaction.transactionDateAndTime)).format('YYYY-MM-DD HH:mm') : ''}
                                                </Grid>
                                                <Grid item xs={6} className="CssTextProfileViewPageContentValue3">
                                                    {transaction.transactionStatus}
                                                </Grid>
                                            </Grid>
                                        ))}
                            </Grid>
                        </PerfectScrollbar>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default TransactionHistory