import React, { useEffect, useState, useContext } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { Box, styled, Tooltip } from "@mui/material";
import yesIcon from "../Checklist/YesNoSvg/Tick.svg"
import NoIcon from "../Checklist/YesNoSvg/Line.svg"
import { addHealthQuestionarie, getAllHealthQuestionarie, updateHealthQuestionarie } from "../../slices/HealthQuestionaries/HealthQuestionariesSlice";
import { useHistory, useLocation } from "react-router-dom";
import AddHealthQuestionaries from "./AddHealthQuestionaries";
import checklistIcon from "../../assets/checklist.svg";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteHealthQuestionaries from "./DeleteHealthQuestionaries";
import DeleteChecklist from "../Checklist/DeleteChecklist";
import { Switch } from "@mui/material";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../Util/CommonUtils";
import { filterRowTreeFromGroupingColumns } from "@mui/x-data-grid-pro/hooks/features/rowGrouping/gridRowGroupingUtils";
import Item from "antd/lib/list/Item";
import { ThemeContext } from '../../theme/ThemeContext';


const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: '#fff',
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
      right: 11
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.light)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      // left: 12,
      fontSize: '12px'
    }
  },

  "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}));


export default function HealthQuestionaries(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const location = useLocation();
  const [openDeleteChecklistItem, setOpenDeletChecklistItem] = useState(false);
  const [openActivateChecklistItem, setOpenActivateChecklistItem] = useState(false);
  const [checklitItemIDToDelete, setChecklitItemIDToDelete] = useState();
  const [checklitItemIDToactivate, setChecklitItemIDToactivate] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory()
  const { setDiaLogBoxBlur } = useContext(ThemeContext);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    templateId: location.state.data.id,
    id: "",
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    id: "",
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging]);

  const handleOpen = () => {
    setOpen(true);
    setDiaLogBoxBlur(true)
  };

  const handleClose = () => {
    setOpen(false);
    setDiaLogBoxBlur(false)
  };

  const loadData = (filter, paging) => {

    dispatch(getAllHealthQuestionarie(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data.data.questionaries);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };


  const [selectedObject, setSelectedObject] = useState({
    id: '',
    companyId: '',
    question: '',
    options: '',
    expertedAnswer: '',
    isMandatory: true,
    disabled: false,
    templateId: location.state.data.id,
  });


  const handleChangeSwitch = (e, data) => {
    if (e.target.checked) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
    const payload = {
      id: data.id,
      question: data.question,
      options: data.options,
      expertedAnswer: data.expertedAnswer,
      mandatory: data.mandatory,
      templateId: location.state.data.id,
      isDisabled: e.target.checked ? false : true,
    };

    dispatch(updateHealthQuestionarie(payload))
      .unwrap()
      .then((data) => {
        handleClose(loadData(filter, paging));
        if (payload.isDisabled === false) {
          Toast(t('CHECK_LIST_ENABLED'), 'success')
        } else if (payload.isDisabled === true) {
          Toast(t('CHECK_LIST_DISABLED'), 'success')
        }
      });
  }


  const handleEditVisit = (params) => {
    if (params.disabled === true) {
      setOpenEditDialog(openEditDialog);
    }
    else {
      setOpenEditDialog(!openEditDialog);
      setDiaLogBoxBlur(true)
    }
    setSelectedObject(params);
  };

  const handleDeleteChecklistItem = (params) => {
    if (params.disabled === true) {
      setOpenEditDialog(openEditDialog);
    } else {
      setOpenDeletChecklistItem(!openDeleteChecklistItem);
      setDiaLogBoxBlur(true)
    }
    setChecklitItemIDToDelete(params.id);
  };

  const globalsearch = (searchedVal) => {
    const lowercaseSearchedVal = searchedVal.toLowerCase();
    const filteredRows = rows.filter((test) => {
      return (
        test.expertedAnswer.toLowerCase().includes(lowercaseSearchedVal) ||
        test.question.toLowerCase().includes(lowercaseSearchedVal) ||
        (test.options).find(item => item.toLowerCase().includes(lowercaseSearchedVal))
      );
    });
    setRows(filteredRows);
  };
  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...';
    } else {
      return text;
    }
  }
  const handleTextOverflow1 = (text) => {
    if (text && text.length > 10) {
      return text.substring(0, 10) + '...';
    } else {
      return text;
    }
  }
  const columns = React.useMemo(() => [

    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "question",
      headerName: t('tableColumnHeaderItem'),
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.question,
      renderCell: (params) => (
        <Tooltip title={` ${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "options",
      headerName: t('slectLabeloptions'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.options,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.options}`} placement="bottom-start">
          <span>{handleTextOverflow1(params.row.options)}</span>
        </Tooltip>
      ),
    },

    {
      field: "expertedAnswer",
      headerName: t('EXPECTED_ANSWER'),
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.expertedAnswer,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.expertedAnswer}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.expertedAnswer)}</span>
        </Tooltip>
      ),
    },

    {
      field: "mandatory",
      headerName: t('tableColumnHeaderMandatory'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params.row.mandatory === "Yes" ? <div> <img src={yesIcon} /> </div>
        : params.row.mandatory === "No" ? <div><img src={NoIcon} /> </div> : params.row.mandatory,
    },

    {
      field: "Actions",
      headerName: t('ACTION'),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}

        />,

        <GridActionsCellItem
          icon={<DeleteChecklist />}
          label="Delete"
          onClick={() => handleDeleteChecklistItem(params.row)}
        />,

        <GridActionsCellItem
          icon={<Tooltip title={params.row.disabled === false ? t('DISABLE_ITEM') : t('ENABLE_ITEM')}>
            <Android12Switch checked={params.row.disabled ? false : true} />
          </Tooltip>}
          onChange={(e) => handleChangeSwitch(e, params.row)}
        />,
      ],
    },
  ]);

  return (
    <>
      <HeaderToolbar src={checklistIcon} back_button={true}
        onClick={handleOpen}
        title={t('CHECKLIST003')}
        tooltipTitle={t('CHECKLIST002')} />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>



      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddHealthQuestionaries
          selectedObject={selectedObject}
          templateId={location.state.data.id}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
            setDiaLogBoxBlur(false)
          }}
        />
      )},

      {openEditDialog && (
        <AddHealthQuestionaries
          selectedObject={selectedObject}
          open={openEditDialog}
          templateId={location.state.data.id}
          handleClose={() => {
            setOpenEditDialog(false);
            setDiaLogBoxBlur(false)
            setSelectedObject({
              id: "",
              name: "",
              companyId: "",
              description: "",
              question: "",
              options: "",
              expertedAnswer: "",
              isMandatory: true,
            });
            loadData(filter, paging);
          }}
        />
      )},

      {openDeleteChecklistItem && (
        <DeleteHealthQuestionaries
          open={openDeleteChecklistItem}
          handleClose={(data) => {
            setOpenDeletChecklistItem(data);
            handleClose(loadData(filter, paging));
            setDiaLogBoxBlur(false)
          }}
          DeleteId={checklitItemIDToDelete}
        />
      )}

    </>
  )
}


