import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import ViewIcon from '../../Pages/Invitations/ViewIcon';
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';
import { getSiteById } from '../../slices/Site/SiteSlice';
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from '../../Util/CommonUtils';

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        overflowY: 'clip',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        overflowY: 'visible',
        borderRadius: '20px',
        minWidth: '120vh',
        height: '584px',
        opacity: 1,
        backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
    },
    '& .MuiDialogContent-root ': {
        padding: '16px 24px',
        position: ' relative',
        bottom: '80px',
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function SelfRegistrationApprovalView(props) {
    const [open, setOpen] = React.useState(false);
    const [siteName, setSiteName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [createDate, setCreateDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const rowDataMode = props.rowData.visitMode
    const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        loadSiteName(props.rowData.siteId);
        setOpen(true);
    };

    if (open) {
        setDiaLogBoxBlur(true)
    }

    const handleClose = () => {
        setOpen(false);
        setDiaLogBoxBlur(false)
    };

    useEffect(() => {
        setEndDate(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YY kk:mm"));
        setStartDate(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("DD-MM-YY kk:mm"));
        setCreateDate(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.createdAt)).format("DD-MM-YY kk:mm"));
    }, [])

    const loadSiteName = (siteId) => {
        dispatch(getSiteById(siteId))
            .unwrap().then((data) => {
                if (data.code === 'LMSI6000') {
                    console.log(JSON.stringify(data))
                    setSiteName(data.data.name);
                }
                else {
                    setSiteName('');
                }
            })
    };

    const inviteDate = () => {
        if (props.rowData.visitMode === 'SELF_REGISTARTION' || props.rowData.visitMode === 'WALKIN_REGISTARTION_KIOSK') {
            if (props.rowData.status === "CONFIRMED" || props.rowData.status === "REJECTED") {
                return createDate;
            }
            return startDate;
        }
        return startDate;
    }

    const inviteEndDate = () => {
        if (props.rowData.visitMode === 'SELF_REGISTARTION' || props.rowData.visitMode === 'WALKIN_REGISTARTION_KIOSK') {
            return " ";
        }
        return endDate;
    }
    const handleTextOverflow = (text) => {
        if (text && text.length > 20) {
            return text.substring(0, 20) + '...';
        } else {
            return text;
        }
    }

    const [date, setDate] = useState(false)
    useEffect(() => {
        dispatch(getListSettings(''))
            .unwrap()
            .then((data) => {
                if ((data.code === "CVAI0000")) {
                    //   setSelfIntervalTime(data.data.selfIntervalTime);
                    setDate(data.data.dateFormat)
                }
            })
    }, []);


    return (
        <div >
            <div onClick={handleClickOpen} >
                <ViewIcon />

            </div>
            <Grid >
                <BootstrapDialog
                    // onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    darkMode={darkMode}
                    BackdropProps={{
                        style: {
                            backdropFilter: 'blur(2px)',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        },
                    }}
                >
                    <BootstrapDialogTitle onClose={handleClose} style={{ color: darkMode ? '#fff' : '' }}>
                        <Avatar alt={props.rowData.visitor.firstName} src={props.rowData.visitor.profilePhotoURL !== undefined ? props.rowData.visitor.profilePhotoURL : null} sx={{ width: 150, height: 150, bottom: '80px', margin: 'auto', border: 'solid #FFFF', borderWidth: '7px', }} />
                        <div className='CssTextProfileViewPageName' style={{ color: darkMode ? '#fff' : '' }} >
                            {props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName : props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName}
                        </div>
                        <div className='CssTextProfileViewPagePhone' style={{ color: darkMode ? '#fff' : '' }}>
                            {props.rowData.visitor.phone}
                        </div>
                    </BootstrapDialogTitle>
                    <Divider variant="inset" className='MuiDividerCss' sx={{ backgroundColor: darkMode ? '#fff' : '' }} />
                    <DialogContent style={{ marginLeft: '70px', marginRight: '70px', height: '400px', overflow: 'hidden' }}>
                        <PerfectScrollbar options={{ wheelPropagation: true }}>
                            < Grid container spacing={12.25} className='CssTextProfileViewPageContent' marginTop={0.5} style={{ color: darkMode ? '#fff' : '' }}>
                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={3} >Email</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Tooltip title={props.rowData.visitor.email}>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {handleTextOverflow(props.rowData.visitor.email)}
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={3}>Host</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Tooltip title={props.rowData.hostName}>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {handleTextOverflow(props.rowData.hostName)}
                                        </Grid>
                                    </Tooltip>
                                </Grid>

                                <Grid container spacing={2} p={1.25} >
                                    <Grid item xs={3}>Purpose Type</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.purposeType?.name}</Grid>
                                </Grid>
                                <Grid container spacing={2} p={1.25} >
                                    <Grid item xs={3}>Visitor Type</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.visitorType?.name}</Grid>
                                </Grid>
                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={3}>Site</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Tooltip title={siteName}>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {handleTextOverflow(siteName)}
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                                <Grid
                                    container spacing={2} p={1.25}>
                                    <Grid item xs={3}>Company Name</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Tooltip title={props.rowData.visitor.visitorCompanyName}>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {handleTextOverflow(props.rowData.visitor.visitorCompanyName)}
                                        </Grid>
                                    </Tooltip>
                                </Grid>

                                {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                                    <Grid container spacing={2} p={1.25}
                                    >
                                        <Grid item xs={3}>Type</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                            props.rowData.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' :
                                                props.rowData.visitMode === "INVITATION" ? "Invitation" :
                                                    props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ? "Walkin Registration" :
                                                        props.rowData.visitMode === "SELF_REGISTARTION" ? "Self Registration" :
                                                            props.rowData.visitMode}</Grid>
                                    </Grid>
                                    :

                                    <Grid container spacing={2} p={1.25}
                                    >
                                        <Grid item xs={3}>Type</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                            props.rowData.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' :
                                                props.rowData.visitMode === "INVITATION" ? "Invitation" :
                                                    props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ? "Walkin Registration" :
                                                        props.rowData.visitMode === "SELF_REGISTARTION" ? "Self Registration" :
                                                            props.rowData.visitMode}</Grid>
                                    </Grid>
                                }

                                <Grid
                                    container spacing={2} p={1.25}>
                                    <Grid item xs={3}>Status</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>

                                        <span style={{ color: '#EDBD37', backgroundColor: '#EDBD3726', opacity: 1 }}>
                                            {props.rowData.status === "SCHEDULED" ? "Scheduled" : ""}
                                        </span>

                                        <span style={{ color: '#A6C212', backgroundColor: '#A6C21226', opacity: 1 }}>
                                            {props.rowData.status === "CONFIRMED" ? "Confirmed" : ""}
                                        </span>

                                        <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
                                            {props.rowData.status === "CANCELLED" ? "Cancelled" : ""}
                                        </span>

                                        <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
                                            {props.rowData.status === "REJECTED" ? "Rejected" : ""}
                                        </span>

                                        <span style={{ color: '#287ADC', backgroundColor: '#287ADC26', opacity: 1 }}>
                                            {props.rowData.status === "CHECKED_IN" ? "Check In" : ""}
                                        </span>

                                        <span style={{ color: '#03ACBF', backgroundColor: '#03ACBF26', opacity: 1 }}>
                                            {props.rowData.status === "CHECKED_OUT" ? "Check Out" : ""}
                                        </span>

                                        <span style={{ color: '#36C96D', backgroundColor: '#36C96D26', opacity: 1 }}>
                                            {props.rowData.status === "APPROVED" ? "Approved" : ""}
                                        </span>

                                        <span style={{ color: '#ED8637', backgroundColor: '#ED863726', opacity: 1 }}>
                                            {props.rowData.status === "MISSED" ? "Missed" : ""}
                                        </span>

                                        <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
                                            {props.rowData.status === "BLOCKED" ? "Blacklist" : ""}
                                        </span>

                                        <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
                                            {props.rowData.status === "INITIATED_FOR_BLOCKED" ? "Blacklist Initiated" : ""}
                                        </span>

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={3} >Invite Start Date</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                        {date === "MM-dd-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("MM-DD-YYYY HH:mm")
                                            : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("DD-MM-YYYY HH:mm")
                                                : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("YYYY-MM-DD HH:mm") : ''}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1.25}>
                                    <Grid item xs={3}>Invite End Date</Grid>
                                    <Grid item xs={3}>:</Grid>
                                    <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                        {date === "MM-dd-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("MM-DD-YYYY HH:mm")
                                            : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YYYY HH:mm")
                                                : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("YYYY-MM-DD HH:mm") : ''}
                                    </Grid>
                                </Grid>

                                {props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ?
                                    <Grid
                                        container spacing={2} p={1.25}>
                                        <Grid item xs={3}>Remarks</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {props.rowData.remarks}
                                        </Grid>
                                    </Grid>
                                    : ""}

                                {props.rowData.visitMode === "WALKIN_REGISTARTION" ?
                                    <Grid
                                        container spacing={2} p={1.25}>
                                        <Grid item xs={3}>Remarks</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {props.rowData.approvalReason}
                                        </Grid>
                                    </Grid>
                                    : ""}

                                {props.rowData.visitMode === "INVITATION" ?
                                    <Grid
                                        container spacing={2} p={1.25}>
                                        <Grid item xs={3}>Remarks</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                            {props.rowData.approvalReason}
                                        </Grid>
                                    </Grid>
                                    : ""}

                                {props.rowData.visitMode === "SELF_REGISTARTION" ?
                                    <Grid
                                        container spacing={2} p={1.25}>
                                        <Grid item xs={3}>Remarks</Grid>
                                        <Grid item xs={3}>:</Grid>
                                        <Tooltip title={props.rowData.remarks}>
                                            <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                                {handleTextOverflow(props.rowData.remarks)}
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                    : ""}

                            </Grid>
                        </PerfectScrollbar>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default SelfRegistrationApprovalView