import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import adminDashboardService from '../../services/DashboardPanel/DashboardPanelService'

const initialState = []

export const getAccountStatics = createAsyncThunk(
  'account/statics',
  async (payload) => {
    const res = await adminDashboardService.getAccountStatics(payload)
    return res.data
  },
)

export const getAccountList = createAsyncThunk('accounts', async (payload) => {
  const res = await adminDashboardService.getAccountList(payload)
  return res.data
})

export const postChildSwitchCompany = createAsyncThunk('Impersonate', async (payload) => {
  const res = await adminDashboardService.postChildSwitchCompany(payload)
  return res.data
})

const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  extraReducers: {
    [getAccountStatics.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },
  },
})

const { reducer } = adminDashboardSlice
export default reducer
