import { Grid } from "@material-ui/core";
import { DialogContent, Typography } from "@mui/material";
import React, { useContext, useState } from "react";

// import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import DeleteIcon from "../../Pages/Watchlist/DeleteIcon";
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg';
import { ThemeContext } from '../../theme/ThemeContext';
import DeleteDialogBox from "./DeleteDialogBox";

export default function DeleteDialog(props) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);

    const handleSubmit = () => {
        handleClose();
        props.handleDelete();
    };

    const handleOpen = () => {
        setOpen(true)
        setDiaLogBoxBlur(true)
    }

    const handleClose = () => {
        setOpen(false)
        setDiaLogBoxBlur(false)
    }

    return (
        <>
            <Grid onClick={handleOpen}>
                <DeleteIcon />
            </Grid>
            <DeleteDialogBox
                Header={
                    <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                        {t("COMMON004")}
                    </span>
                }
                acceptText={t("confirmVisitorButton")}
                cancelText={t("dataTableCancel")}
                fullWidth={true}
                onClose={handleClose}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Grid >
                        <DeleteIcon width="60px" height={'50px'} />
                    </Grid>
                    <Grid p={2} style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', fontSize: '17px' }}>
                        <Typography gutterBottom>
                            {t("COMMON018")}
                        </Typography>
                        {/* <Typography style={{ fontWeight: "bold" }}>
                            {props.deviceName} 
                        </Typography> */}
                    </Grid>
                </DialogContent>
            </DeleteDialogBox>
        </>
    );
}
