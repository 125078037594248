import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import '../../App.css'
import copyIcon from '../../assets/USA_Images/copyIcon.svg'
import DataTable from '../../components/DataTable/DataTable'
import KeyValidityDialog from '../../components/DialogBoxComponent/KeyValidityDialog'
import Toast from '../../components/ToastContainer/CustomToast'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  generateAPIKey,
  getAPIKeys,
} from '../../slices/Integrations/IntegrationSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'

function Integration() {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(0)
  const [date, setDate] = useState('')
  const [noteable, setNoteable] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext)

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const renderCellScheduledValidFrom = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.validFrom),
    ).format(
      date === 'dd-MM-yyyy HH:mm'
        ? 'DD-MM-YYYY'
        : date === 'MM-dd-yyyy HH:mm'
          ? 'MM-DD-YYYY'
          : date === 'yyyy-MM-dd HH:mm'
            ? 'YYYY-MM-DD'
            : '',
    )
    return <span className="table-cell-trucate">{formattedDate}</span>
  }

  const renderCellScheduledValidTo = (date) => (params) => {
    if (params.row.validTo !== undefined && params.row.validTo !== null) {
      const formattedDate = moment(
        CommonUtil.getLocalDateTimeInYearFormat(params.row.validTo),
      ).format(
        date === 'dd-MM-yyyy HH:mm'
          ? 'DD-MM-YYYY'
          : date === 'MM-dd-yyyy HH:mm'
            ? 'MM-DD-YYYY'
            : date === 'yyyy-MM-dd HH:mm'
              ? 'YYYY-MM-DD'
              : '',
      )
      return <span className="table-cell-trucate">{formattedDate}</span>
    }
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content)
  }

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.dateFormat))
        }
      })
  }, [])

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm'
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm'
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm'
      default:
        return 'yyyy-MM-dd HH:mm'
    }
  }

  useEffect(() => {
    loadAPIKeys(paging)
  }, [paging])

  const handleGenerateKey = () => {
    if (rows.length > 0) {
      setOpenDialog(!openDialog)
      setDiaLogBoxBlur(true)
      return
    } else {
      generateAPIAccessKey()
    }
  }

  const handleRegenerateKeys = (keyValidity) => {
    generateAPIAccessKey(keyValidity)
    setOpenDialog(!openDialog)
  }

  const generateAPIAccessKey = (keyValidity) => {
    const apiKeyPayload = {
      status: true,
      companyId: localStorage.getItem('companyId'),
      validTill: keyValidity || 7,
    }
    dispatch(generateAPIKey(apiKeyPayload))
      .unwrap()
      .then((data) => {
        if ((data.code = 'MSGS4001' && data.data)) {
          Toast(data.message, 'success')
          loadAPIKeys(paging)
        }
      })
      .catch((er) => { })
  }

  const loadAPIKeys = () => {
    const payload = {
      pageNumber: paging.page,
      pageSize: paging.rowsPerPage,
      companyId: localStorage.getItem('companyId'),
    }
    dispatch(getAPIKeys(payload))
      .unwrap()
      .then((data) => {
        if (
          data.code === 'MSGS4000' &&
          data.data.companyAccessKeys.length > 0
        ) {
          setTotalRecords(data.data.totalCount)
          setRows(data.data.companyAccessKeys)
          setPage(data.data.currentPage)
          setPageSize(data.data.pageSize)
          setTotalPages(data.data.totalPages)
          setNoteable(true)
        } else {
          setRows([])
        }
        setLoading(false)
      })
      .catch((er) => { })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 25) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
    },
    {
      field: 'accessKey',
      headerName: t('API_KEY'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.accessKey,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.accessKey}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.accessKey)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: t('API_KEY_STATUS'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.status === true ? 'ACTIVE' : 'INACTIVE',
    },
    {
      field: 'validFrom',
      headerName: t('VALID_FROM'),
      flex: 1,
      width: 150,
      renderCell: renderCellScheduledValidFrom(date),
    },
    {
      field: 'validTo',
      headerName: t('VALID_TO'),
      flex: 1,
      width: 150,
      renderCell: renderCellScheduledValidTo(date),
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <Tooltip title={t('COPY_TOOL_TIP')}>
          <GridActionsCellItem
            icon={<img src={copyIcon} alt="Copy icon" />}
            onClick={() => handleCopy(params.row.accessKey)}
          />
        </Tooltip>,
      ],
    },
  ])

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '100px, 0' }}>
            <Typography
              variant="h3"
              style={{
                marginLeft: darkMode ? '33px' : '0px',
                marginRight: darkMode ? '18px' : '12px',
                color: darkMode ? '#fff' : '#3D4977',
                marginTop: darkMode ? '3px' : '4px',
              }}
            >
              {t('API_KEYS')}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{ marginRight: darkMode ? '20px' : '' }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Button
            onClick={handleGenerateKey}
            size="large"
            variant="outlined"
            style={{
              color: '#FFFFFF',
              backgroundColor: '#F2824C',
              border: '2px solid #F2824C',
              padding: '10px, 20px',
            }}
          >
            {!noteable ? (
              <Typography>{t('GENERATE')}</Typography>
            ) : (
              <Typography>{t('RE-GENERATE')}</Typography>
            )}
          </Button>
        </Grid>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <div style={{ height: 300, width: '100%', marginTop: '10px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.accessKey}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openDialog && (
        <KeyValidityDialog
          open={openDialog}
          onSubmit={handleRegenerateKeys}
          onClose={() => {
            setOpenDialog(false);
            setDiaLogBoxBlur(false);
          }}
        />
      )}
    </>
  )
}

export default Integration
