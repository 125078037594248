import { Button, Typography } from '@material-ui/core'
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import jwt_decode from 'jwt-decode'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as ProfileEditIcon } from '../../assets/ProfileEditIcon.svg'
import { ReactComponent as EditIcon } from '../../assets/USA_Images/login_images_visitor/EditIcon.svg'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import ProfileContext from '../../components/ProfileContext'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  getListSettingsById
} from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  getCompanyInfo,
  updateCompanyInfo,
} from '../../slices/Company/CompanySlice'
import { getDealerBillingInfo } from '../../slices/Dealer/DealerSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import { countryData } from '../Person/AddressFormData'
import UploadCompanyProfile from '../Person/UploadCompanyProfile'
function CompanyProfile() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [country, setCountry] = useState('')

  const [company, setCompany] = useState({
    id: '',
    name: '',
    registrationNumber: '',
    owner: '',
    code: '',
    industry: '',
    companySize: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    address: '',
    addressLine2: '',
    createdAt: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    companyLogo: null,
    companyImageData: '',
    billingUserName: '',
    billingUserCountryCode: '',
    billingUserPhone: '',
    billingUserEmail: '',
    billingAddress: '',
    billingCountry: '',
    billingState: '',
    billingCity: '',
    billingZipcode: '',
    imagePreSignedURL: '',
    companyImagePreSignedUrl: '',
    companyImageFile: null,
  })

  const [error, setError] = useState({
    name: false,
    registrationNumber: false,
    owner: false,
    industry: false,
    companySize: false,
    email: false,
    phone: false,
    country: false,
    address: false,
    addressLine2: false,
    creationTime: false,
    city: false,
    state: false,
    pincode: false,
    createdAt: false,
    billingUserName: false,
    billingUserCountryCode: false,
    billingUserPhone: false,
    billingUserEmail: false,
    billingAddress: false,
    billingCountry: false,
    billingState: false,
    billingCity: false,
    billingZipcode: false,
  })
  const [profileImage, setProfileImage] = React.useState()
  const [profile, setProfile] = useState('')
  const [phoneInput, setPhoneInput] = useState('')
  const [billingUserPhoneInput, setBillingUserPhoneInput] = useState('')
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [countries, setCountries] = React.useState([])
  const { setCompanyProfilePic } = useContext(ProfileContext)
  const { setCompanyProfile } = useContext(ProfileContext)
  const [isEditable, setIsEditable] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null)
  const [companyType, setCompanyType] = useState('')
  const [date, setDate] = useState()
  const companyId = localStorage.getItem('companyId')
  const companyTypeValue = localStorage.getItem('companyType')
  const { darkMode } = useContext(ThemeContext)
  useEffect(() => {
    loadCompany()
    loadSetting()
  }, [dispatch])

  useEffect(() => {
    setCountries(countryData)
    setInavalidprofilePhoto(false)
    var decoded_jwtToken = jwt_decode(
      localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    )

    setCompanyType(decoded_jwtToken.companyType)
    if (
      decoded_jwtToken.roleCode != 'OWNER' &&
      decoded_jwtToken.roleCode != 'ADMIN'
    ) {
      setIsEditable(true)
    }
  }, [])

  const handleProfile = (file) => {
    if (file) {
      setCompany({
        ...company,
        companyLogo: company.name,
        companyImageData: file.base64,
        companyImageFile: file.file,
      })
    } else {
      setCompany({
        ...company,
        companyLogo: '',
        companyImageData: '',
        companyImageFile: null,
      })
    }
    setProfile(file.base64)
    setProfileImage(file.file)
  }

  const loadCompany = useCallback(() => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0026') {
          const phoneCountryCode = data.data.phone
            ? data.data.phone.split('-')[0]
            : ''
          const phone = data.data.phone ? data.data.phone.split('-')[1] : ''
          const companyImagePreSignedUrl = data.data.imagePreSignedURL
          const imagePreSignedURL = data.data.imagePreSignedURL
          const companyLogo = data.data.imagePreSignedURL ? data.data.name : ''
          const companyImageFile = data.data.imagePreSignedURL ? profile : null
          setCompany((prevCompany) => ({
            ...prevCompany,
            ...data.data,
            phoneCountryCode,
            phone,
            companyImagePreSignedUrl,
            companyLogo,
            companyImageFile,
          }))

          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)

          if (countryCode && phone.length <= 6 && fullCountryName) {
            setCountry(countryCode)
            setPhoneInput('')
          } else if (!fullCountryName && phone.length <= 6) {
            setCountry('us')
            setPhoneInput('')
          } else if (phone.length <= 6) {
            setPhoneInput('')
          } else {
            setPhoneInput(data.data.phone)
          }

          setProfile(data.data.imagePreSignedURL)

          const selectedCountry = countryData.find(
            (country) => country.name === data.data.country,
          )
          setSelectedCountry(selectedCountry)
        }
      })
      .catch((er) => {
        // handle error
      })
  }, [dispatch])

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (companyTypeValue === 'NONE' || companyTypeValue === 'PARTNER') {
            setDate(data.data.adminAndDealerDateFormat)
          } else {
            setDate(data.data.dateFormat)
          }
        }
      })
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  useEffect(() => {
    var decoded_jwtToken = jwt_decode(
      localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    )

    dispatch(getDealerBillingInfo(decoded_jwtToken.companyId))
      .unwrap()
      .then((billingData) => {
        setCompany((prevData) => ({
          ...prevData,
          billingUserName: billingData.data.firstName,
          billingUserEmail: billingData.data.email,
          billingAddress: billingData.data.addressLine1,
          billingState: billingData.data.stateOrProvince,
          billingCity: billingData.data.city,
          billingUserCountryCode: billingData.data.phone
            ? billingData.data.phone.split('-')[0]
            : '',
          billingUserPhone: billingData.data.phone
            ? billingData.data.phone.split('-')[1]
            : '',
          billingZipcode: billingData.data.areaCode,
          billingCountry: billingData.data.country,
        }))
        setBillingUserPhoneInput(billingData.data.phone)
        const fullCountryName = billingData.data.country
        const countryCode = getCountryCodeFromName(fullCountryName)
        if (
          countryCode &&
          billingData.data.phone.length <= 6 &&
          fullCountryName !== ''
        ) {
          setCountry(countryCode)
          setSelectedBillingCountry('')
        }
        if (fullCountryName === '' && billingData.data.phone.length <= 6) {
          setCountry('us')
          setSelectedBillingCountry('')
        } else if (billingData.data.phone.length <= 6) {
          setSelectedBillingCountry('')
        } else {
          setBillingUserPhoneInput(billingData.data.phone)
        }
        const selectedCountry = countryData.find(
          (country) => country.name === billingData.data.country,
        )
        setSelectedBillingCountry(selectedCountry)
      })
  }, [dispatch])

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const uploadCompanyProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.imagePreSignedURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        setCompanyProfilePic(profile)
      })
      .catch(function (response) { })
  }

  const updateCompany = () => {
    const formData = new FormData()

    // Append each field to the FormData object
    for (const key in company) {
      // Skip null or undefined values
      if (company[key] != null) {
        formData.append(key, company[key])
      }
    }

    // Append the file if it exists
    if (company.companyImageFile !== null) {
      formData.append('companyImageFile', company.companyImageFile)
    }

    // Log FormData for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }
    dispatch(updateCompanyInfo(formData))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASE0060') {
          Toast(data.message, 'error')
        } else if (data.code === 'UASI0007') {
          uploadCompanyProfilePhoto(data.data)
          Toast(data.message, 'success')
          setEditMode(true)
          setCompanyProfile(company)
          loadCompany()
        } else {
          toast.error(data.message)
        }
      })
      .catch((er) => { })
  }

  const handleClickEdit = () => {
    setEditMode(editMode ? false : true)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setCompany({
      ...company,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }
  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    //
    setCompany((prevData) => ({
      ...prevData,
      phoneCountryCode: '+' + data.dialCode,
      phone: value.toString().replace(data.dialCode, ''),
    }))
    setError({ phone: false })
  }

  const handleBillingUserPhoneChange = (value, data, event, formattedValue) => {
    setBillingUserPhoneInput(value)
    setCompany({
      ...company,
      billingUserCountryCode: '+' + data.dialCode,
      billingUserPhone: value.toString().replace(data.dialCode, ''),
    })
    setError({ billingUserPhone: false })
  }

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value)
    setCompany({
      ...company,
      country: value.name,
    })
  }

  const onSubmit = (data) => {
    if (CommonUtil.isEmptyString(company.name)) {
      Toast(t('DEALERCOMPANYERROR'), 'error')
      setError({ ...error, name: true })
      return false
    }
    if (!CommonUtil.isValidNumeric(company.companySize)) {
      Toast(t('COMPANYSIZENUMBER'), 'error')
      setError({ ...error, companySize: true })
      return
    }

    if (!CommonUtil.isValidEmail(company.email)) {
      Toast(t('DEALEREMAILERROR'), 'error')
      setError({ ...error, email: true })
      return
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? ''
        : CommonUtil.isEmptyString(company.code)
    ) {
      Toast(t('COMPANYCODE'), 'error')
      setError({ ...error, code: true })
      return false
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? ''
        : CommonUtil.isEmptyString(company.registrationNumber)
    ) {
      Toast(t('REGISTRATIONNUMBER'), 'error')
      setError({ ...error, registrationNumber: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingUserName)
    ) {
      Toast(t('DEALERBILLINGNAME'), 'error')
      setError({ ...error, billingUserName: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingUserEmail)
    ) {
      Toast(t('DEALERBILLINGEMAIL'), 'error')
      setError({ ...error, billingUserEmail: true })
      return false
    }

    if (!CommonUtil.isAlphaNumericSpace(company.pincode)) {
      Toast(t('BILLING_PINCODE_VALIDATION'), 'error')
      setError({ ...error, pincode: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isValidName(company.billingUserEmail)
    ) {
      Toast(t('DEALERBILLINGVALIDEMAIL'), 'error')
      setError({ ...error, billingUserEmail: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingUserPhone)
    ) {
      Toast(t('DEALERBILLINGPHONENUMBER'), 'error')
      setError({ ...error, billingUserPhone: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingAddress)
    ) {
      Toast(t('DEALERBILLINGADDRESS'), 'error')
      setError({ ...error, billingAddress: true })
      return false
    }
    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingCountry)
    ) {
      Toast(t('DEALERBILLINGCOUNTRY'), 'error')
      setError({ ...error, billingCountry: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingState)
    ) {
      Toast(t('DEALERBILLINGSTATEERROR'), 'error')
      setError({ ...error, billingState: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingCity)
    ) {
      Toast(t('DEALERBILLINGCITYERROR'), 'error')
      setError({ ...error, billingAddress: true })
      return false
    }

    if (
      companyType === 'PARTNER' &&
      CommonUtil.isEmptyString(company.billingZipcode)
    ) {
      Toast(t('DEALERBILLINGZIPCODE'), 'error')
      setError({ ...error, billingZipcode: true })
      return false
    }
    if (
      companyType === 'PARTNER' &&
      !CommonUtil.isAlphaNumericSpace(company.billingZipcode)
    ) {
      Toast(t('BILLING_PINCODE_VALIDATION'), 'error')
      setError({ ...error, billingZipcode: true })
      return false
    }

    if (!InavalidprofilePhoto) {
      setError({ ...error, billingZipcode: false })
      updateCompany()
    } else {
      Toast('Image size should be less than 2MB', 'error')
    }
  }

  const handleBillingCountryChange = (event, value) => {
    setSelectedBillingCountry(value)
    if (value === null) {
      setCompany({
        ...company,
        billingCountry: '',
      })
    } else {
      setCompany({
        ...company,
        billingCountry: value.name,
      })
    }
  }

  const onCancel = () => {
    setEditMode(true)
    loadCompany()
  }

  const handleUpdatePaymentMethod = () => {
    if (window.location.origin === 'https://login.mysmartguardplus.com') {
      window.open('https://billing.stripe.com/p/login/7sI9CFd0S7xQ8HC8ww')
    } else {
      window.open('https://billing.stripe.com/p/login/test_4gw29Z1PobHO4aA288')
    }
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: darkMode ? '#021C31' : '#fff',
        }}
      >
        <Box sx={{ m: 2 }}>
          <Typography
            gutterBottom
            variant="h3"
            style={{
              color: darkMode ? '#fff' : '#3D4977',
              marginLeft: darkMode ? '32px' : '0px',
            }}
          >
            {t('COMPPROFSCREEN006')}
          </Typography>
        </Box>
        <Grid>
          {!isEditable ? (
            <div
              style={{
                backgroundColor: darkMode ? '#0F273B' : '#F2F2F6',
                marginRight: darkMode ? '10px' : '0px',
              }}
            >
              <Tooltip
                title={
                  <Typography fontSize={12} fontWeight={500}>
                    {t('COMMON005')}
                  </Typography>
                }
              >
                <IconButton onClick={handleClickEdit}>
                  {darkMode ? <EditIcon /> : <ProfileEditIcon />}
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Paper
        style={{
          backgroundColor: darkMode ? '#0F273B' : '#fff',
          marginLeft: darkMode ? '31px' : '0px',
          marginRight: darkMode ? '10px' : '0px',
        }}
      >
        <Grid container p={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={4}
            sm={12}
          >
            <Grid xl={6}>
              <UploadCompanyProfile
                onsetProfile={(profile) => handleProfile(profile)}
                companyLogo={profile}
                disabled={editMode}
              />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('VISUPDATE0006')}
                name={'name'}
                value={company.name}
                disabled={editMode}
                required={true}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('COMPPROFSCREEN010')}
                name={'industry'}
                value={company.industry}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('COMPPROFSCREEN012')}
                name={'companySize'}
                value={company.companySize}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            {
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfield
                  label={t('COMPPROFSCREEN014')}
                  name={'code'}
                  value={company.code}
                  validation="code"
                  disabled={true}
                  required={true}
                  handleChange={handleChange}
                />
              </Grid>
            }

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              ''
            ) : (
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfield
                  label={t('COMP0002')}
                  name={'registrationNumber'}
                  value={company.registrationNumber}
                  validation="alpha-numeric"
                  disabled={editMode}
                  required={true}
                  handleChange={handleChange}
                />
              </Grid>
            )}

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('COMMON042')}
                name={'email'}
                value={company.email}
                disabled={true}
                handleChange={handleChange}
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <SelfRegistrationCustomPhone
                placeholder="Mobile"
                disabled={editMode}
                value={phoneInput}
                label={''}
                country={country}
                specialLabel={false}
                inputProps={{ maxLength: 15 }}
                handleChange={(value, data, event, formattedValue) =>
                  handlePhoneChange(value, data, event, formattedValue)
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('ADD001')}
                name={'address'}
                value={company.address}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('ADD005')}
                name={'city'}
                value={company.city}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('ADD004')}
                name={'state'}
                value={company.state}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <Autocomplete
                disabled={editMode}
                options={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('ADD003')}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: darkMode ? '#fff' : '#0F273B', // Set text color based on dark mode
                      },
                      '& .MuiInputLabel-root': {
                        color: darkMode ? '#fff' : '#0F273B', // Set label color based on dark mode
                      },

                      '& .MuiInputBase-input.Mui-disabled': {
                        opacity: 1, // Ensure full visibility for disabled state
                        WebkitTextFillColor: darkMode && editMode
                          ? 'white' // Fix for WebKit browsers when darkMode is true and editMode is false
                          : 'rgba(0, 0, 0, 0.38)', // Dark color for WebKit when darkMode is false or editMode is true
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: '#B3B3B3', // Label color for disabled state
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Border color when focused
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#B3B3B3', // Border color for disabled state
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfield
                label={t('DEALERZIPCODE')}
                name={'pincode'}
                // validation="numericWithSpace"
                value={company.pincode}
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 10 }}
                error={error.pincode}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  {(date === 'MM-dd-yyyy' || date === 'MM-DD-YYYY') && (
                    <DesktopDatePicker
                      label={t('CREATIONTIME')}
                      name={'creationTime'}
                      inputFormat={'MM-dd-yyyy'}
                      disabled={true}
                      value={company.createdAt}
                      onChange={(createdAt) =>
                        setCompany({
                          ...company,
                          createdAt: createdAt,
                        })
                      }
                      renderInput={(params) => <CustomTextfield {...params} />}
                    />
                  )}
                  {(date === 'dd-MM-yyyy' || date === 'DD-MM-YYYY') && (
                    <DesktopDatePicker
                      label={t('CREATIONTIME')}
                      name={'creationTime'}
                      inputFormat={'dd-MM-yyyy'}
                      disabled={true}
                      value={company.createdAt}
                      onChange={(createdAt) =>
                        setCompany({
                          ...company,
                          createdAt: createdAt,
                        })
                      }
                      renderInput={(params) => <CustomTextfield {...params} />}
                    />
                  )}
                  {(date === 'yyyy-MM-dd' || date === 'YYYY-MM-DD') && (
                    <DesktopDatePicker
                      label={t('CREATIONTIME')}
                      name={'creationTime'}
                      inputFormat={'yyyy-MM-dd'}
                      disabled={true}
                      value={company.createdAt}
                      onChange={(createdAt) =>
                        setCompany({
                          ...company,
                          createdAt: createdAt,
                        })
                      }
                      renderInput={(params) => <CustomTextfield {...params} />}
                    />
                  )}
                </Stack>
              </LocalizationProvider>
            </Grid>

            {companyType === 'PARTNER' && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h4">
                      {t('DEALERBILLINGINFO')}
                    </Typography>
                    <Button
                      disabled={editMode}
                      onClick={handleUpdatePaymentMethod}
                      style={{
                        height: '28px',
                        backgroundColor: '#f4772e',
                        color: 'white',
                      }}
                    >
                      Update Payment Method
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfield
                    label={t('COMMON014')}
                    name={'billingUserName'}
                    value={company.billingUserName}
                    disabled={editMode}
                    required={true}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfield
                    label={t('COMMON042')}
                    name={'billingUserEmail'}
                    value={company.billingUserEmail}
                    disabled={editMode}
                    handleChange={handleChange}
                    required={true}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
                  <SelfRegistrationCustomPhone
                    placeholder="Mobile"
                    disabled={editMode}
                    value={billingUserPhoneInput}
                    label={t('COMMON063')}
                    country={'us'}
                    specialLabel={false}
                    inputProps={{ maxLength: 15 }}
                    handleChange={(value, data, event, formattedValue) =>
                      handleBillingUserPhoneChange(
                        value,
                        data,
                        event,
                        formattedValue,
                      )
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfield
                    label={t('ADD012')}
                    name={'billingAddress'}
                    value={company.billingAddress}
                    disabled={editMode}
                    handleChange={handleChange}
                    required={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
                  <Autocomplete
                    options={countries}
                    value={selectedBillingCountry}
                    required={true}
                    error={error.billingCountry}
                    disabled={editMode}
                    onChange={handleBillingCountryChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t('INVITATION006')} />
                    )}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfield
                    label={t('ADD004')}
                    name={'billingState'}
                    value={company.billingState}
                    error={error.billingState}
                    handleChange={handleChange}
                    disabled={editMode}
                    required={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfield
                    label={t('ADD005')}
                    name={'billingCity'}
                    value={company.billingCity}
                    error={error.billingCity}
                    handleChange={handleChange}
                    disabled={editMode}
                    required={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfield
                    label={t('DEALERZIPCODE')}
                    name={'billingZipcode'}
                    value={company.billingZipcode}
                    disabled={editMode}
                    error={error.billingZipcode}
                    // validation="numericWithSpace"
                    handleChange={handleChange}
                    required={true}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>{' '}
              </>
            )}
          </Grid>

          <Grid container lg={4}></Grid>
          {!editMode ? (
            <Grid container lg={8} justifyContent="center">
              <Grid pr={2} pt={4}>
                <SubmitButtons
                  variant="contained"
                  disable={editMode}
                  onClick={onSubmit}
                >
                  <Typography>{t('COMMON035')}</Typography>
                </SubmitButtons>
              </Grid>
              <Grid pt={4}>
                <CancelButtons
                  disable={editMode}
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                >
                  <Typography style={{ textTransform: 'none' }}>
                    {t('COMMON008')}
                  </Typography>
                </CancelButtons>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CompanyProfile
