import {
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions
} from '@mui/material'
import jwt_decode from 'jwt-decode'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../../src/assets/FilterIcon.svg'
import CommonUtil from '../../Util/CommonUtils'
import { allHostList } from '../../slices/HostSlice'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import AutocompleteComponent from '../CustomInputs/AutocompleteComponent'
import Toast from '../ToastContainer/CustomToast'
import DialogBox from './DialogBox'

export default function FilterForm(props) {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [hostList, setHostList] = useState([])
  const [selectedHost, setSelectHost] = useState([])
  const [purposeData, setPurposeData] = useState([])
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const [payload, setPayload] = useState({
    hostId: '',
    purposeTypeId: '',
    status: '',
    visitMode: props.visitMode,
  })

  const handleClose = () => {
    setSelectHost('')
    setPayload({
      hostId: '',
      purposeTypeId: '',
      status: '',
      visitMode: props.selectedObject,
    })
    setOpen(false)
    setDiaLogBoxBlur(false)
  }

  const [error, setError] = useState({
    hostId: false,
    purposeTypeId: false,
  })

  if (open) {
    setDiaLogBoxBlur(true)
  }
  const reset = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleCountryChange = (event, value) => {
    setPayload({
      ...payload,
      status: value,
    })
  }

  const statusValue = props.status
  console.log(statusValue)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadPurposeType()
    loadHosts()
  }

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      email: decoded_jwtToken.email,
    }
    dispatch(allHostList(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'OMSI0000') {
          //  getSelectedItem(data.data.employees[0]);
          setHostList(data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  const handleSubmit = () => {
    if (
      payload.purposeTypeId.trim() === '' &&
      payload.hostId.trim() === '' &&
      payload.status === ''
    ) {
      Toast(t('COMMON015'), 'error')
      return
    }

    props.applyFilter(payload)
    setSelectHost('')
    setPayload({
      hostId: '',
      purposeTypeId: '',
      status: '',
    })
    handleClose()
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event.id,
    })
  }

  const onselectHost = (event) => {
    setPayload({
      ...payload,
      hostId: event.id,
    })
  }

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      })
      setSelectHost(newValue)
    } else {
      setPayload({
        ...payload,
        hostId: '',
      })
      setSelectHost({})
    }
  }

  const handleText = (event) => {
    if (CommonUtil.isValidEmails(event.target.value)) {
      console.log(event.target.value)
      const payload = {
        pageNumber: 1,
        pageSize: 0,
        email: event.target.value,
      }
      dispatch(allHostList(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'OMSI0000') {
            if (!CommonUtil.isEmptyArray(data.data)) {
              // getSelectedItem(data.data.employees[0]);
              setHostList(data.data.employees)
            } else {
              setHostList([])
              getSelectedItem(null)
            }
          }
        })
    }
  }

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email }) => `${firstName} ${email}`,
    limit: 1,
  })

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <FilterForm />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={<span style={{ color: darkMode ? '#fff' : '#3D4977' }}>{t('COMMON006')}</span>}
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid container justifyContent="center" alignItems={'center'} style={{ backgroundColor: darkMode ? "#0F273B" : "#fff" }}>
          <Grid item xs={12} sm={9} sx={{ mt: 5 }}>
            <Autocomplete
              style={{
                margin: '-15px',
                marginLeft: '1px',
                color: '#242424',
              }}
              noOptionsText={'No Options found'}
              fullWidth
              filterOptions={filterOptions}
              options={CommonUtil.isEmptyArray(hostList) ? [] : hostList}
              getOptionLabel={(option) =>
                option.lastName
                  ? option.firstName + ' ' + option.lastName
                  : option.firstName
                    ? option.firstName
                    : ''
              }
              value={selectedHost}
              onChange={(event, newValue) => {
                getSelectedItem(newValue)
                setError({ selectedHost: false })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('commonsTextFeildHostValue')}
                  required={false}
                  placeholder="Host"
                  name="hostId"
                  key="Confirmation Code"
                  id="hostId"
                  handleChange={(e) => handleText(e)}
                  error={error.selectedHost}
                  InputLabelProps={{
                    style: { color: darkMode ? '#fff' : '#707070' },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#787c82', borderColor: darkMode ? '#fff' : '#787c82' },
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#a5a7a8',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#707070',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#707070',
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 5 }}>
            <AutocompleteComponent
              label={t('PURPOSETYPE')}
              name="purposeTypeId"
              error={error.purposeTypeId}
              value={payload.purposeTypeId}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectPurposeType(e)}
              options={purposeData}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 5, mb: 2 }}>
            <Autocomplete
              options={CommonUtil.isEmptyArray(statusValue) ? [] : statusValue}
              onChange={handleCountryChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('COMMON001')}
                  InputLabelProps={{
                    style: { color: darkMode ? '#fff' : '#707070' }, // Label color
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#707070' }, // Value color
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#a5a7a8', // Border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#707070', // Border color on hover
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: darkMode ? '#fff' : '#707070', // Border color when focused
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  )
}
