import { Autocomplete, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../components/Util/CommonUtils";
import { getBlackListType } from "../../slices/BlacklistType/BlacklistTypeSlice";
import { blacklistVisitor } from "../../slices/BlacklistVisitor/BlacklistVisitorSlice";
import { ThemeContext } from '../../theme/ThemeContext';

export default function BlockVisitorForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [blacklistData, setBlacklistData] = useState([]);
  const [restrictReasonType, setRestrictReasonType] = useState(null);
  const [restrictReason, setRestrictReason] = useState();
  const [restrictReasonTypeTd, setRestrictReasonTypeId] = useState();
  const { darkMode } = useContext(ThemeContext);
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = useState({
    visitId: props.BlacklistVisitorId,
    restrictedBy: decoded_jwtToken.firstName + " " + decoded_jwtToken.lastName,
    restrictReasonType: "",
    restrictReason: props.restrictReason,
  });

  const [error, setError] = React.useState({
    restrictReasonType: false,
    restrictReason: false,
    removeAccess: false,
  });

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setPayload({
  //     ...payload,
  //     [name]: event.target.value,
  //     [name]: event.target.checked,
  //   });
  // };

  const [removeAccess, setRemoveAccess] = useState(props.removeAccess);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    setPayload({
      ...payload,
      restrictReasonType: restrictReasonTypeTd,
      restrictReason: props.restrictReason,
    });
  }, [restrictReasonType]);



  const handleChange1 = (event) => {
    setRemoveAccess(event.target.checked);
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.checked,
    });
  };
  const [selectedBlackList, setSelectedBlackList] = useState(null);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(restrictReasonTypeTd)) {
      Toast(t("VISSTATUST"), "error");
      setError({ restrictReasonType: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.restrictReason)) {
      Toast(t("VISSTATUST1"), "error");
      setError({ restrictReason: true });
      return false;
    }

    dispatch(blacklistVisitor(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE1046") {
          Toast(t("COMMON073"), "success");
          props.disable();
          handleClose();
        } else if (data.code === "CVAE1049") {
          Toast(data.message, "success");
          props.disable();
          handleClose();
        }
        else {
          Toast(data.message, "error");
          handleClose();
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadBlacklistType();
  };

  const loadBlacklistType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getBlackListType(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setBlacklistData(data.data.purposeTypes);
        } else {
          setBlacklistData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const onselectBlacklistType = (event) => {
  //   setPayload({
  //     ...payload,
  //     restrictReasonType: event.id,
  //   });
  // };
  const onselectBlacklistType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        restrictReasonType: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        restrictReasonType: ''
      })
    }
  }

  useEffect(() => {
    const defaultValue = blacklistData.find((value) => value.id === props.restrictReasonType);
    if (defaultValue?.id) {
      setRestrictReasonType(defaultValue);
      setRestrictReasonTypeId(defaultValue.id);
      setPayload({
        ...payload,
        restrictReasonType: defaultValue.id
      })
    }
  }, [props.restrictReasonType, blacklistData])


  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {process.env.REACT_APP_ENVIRONMENT === "USA"
              ? t("BLOCKEDVIS004")
              : "Block Visitor"}
          </span>
        }

        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >

        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid item xs={10} sm={10} style={{ marginTop: "10px" }}>
            <Autocomplete
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='restrictReasonType'
              value={restrictReasonType}
              options={blacklistData}
              getOptionLabel={(option) => option.name ? option.name : ''}
              onChange={(event, newValue) => {
                if (newValue !== null) {// eslint-disable-next-line
                  setPayload({ ...payload, restrictReasonType: newValue.id });
                  setRestrictReasonTypeId(newValue.id)
                  setRestrictReasonType(newValue)
                } else {
                  setPayload({
                    ...payload,
                    restrictReasonType: ''
                  });
                  setRestrictReasonType()
                  setRestrictReasonTypeId("")
                }
              }}
              renderInput={(params) => (
                <CustomTextfield {...params} name='Blacklist Type' error={error.restrictReasonType} variant='outlined' fullWidth label={"Blacklist Type *"} />
              )}
            />


          </Grid>

          <Grid item xs={10} sm={10} mt={2.5}>
            <CustomTextfield
              type={"text"}
              label={t("BLOCKEDVIS006")}
              error={error.restrictReason}
              name="restrictReason"
              value={payload.restrictReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
