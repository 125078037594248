import { Autocomplete, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomPhone from "../../components/CustomInputs/CustomPhone";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import { createWatchlistTemplate, updateWatchlistTemplate } from "../../slices/WatchlistGlobal/WatchlistGlobalSlice";
import CommonUtil from "../../Util/CommonUtils";
import SelfRegistrationCustomPhone from "../OnBoardV2/SelfRegistrationCustomPhone";
import { makeStyles } from '@material-ui/core'
import { ThemeContext } from '../../theme/ThemeContext';
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { Divider } from "antd";

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

export default function WatchlistTemplateForm(props) {
  const { open, handleClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phoneInput, setPhoneInput] = useState("");
  const [phoneInput1, setPhoneInput1] = useState("");
  const [phoneInput2, setPhoneInput2] = useState("");
  const [purposeData, setPurposeData] = useState([]);
  const [purType, setPurType] = useState('');
  const [str, setStr] = useState("");
  const [secondContact, setSecondContact] = useState("");
  const [firstContact, setFirstContact] = useState("");
  const [country, setCountry] = useState(props.country)
  const { darkMode } = useContext(ThemeContext);

  const [payload, setPayload] = useState({
    id: "",
    email: "",
    phoneNumber: "",
    phoneCountryCode: "",
    firstName: "",
    lastName: "",
    visitorCompanyName: "",
    purposeTypeId: "",
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
  });

  const [addSecondContact, setAddSecondContact] = useState({
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
  });

  const [emptyContact, setEmptyContact] = useState({
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
  })

  const [error, setError] = React.useState({
    email: false,
    phoneNumber: false,
    firstName: false,
    lastName: false,
    visitorCompanyName: false,
    contactEmail: false,
    contactPhoneNumber: false,
    purposeTypeId: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleChangeSecondContact = (event) => {
    const name = event.target.name;
    setAddSecondContact({
      ...payload,
      [name]: event.target.value,
    });
    setEmptyContact({
      ...emptyContact,
      contactEmail: event.target.value
    });

    setError({
      [name]: false,
    });
  };


  const handleSubmit = () => {
    if (!CommonUtil.isValidNames(payload.firstName)) {
      setError({ ...error, firstName: true });
      Toast(t("FIRSTNAME"), "error");
      return;
    }

    if (!CommonUtil.isValidNames(payload.lastName)) {
      setError({ ...error, lastName: true });
      Toast(t("LASTNAME"), "error");
      return;
    }

    if (!CommonUtil.isValidMobile(payload.phoneNumber)) {
      setError({ ...error, phoneNumber: true });
      Toast(t("MOBILE"), "error");
      return;
    }

    if (!CommonUtil.isValidEmails1(payload.email)) {
      setError({ ...error, email: true });
      Toast(t("CORRECTEMAIL"), "error");
      return;
    }
    if (!CommonUtil.isValidEmails1(payload.contactEmail)) {
      setError({ ...error, contactEmail: true });
      Toast(t("CORRECTEMAIL"), "error");
      return;
    }

    if (!CommonUtil.isValidEmails1(addSecondContact.contactEmail)) {
      setError({ ...error, contactEmail: true });
      Toast(t("CORRECTEMAIL"), "error");
      return;
    }

    if (!CommonUtil.isValidMobile(payload.contactPhoneNumber)) {
      setError({ ...error, contactPhoneNumber: true });
      Toast(t("MOBILE"), "error");
      return;
    }
    if (CommonUtil.isEmpty(payload.firstName) && CommonUtil.isEmpty(payload.lastName) && CommonUtil.isEmpty(payload.email) && CommonUtil.isEmpty(payload.visitorCompanyName) && CommonUtil.isEmpty(payload.phoneNumber) && CommonUtil.isEmpty(payload.purposeTypeId)) {
      Toast(t("At least one Watchlist keyword is required"), "error");
      return;
    }

    if (CommonUtil.isEmpty(payload.contactEmail) && CommonUtil.isEmpty(payload.contactPhoneNumber)) {
      setError({ ...error, contactEmail: true });
      Toast(t("Either Contact email or mobile number is mandatory"), "error");
      return;
    }
    if (isOpened && CommonUtil.isEmpty(addSecondContact.contactEmail) && CommonUtil.isEmpty(addSecondContact.secondContact)) {
      Toast(t("Either another Contact email or mobile number is mandatory"), "error");
      return;
    }
    if (!(payload.contactEmail === "" && addSecondContact.contactEmail === "") && (payload.contactEmail === addSecondContact.contactEmail)) {
      Toast("Contact emails should not be same", "error");
      return;
    }

    if (!(firstContact === "" && secondContact === "") && (firstContact === secondContact) && CommonUtil.isEmptyString(props.selectedObject.id)) {
      Toast("Contact mobile number can not be same", "error");
      return;
    }

    if (!(payload.phoneNumber === "" && payload.contactPhoneNumber === "") && (payload.phoneNumber === payload.contactPhoneNumber)) {
      Toast("Phone Number and Contact phone can not be same", "error");
      return;
    }

    if (!(payload.phoneNumber === "" && secondContact === "") && (payload.phoneNumber === secondContact) && addSecondContact.contactEmail !== "") {
      Toast("Phone Number and Contact phone can not be same", "error");
      return;
    }

    if (!(payload.email === "" && addSecondContact.contactEmail === "") && (payload.email === addSecondContact.contactEmail)) {
      Toast("Email ID and Contact email can not be same", "error");
      return;
    }

    if (!(payload.contactEmail === "" && payload.email === "") && (payload.contactEmail === payload.email)) {
      Toast("Email ID and Contact email can not be same", "error");
      return;
    }

    if (!(payload.phoneNumber === "" && payload.contactPhoneNumber === "") && (payload.phoneNumber === payload.contactPhoneNumber)) {
      Toast("Phone Number and Contact phone can not be same", "error");
      return;
    }

    if (!(payload.phoneNumber === "" && addSecondContact.contactPhoneNumber === "") && (payload.phoneNumber === addSecondContact.contactPhoneNumber)) {
      Toast("Phone Number and Contact phone can not be same", "error");
      return;
    }

    if (!(payload.phoneNumber === "" && addSecondContact.contactPhoneNumber === "") && (payload.phoneNumber === addSecondContact.contactPhoneNumber && addSecondContact.contactEmail !== "")) {
      Toast("Phone Number and Contact phone can not be same", "error");
      return;
    }



    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      let newTemplate = {};
      if (
        addSecondContact.contactEmail !== "" ||
        addSecondContact.contactPhoneNumber !== ""
      ) {
        newTemplate = {
          watchListId: props.selectedObject.id,
          email: payload.email,
          phoneCountryCode: payload.phoneCountryCode,
          phoneNumber: payload.phoneNumber,
          firstName: payload.firstName,
          lastName: payload.lastName,
          visitorCompanyName: payload.visitorCompanyName,
          contactDetails: [
            {
              contactEmail: payload.contactEmail,
              contactPhoneCountryCode: payload.contactPhoneCountryCode,
              contactPhoneNumber: payload.contactPhoneNumber,
            },
          ],
          purposeTypeId: payload.purposeTypeId.id,
        };
        if (phoneInput2 === "") {
          newTemplate.contactDetails.push(emptyContact);
        }
        else {
          newTemplate.contactDetails.push(addSecondContact);
        }
      } else {
        newTemplate = {
          watchListId: props.selectedObject.id,
          email: payload.email,
          phoneCountryCode: payload.phoneCountryCode,
          phoneNumber: payload.phoneNumber,
          firstName: payload.firstName,
          lastName: payload.lastName,
          visitorCompanyName: payload.visitorCompanyName,
          contactDetails: [
            {
              contactEmail: payload.contactEmail,
              contactPhoneCountryCode: payload.contactPhoneCountryCode,
              contactPhoneNumber: payload.contactPhoneNumber,
            },
          ],
          purposeTypeId: payload.purposeTypeId.id,
        };
      }
      dispatch(createWatchlistTemplate(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI2000") {
            Toast(t("WATCHLISTSUCCESS"), "success");
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    } else {
      const newTemplate = {
        watchListId: props.selectedObject.id,
        email: payload.email,
        phoneCountryCode: payload.phoneCountryCode,
        phoneNumber: payload.phoneNumber,
        firstName: payload.firstName,
        lastName: payload.lastName,
        visitorCompanyName: payload.visitorCompanyName,
        contactEmail: payload.contactEmail,
        contactPhoneCountryCode: payload.contactPhoneCountryCode,
        contactPhoneNumber: payload.contactPhoneNumber,
        purposeTypeId: payload.purposeTypeId.id,
      };
      dispatch(updateWatchlistTemplate(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI2002") {
            Toast(t("WATCHLISTUPDATE"), "success");
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setPurType(purposeData.find((type) => {
      return type.id === props.selectedObject.purposeTypeId;
    }))
  }, [purposeData?.length > 0])

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        watchListId: props.selectedObject.id,
        email: props.selectedObject.email,
        phoneCountryCode: props.selectedObject.phoneCountryCode,
        phoneNumber: props.selectedObject.phoneNumber,
        firstName: props.selectedObject.firstName,
        lastName: props.selectedObject.lastName,
        visitorCompanyName: props.selectedObject.visitorCompanyName,
        purposeTypeId: purType,
        contactEmail: props.selectedObject.contactEmail,
        contactPhoneCountryCode: props.selectedObject.contactPhoneCountryCode,
        contactPhoneNumber: props.selectedObject.contactPhoneNumber,

      });
      setPhoneInput(props.selectedObject.phoneCountryCode + props.selectedObject.phoneNumber);
      setPhoneInput1(props.selectedObject.contactPhoneCountryCode + props.selectedObject.contactPhoneNumber);
      setPhoneInput2(props.selectedObject.contactPhoneCountryCode + props.selectedObject.contactPhoneNumber);
      setSecondContact(props.selectedObject.contactPhoneNumber);
      setFirstContact(props.selectedObject.contactPhoneNumber)
    }
  }, [!CommonUtil.isEmptyString(purType)]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadPurposeType();
  };

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const [isOpened, setIsOpened] = useState(false);
  const handleAddContact = () => {
    setIsOpened(true);
  };
  const handleRemoveContact = () => {
    setAddSecondContact({
      contactEmail: "",
      contactPhoneNumber: "",
    });
    setPhoneInput2('')
    setSecondContact('')
    setFirstContact('')
    setIsOpened(false);
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value);
    setPayload({
      ...payload,
      phoneCountryCode: "+" + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ""),
    });
    setError({ phoneNumber: false });
  }

  const handlePhoneChange1 = (value, data, event, formattedValue) => {
    setPhoneInput1(value);
    setFirstContact(value.toString().replace(data.dialCode, ""))
    setPayload({
      ...payload,
      contactPhoneCountryCode: "+" + data.dialCode,
      contactPhoneNumber: value.toString().replace(data.dialCode, ""),
    });
    setError({ phoneNumber: false });
  }

  const handlePhoneChange2 = (value, data, event, formattedValue) => {
    setPhoneInput2(value);
    setSecondContact(value.toString().replace(data.dialCode, ""))
    setAddSecondContact({
      ...addSecondContact,
      contactPhoneCountryCode: "+" + data.dialCode,
      contactPhoneNumber: value.toString().replace(data.dialCode, ""),
    });
    setError({ phoneNumber: false });
  }

  return (
    <>
      <DialogBox
        // Header={payload.watchListId ? t("WATCHLIST006") : t("WATCHLIST005")}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {payload.watchListId ? t("WATCHLIST006") : t("WATCHLIST005")}
          </span>
        }
        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px !important",
            minWidth: "55vw",
            color: "#3D4977",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '#fff' }}
          spacing={2}
        >
          <Divider style={{backgroundColor: darkMode ? "#fff" : ""}}/>
          <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
            <CustomTextfield
              variant="outlined"
              label={t("COMMON020")}
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ className: classes.input, maxLength: 50 }}
              style={{ margin: "1px" }}
              fullWidth
            />

          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
            <CustomTextfield
              variant="outlined"
              label={t("COMMON021")}
              error={error.lastName}
              name="lastName"
              value={payload.lastName}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ className: classes.input, maxLength: 50 }}
              style={{ margin: "1px" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: "29px" }}>
            <SelfRegistrationCustomPhone
              placeholder="Mobile"
              value={phoneInput}
              country={country}
              specialLabel={false}
              inputProps={{ className: classes.input }}
              handleChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
            <CustomTextfield
              label={t("COMMON042")}
              name="email"
              value={payload.email}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ className: classes.input, maxLength: 50 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
            <Autocomplete
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='purposeTypeId'
              value={payload.purposeTypeId}
              options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
              getOptionLabel={(option) => option.name ? option.name : ''}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                  onselectPurposeType(newValue);
                } else {
                  onselectPurposeType('');
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined' fullWidth label={'Purpose Type'}
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                      },
                      '&:hover fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px", display: "flex" }}>
            <Grid item xs={6}>
              <Typography
                href="#"
                style={{
                  color: darkMode ? "#fff" : "#242424",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {t("PERSONINFO")} {""}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography href="#" style={{ color: darkMode ? "#fff" : "#242424", opacity: "0.4" }}>
                {t("WATCHLISTNOTIFY")}
              </Typography>
            </Grid>
          </Grid>

          {/* next row */}

          <Grid item xs={12} sm={6} style={{ marginTop: "0px" }}>
            <CustomTextfield
              label={t("WATCHLISTTEMP002")}
              name="contactEmail"
              value={payload.contactEmail}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ className: classes.input, maxLength: 50 }}
              fullWidth
              required={true}
              error={error.contactEmail}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
            <SelfRegistrationCustomPhone
              placeholder="Mobile"
              value={phoneInput1}
              country={country}
              specialLabel={false}
              inputProps={{ className: classes.input }}
              handleChange={(value, data, event, formattedValue) => handlePhoneChange1(value, data, event, formattedValue)}
            />
          </Grid>

          {/* Another Contact Grid*/}

          {isOpened && (
            <>
              <Grid item xs={12} sm={6} style={{ marginTop: "0px" }}>
                <CustomTextfield
                  label={t("WATCHLISTTEMP002")}
                  name="contactEmail"
                  value={addSecondContact.contactEmail}
                  handleChange={(e) => handleChangeSecondContact(e)}
                  autoComplete="off"
                  inputProps={{ className: classes.input, maxLength: 50 }}
                  fullWidth
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <SelfRegistrationCustomPhone
                  placeholder="Mobile"
                  value={phoneInput2}
                  country={country}
                  specialLabel={false}
                  inputProps={{ className: classes.input }}
                  handleChange={(value, data, event, formattedValue) => handlePhoneChange2(value, data, event, formattedValue)}
                />
              </Grid>
            </>
          )}

          {/* Another Contact */}

          {props.selectedObject.id === "" ? (
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid item xs={6}>
                {!isOpened && (
                  <Link
                    href="#"
                    style={{ color: "#007CE8" }}
                    onClick={handleAddContact}
                  >
                    {t("ADDCONTACT")}
                  </Link>
                )}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {isOpened && (
                  <Link
                    href="#"
                    style={{ color: "#E3393C" }}
                    onClick={handleRemoveContact}
                  >
                    {t("REMOVE")}
                  </Link>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogBox>
    </>
  );
}
