import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import IntegrationService from '../../services/Integrations/IntegrationService'

const initialState = []

export const getAPIKeys = createAsyncThunk('apiKeys/get', async (payload) => {
  const res = await IntegrationService.getAPIKeys(payload)
  return res.data
})

export const generateAPIKey = createAsyncThunk(
  'apiKey/generate',
  async (apiKeyPayload) => {
    const res = await IntegrationService.generateAPIKeys(apiKeyPayload)
    return res.data
  },
)

const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  extraReducers: {
    [getAPIKeys.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },
  },
})

const { reducer } = integrationSlice
export default reducer
