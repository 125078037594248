import { Avatar, Box, Grid, Link, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DataTable from "../../components/DataTable/DataTable";
import Search from "../../components/SearchTab/Search";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import BlockedView from "../../components/VisitorActionsComponents/BlockedView";
import { getAllVisitors } from "../../slices/Approvals/selfRegistarationApprovalSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import BlockVisitorForm from "../BlacklistVisitors/BlockVisitorForm";
import ApproveIcon from "./ApproveIcon";
import SimpleDialogDemo from "./ImageDailogBox";
import RejectIcon from "./RejectIcon";
import RejectRegistartionInvitationDialog from "./RejectRegistartionInvitationDialog";
import SelfRegistrationFilter from "./SelfRegistrationFilter";

function BlockRequestsApprovals() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);
  const [clear, setClear] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false);
  const [blacklistId, setBlacklistId] = useState();
  const [restrictReasonType, setRestrictReasonType] = useState();
  const [restrictReason, setRestrictReason] = useState();
  const [removeAccess, setRemoveAccess] = useState();
  const [rejectVisitId, setRejectVisitId] = useState();
  const [openRejectInvitation, setOpenRejectInvitation] = useState(false);
  const [del, setDel] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const status = ["INITIATED_FOR_BLOCKED"];

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";


  const [filter, setFilter] = React.useState({
    isRestricted: "true",
    visitorTypeId: "",
    purposeTypeId: "",
    status: 'INITIATED_FOR_BLOCKED',
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "WALKIN_REGISTARTION_KIOSK", "SELF_REGISTARTION",],
  })

  const defaultFilter = {
    isRestricted: "true",
    visitorTypeId: "",
    purposeTypeId: "",
    status: 'INITIATED_FOR_BLOCKED',
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "WALKIN_REGISTARTION_KIOSK", "SELF_REGISTARTION",],
  }

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      visitorTypeId: filterData.visitorTypeId,
      purposeTypeId: filterData.purposeTypeId,
      status: filterData.status,
      visitMode: filterData.visitMode,
      isRestricted: filterData.isRestricted,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    loadData(newPage, size);
    setRowsPerPage(size);
  };

  useEffect(() => {
    loadData(1, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getAllVisitors(payload))
      .unwrap()
      .then((data) => {
        console.info(data);
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.visits);
          setPage(data.data.curPage);
          setPageSize(data.data.pageSize);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleRejectVisit = (params) => {
    setOpenRejectInvitation(!openRejectInvitation);
    setRejectVisitId(params.id);
    setDiaLogBoxBlur(true)
  };

  useEffect(() => {

    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage);
      setDel(false);
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false);
      }
    }
  }, [del]);

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor);
    setBlacklistId(params.row.id);
    setRestrictReasonType(params.row.blackListId.restrictReasonType)
    setRestrictReason(params.row.blackListId.restrictReason)
    setRemoveAccess(params.row.blackListId.removeAccess)
    setDiaLogBoxBlur(true)
  };


  const globalsearch = (searchedVal) => {

    if (searchedVal === "Blacklist Initiated") {
      searchedVal = "INITIATED_FOR_BLOCKED";
    }

    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName ? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.lastName ? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.phone ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.email ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #FFFF",
            fontSize: "100px",
            borderRadius: "50%",
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        ></Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
        </Tooltip>
      ),
    },
    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: "visitor.email",
      headerName: t("VISUPDATE0001"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.email}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "scheduledStartDate",
    //   headerName: "Invite Date",
    //   flex: 1,
    //   minWidth: 150,
    //   valueGetter: (params) =>
    //     CommonUtil.getLocalDate(params.row.scheduledStartDate),
    // },
    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      }
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BlockedView rowData={params.row} />}
          label={t("VIEW")}
        />,

        <GridActionsCellItem
          icon={<ApproveIcon />}
          label={t("dialogTitleBlockVisitor")}
          onClick={() => handleBlacklistVisit(params)}
        />,

        <GridActionsCellItem
          icon={<RejectIcon />}
          label={t("APPROVE003")}
          onClick={() => handleRejectVisit(params)}
        />,
      ],
    },
  ]);

  return (
    <>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t("CLEARFILTER")}
            </Link>
          )}
        </Box>

        <Box item>
          <SelfRegistrationFilter
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <Grid item xs={12}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          count={totalRecords}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>

      {openRejectInvitation && (
        <RejectRegistartionInvitationDialog
          open={openRejectInvitation}
          handleClose={(data) => {
            setOpenRejectInvitation(data);
            setDiaLogBoxBlur(false)
          }}
          disable={() => setDel(true)}
          RejectRegisterId={rejectVisitId}
        />
      )}

      {openBlacklistVisitor && (
        <BlockVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data);
            setDiaLogBoxBlur(false)
          }}
          disable={() => setDel(true)}
          BlacklistVisitorId={blacklistId}
          restrictReasonType={restrictReasonType}
          restrictReason={restrictReason}
          removeAccess={removeAccess}
        />
      )}


    </>
  );
}

export default BlockRequestsApprovals;
