import BrowserLanguage from '../../components/Util/BrowserLanguage'
import {
  REACT_URL_ADDRESSTYPE,
  REACT_URL_APPROVAL,
  REACT_URL_BLOCKED_VISITORS,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_DASHBOARD,
  REACT_URL_INVITATIONS,
  REACT_URL_PERSON,
  REACT_URL_REGISTRATIONS,
  REACT_URL_ROLE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_EMERGENCYMESSAGE,
  REACT_URL_CARDTEMPLATE,
  REACT_URL_ADDITIONAL_SETTINGS,
  REACT_URL_ZONE,
  REACT_URL_DEVICELIST,
  REACT_TIME_SLOTS,
  REACT_ACCESSLEVELS_LIST,
  REACT_URL_VISITORTYPE,
  REACT_URL_CHECKLISTTEMPLATE_USA,
  REACT_URL_BLACKLISTTYPE,
  REACT_URL_PURPOSETYPE_USA,
  REACT_URL_USERROLE,
  REACT_URL_EMPLOYEE,
  REACT_URL_VISITINVITE,
  REACT_URL_WALKINVISIT,
  REACT_URL_DAILYREPORT,
  REACT_URL_INVITATIONREPORT,
  REACT_URL_WALKINREGISTRATIONREPORT,
  REACT_URL_SELFREGISTRATIONREPORT,
  REACT_URL_BLACKLISTVISITORS,
  REACT_URL_USER_LIST,
  REACT_URL_OFFENCE,
  REACT_DELIVERY_REPORT,
  REACT_SMS_TEMPLATE,
  REACT_ATLAS_INTEGRATION,
  REACT_SELF_REGISTRATION_LIST,
  REACT_INTEGRATION,
} from '../../actions/EndPoints'
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg'
import vis_Dashboard_Icon from '../../assets/USA_Images/navitem_images/Dashboard'
import Device_Icon from '../../assets/USA_Images/navitem_images/settingWhite'
import vis_Device_Icon from '../../assets/images/dashboard-icons/SettingsGray'
import visitor_icon from '../../assets/USA_Images/navitem_images/VisitorWhite'
import vis_visitor_icon from '../../assets/images/dashboard-icons/VisitorGray'
import Organization_Icon from '../../assets/USA_Images/navitem_images/OrganizationWhite'
import vis_Organization_Icon from '../../assets/images/dashboard-icons/OrganizationGray'
import SiteManagement from '../../assets/USA_Images/navitem_images/SiteManagementWhite'
import vis_SiteManagement from '../../assets/images/dashboard-icons/Sitemanagementgray2'
import ReportIcon from '../../assets/USA_Images/navitem_images/ReportIcon'
import DeviceManagement from '../../assets/USA_Images/navitem_images/DeviceMangement'
import EmergencyMessageImg from '../../assets/USA_Images/navitem_images/EmergencyMessageImg'
import Offence_img from '../../assets/USA_Images/navitem_images/OffenceImg'

let language = BrowserLanguage.getDefaultLanguage()
const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Visitors',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '2',
        name: 'Invitations',
        link: `${REACT_URL_VISITINVITE}`,
      },
      {
        id: '3',
        name:
          process.env.REACT_APP_ENVIRONMENT === 'USA'
            ? 'Walk-In Registration'
            : 'Registrations',
        link: `${REACT_URL_WALKINVISIT}`,
      },
      {
        id: '35',
        name: 'Self Registration',
        link: `${REACT_SELF_REGISTRATION_LIST}`,
      },

      {
        id: '4',
        name: 'Approvals',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '5',
        name: 'Blacklist Visitor',
        link: `${REACT_URL_BLACKLISTVISITORS}`,
      },
    ],
  },
  {
    id: '6',
    name: 'Employee',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '7',
        name: 'Person',
        link: `${REACT_URL_EMPLOYEE}`,
      },
      {
        id: '8',
        name: 'Role',
        link: `${REACT_URL_USERROLE}`,
      },
    ],
  },
  {
    id: '10',
    name: 'Site Management',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '11',
        name: 'Address Type',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '12',
        name: 'Site Type',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '13',
        name: 'Site',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '14',
        name: 'Zone',
        link: `${REACT_URL_ZONE}`,
      },
      {
        id: '15',
        name: 'Time slots',
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: '16',
        name: 'Visiting Access Level',
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
    ],
  },
  {
    id: '17',
    name: 'Device Management',
    Icon: DeviceManagement,
    items: [
      {
        id: '18',
        name: 'Device List',
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },
  // {
  //   id: '33',
  //   name: 'Offender',
  //   Icon: Offence_img,
  //   items: [
  //     {
  //       id: '34',
  //       name: 'Offender',
  //       link: `${REACT_URL_OFFENCE}`,
  //     },
  //   ],
  // },

  {
    id: '34',
    name: 'Emergency Message',
    Icon: EmergencyMessageImg,
    items: [
      {
        id: '01',
        name: 'Emergency Message',
        link: `${REACT_URL_EMERGENCYMESSAGE}`,
      },
    ],
  },

  {
    id: '19',
    name: 'Report',
    Icon: ReportIcon,
    items: [
      {
        id: '20',
        name: 'Daily Report',
        link: `${REACT_URL_DAILYREPORT}`,
      },
      {
        id: '21',
        name: 'Invitation',
        link: `${REACT_URL_INVITATIONREPORT}`,
      },
      {
        id: '22',
        name: 'Walk-In Registration',
        link: `${REACT_URL_WALKINREGISTRATIONREPORT}`,
      },
      {
        id: '23',
        name: 'Self Registration',
        link: `${REACT_URL_SELFREGISTRATIONREPORT}`,
      },
      {
        id: '54',
        name: 'Delivery Report',
        link: `${REACT_DELIVERY_REPORT}`,
      },
    ],
  },

  {
    id: '24',
    name: 'Settings',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '25',
        name: 'Visitor Type',
        link: `${REACT_URL_VISITORTYPE}`,
      },
      {
        id: '26',
        name: 'Purpose Type',
        link: `${REACT_URL_PURPOSETYPE_USA}`,
      },

      {
        id: '27',
        name: 'Blacklist Type',
        link: `${REACT_URL_BLACKLISTTYPE}`,
      },

      {
        id: '28',
        name: 'Checklist',
        link: `${REACT_URL_CHECKLISTTEMPLATE_USA}`,
      },

      {
        id: '29',
        name: 'Watchlist',
        link: `${REACT_URL_WATCHLIST}`,
      },

      {
        id: '31',
        name: 'Additional Settings',
        link: `${REACT_URL_ADDITIONAL_SETTINGS}`,
      },

      {
        id: '32',
        name: 'Card Template',
        link: `${REACT_URL_CARDTEMPLATE}`,
      },

      {
        id: '33',
        name: 'SMS Template',
        link: `${REACT_SMS_TEMPLATE}`,
      },

      {
        id: '34',
        name: 'Atlas Integration',
        link: `${REACT_ATLAS_INTEGRATION}`,
      },

      {
        id: '34',
        name: 'Integration',
        link: `${REACT_INTEGRATION}`,
      },
    ],
  },
]

const dataEs = [
  {
    id: '0',
    name: 'Panel',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '5',
    name: 'Visitante',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '20',
        name: 'Invitaciones',
        link: `${REACT_URL_VISITINVITE}`,
      },
      {
        id: '3',
        name:
          process.env.REACT_APP_ENVIRONMENT === 'USA'
            ? 'Registro sin cita previa'
            : 'Inscripciones',
        link: `${REACT_URL_WALKINVISIT}`,
      },
      {
        id: '22',
        name: 'Auto registro',
        link: `${REACT_SELF_REGISTRATION_LIST}`,
      },
      {
        id: '23',
        name: 'Aprobaciones',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '24',
        name: 'Visitantes bloqueados',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
    ],
  },
  {
    id: '1',
    name: 'Organización',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Persona',
        link: `${REACT_URL_PERSON}`,
      },
      {
        id: '7',
        name: 'Role',
        link: `${REACT_URL_ROLE}`,
      },
    ],
  },
  {
    id: '10',
    name: 'Manejo de sitio',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '11',
        name: 'Tipo de dirección',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '12',
        name: 'Tipo de sitio',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '13',
        name: 'Sitio',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '14',
        name: 'Zona',
        link: `${REACT_URL_ZONE}`,
      },
      {
        id: '15',
        name: 'Ranuras de tiempo',
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: '16',
        name: 'Nivel de acceso para visitantes',
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Gestión de dispositivos',
    Icon: DeviceManagement,
    items: [
      {
        id: '17',
        name: 'Lista de dispositivos',
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },
  {
    id: '4',
    name: 'Mensaje de emergencia',
    Icon: EmergencyMessageImg,
    items: [
      {
        id: '18',
        name: 'Mensaje de emergencia',
        link: `${REACT_URL_EMERGENCYMESSAGE}`,
      },
    ],
  },
  {
    id: '5',
    name: 'Informe',
    Icon: ReportIcon,
    items: [
      {
        id: '19',
        name: 'Reporte diario',
        link: `${REACT_URL_DAILYREPORT}`,
      },
      {
        id: '20',
        name: 'Invitación',
        link: `${REACT_URL_INVITATIONREPORT}`,
      },
      {
        id: '21',
        name: 'Registro sin cita previa',
        link: `${REACT_URL_WALKINREGISTRATIONREPORT}`,
      },
      {
        id: '22',
        name: 'Auto registro',
        link: `${REACT_URL_SELFREGISTRATIONREPORT}`,
      },
      {
        id: '23',
        name: 'Informe de entrega',
        link: `${REACT_DELIVERY_REPORT}`,
      },
    ],
  },
  {
    id: '6',
    name: 'Ajustes',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '25',
        name: 'Tipo de visitante',
        link: `${REACT_URL_VISITORTYPE}`,
      },
      {
        id: '26',
        name: 'Tipo de propósito',
        link: `${REACT_URL_PURPOSETYPE_USA}`,
      },

      {
        id: '27',
        name: 'Tipo de lista negra',
        link: `${REACT_URL_BLACKLISTTYPE}`,
      },

      {
        id: '28',
        name: 'Lista de Verificación',
        link: `${REACT_URL_CHECKLISTTEMPLATE_USA}`,
      },
      {
        id: '29',
        name: 'lista de observación',
        link: `${REACT_URL_WATCHLIST}`,
      },
      {
        id: '31',
        name: 'Ajustes adicionales',
        link: `${REACT_URL_ADDITIONAL_SETTINGS}`,
      },
      {
        id: '32',
        name: 'Plantilla de tarjeta',
        link: `${REACT_URL_CARDTEMPLATE}`,
      },
      {
        id: '33',
        name: 'Plantilla de SMS',
        link: `${REACT_SMS_TEMPLATE}`,
      },
      {
        id: '34',
        name: 'Integración del Atlas',
        link: `${REACT_ATLAS_INTEGRATION}`,
      },
    ],
  },
]

const dataZh = [
  {
    id: '0',
    name: '仪表板',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '5',
    name: '游客',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '20',
        name: '邀请函',
        link: `${REACT_URL_INVITATIONS}`,
      },
      {
        id: '21',
        name: '注册',
        link: `${REACT_URL_REGISTRATIONS}`,
      },
      {
        id: '22',
        name: '批准',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '23',
        name: '被阻止的访客',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
    ],
  },
  {
    id: '1',
    name: '组织',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '6',
        name: '人',
        link: `${REACT_URL_PERSON}`,
      },
      {
        id: '7',
        name: '角色',
        link: `${REACT_URL_ROLE}`,
      },
      {
        id: '8',
        name: '用户',
        link: `${REACT_URL_USER_LIST}`,
      },
    ],
  },
  {
    id: '2',
    name: '现场管理',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '10',
        name: '地址类型',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '11',
        name: '网站类型',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '12',
        name: '地点',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '12',
        name: '签入和签出设备',
        link: `${REACT_URL_CHECKINOUTDEVICES}`,
      },
    ],
  },
  {
    id: '3',
    name: '设置',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '13',
        name: '用途类型',
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: '14',
        name: '限制类型',
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: '15',
        name: '清单',
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: '16',
        name: '关注列表',
        link: `${REACT_URL_WATCHLIST}`,
      },
    ],
  },
]

export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh
