import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../Util/CommonUtils";
import { ReactComponent as ExportIcon } from '../../assets/images/logs/expoer-bg.svg';
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';

export default function FilterExportDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [changeDate, setDateChange] = useState(true);
  const [dateOpen, setDateOpen] = useState('');
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [payload, setPayload] = useState({
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
  });

  const handleClose = () => {
    setPayload({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    });
    setOpen(false);
    setDiaLogBoxBlur(false)
  };

  if (open) {
    setDiaLogBoxBlur(true)
  }

  const reset = () => {
    if (
      payload.scheduledStartDate === startDate &&
      payload.scheduledEndDate === endDate
    ) {
      Toast(t("COMMON027"), "error")
      return;
    }

    setPayload({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    });
    setDateOpen(props.open);
  };

  const [error, setError] = useState({
    scheduledStartDate: false,
    scheduledEndDate: false,
  });

  // useEffect(()=>{
  //   api.securedAxios().get('/web/getSettings').then((res) => {
  //   if(res.code === "CVAI0000"){
  //     setDate(res.data.data.dateFormat)
  //     setDateChange(true)
  //    }
  //  })
  //  },[props.open])

  const handleSubmit = () => {
    if (
      payload.scheduledStartDate === null &&
      payload.scheduledEndDate === null
    ) {
      Toast(t("COMMON015"), "error")
      return;
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ""
    ) {
      setError({ scheduledStartDate: true });
      Toast(t("INVITESTARTDATEERROR"), "error")
      return;
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ""
    ) {
      setError({ scheduledEndDate: true });
      Toast(t("INVITEENDDATEERROR"), "error")
      return;
    }

    if (
      new Date(payload.scheduledStartDate).toString() === "Invalid Date" ||
      new Date(payload.scheduledEndDate).toString() === "Invalid Date"
    ) {
      Toast(t("USERPROFSCREEN013"), "error")
      return;
    }

    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(t("INVITATIONFORM010"), "error")
      return;
    }
    handleClose();
    props.applyFilter(payload);
    setPayload({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    });
  };

  return (
    <>
      <Tooltip title={<Typography>{t("EMP014")}</Typography>}>
        <ExportIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
        </ExportIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog open={open}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(2px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          }}
        >
          <DialogTitle variant="h4" onClick={handleClose} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
              {t('COMMON006')}
            </Typography>
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
            >

              <Grid item xs={12} sm={9} style={{ marginTop: "20px", height: "100%" }}>
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={t('INVITESTARTDATEERROR1')}
                  changeDate={changeDate}
                  date={props.date}
                />
              </Grid>

              <Grid item xs={12}
                sm={9}
                style={{ marginTop: "30px", height: "100%" }}
              >
                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label={t('INVITESTARTDATEERROR2')}
                  changeDate={changeDate}
                  date={props.date}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
            backgroundColor: darkMode ? '#0F273B' : '#fff'
          }}>
            <SubmitButtons variant='contained' onClick={handleSubmit}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t('COMMON024')}
              </Typography>
            </SubmitButtons>
            <CancelButtons variant='contained' color="secondary" onClick={reset}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t('COMMON025')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
