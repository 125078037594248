import React, { useEffect, useState, useContext } from 'react'
import { Menu, MenuItem, Typography } from '@mui/material';
import { IconFlagUS, IconFlagES, IconFlagCN, IconFlagFR } from 'material-ui-flags';
import CheckIcon from '@mui/icons-material/Check';
import BrowserLanguage from '../Util/BrowserLanguage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { REACT_DASHBOARD_PANEL, REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { ThemeContext } from '../../theme/ThemeContext';

function SelectLanguageMenu(props) {
    const [image, setImage] = React.useState(props.normalImage);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    // const [openMenu, setOpenMenu] = React.useState(props.onclickMenu);
    const [search, setSearch] = React.useState(null);
    const [anchorEl2, setanchorEl2] = React.useState(null);
    const { darkMode } = useContext(ThemeContext);


    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('en');
    const history = useHistory();

    useEffect(() => {
        const currentLanguage = localStorage.getItem('i18nextLng')
        setSelectedLang(currentLanguage)
    }, [])

    const handleClick = (event) => {
        setanchorEl2(event.currentTarget);
        setOpenMenu(true);
        setImage(props.onclickImage)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
        out();
    };

    const over = (event) => {
        // setanchorEl2(event.currentTarget);
        // setOpenMenu(true)
        setImage(props.hoverImage);
    };

    const out = () => {
        setImage(props.normalImage);
        setOpenMenu(false)
    }


    const onMouseOut = (e) => {
        setImage(props.normalImage);
    }

    const handleCloseLangMenu = (event) => {
        props.handleClose(null)
        setanchorEl2(event.currentTarget)
        if (event[0] === undefined) {
            BrowserLanguage.getDefaultLanguage();
            setSearch(null);
        } else {
            if (localStorage.getItem("companyType") === 'CUSTOMER') {
                localStorage.setItem("i18nextLng", event);
                BrowserLanguage.setLanguage(event);
                i18n.changeLanguage(event);
                setSearch(event);
                setSelectedLang(event);
                history.push({
                    pathname: REACT_URL_DASHBOARD
                })
            }
            else {
                localStorage.setItem("i18nextLng", event);
                BrowserLanguage.setLanguage(event);
                i18n.changeLanguage(event);
                setSearch(event);
                setSelectedLang(event);
                history.push({
                    pathname: REACT_DASHBOARD_PANEL
                })
            }
        }
        window.location.reload(false);
    };
    return (
        <>
            <MenuItem
                anchorEl={anchorEl2}
                onMouseOver={over}
                onMouseOut={onMouseOut}
                onClick={(e) => handleClick(e)}
                sx={[
                    (theme) => ({
                        '&:hover': {
                            background: process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' : process.env.REACT_APP_BG_ICON,
                            color: process.env.REACT_APP_BG_HEADER_PROFILE,
                        },
                    }),
                    openMenu && {
                        background: process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' : '',
                        color: process.env.REACT_APP_ENVIRONMENT === 'USA' ? '#000' : '#000',
                    },
                ]}
            >
                <img src={image} alt={props.alt} style={{ paddingRight: '10px' }} />
                <Typography> {props.item}</Typography>
            </MenuItem>
            <Menu
                anchorEl={anchorEl2}
                id="account-menu"
                open={openMenu}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    paddingRight: 55,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 0,
                        mr: 1,
                        backgroundColor: darkMode ? '#0F273B' : 'background.paper', // Apply dark mode background color here

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                        },

                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 18,
                            right: -3,
                            width: 10,
                            height: 10,
                            bgcolor: darkMode ? '#0F273B' : '', 
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left ', vertical: 'top' }}
            >
                <MenuItem
                    value={'en'}
                    key={'en'}
                    onClick={() => {
                        handleCloseLangMenu('en');
                    }}
                    // onMouseOver={over}
                    // onMouseOut={out}
                    sx={[
                        (theme) => ({
                            '&:hover': {
                                background: '#DCFFE980',
                            },
                        }),
                    ]}
                >
                    <IconFlagUS className='lang-flag' />
                    &nbsp;
                    <span style={{ color: darkMode ? '#fff' : '#000' }}>
                        {t('i18nLabelEnglishFlag')}
                    </span>
                    &nbsp;
                    {selectedLang === 'en' && <CheckIcon style={{ color: 'green' }} />}
                </MenuItem>
                <MenuItem
                    value={'es'}
                    key={'es'}
                    onClick={() => {
                        handleCloseLangMenu('es');
                    }}
                    // onMouseOver={over}
                    // onMouseOut={out}
                    sx={[
                        (theme) => ({
                            '&:hover': {
                                background: '#DCFFE980',
                            },
                        }),
                    ]}
                >
                    <IconFlagES className='lang-flag' />
                    &nbsp;
                    <span style={{ color: darkMode ? '#fff' : '#000' }}>
                        {t('i18nLabelSpanishFlag')}
                    </span>
                    {selectedLang === 'es' && <CheckIcon style={{ color: 'green' }} />}

                </MenuItem>
                <MenuItem
                    value={'fr'}
                    key={'fr'}
                    onClick={() => {
                        handleCloseLangMenu('fr');
                    }}
                    // onMouseOver={over}
                    // onMouseOut={out}
                    sx={[
                        (theme) => ({
                            '&:hover': {
                                background: '#DCFFE980',
                            },
                        }),
                    ]}
                >
                    {/* <IconFlagFR className='lang-flag' />
                    &nbsp;  {t('french')}
                    {selectedLang === 'fr' && <CheckIcon style={{ color: 'green' }} />} */}

                </MenuItem>
            </Menu>
        </>
    )
}

export default SelectLanguageMenu