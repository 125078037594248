import { Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext'

export default function FilterCardTemplate(props) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [visitorData, setVisitorData] = useState([])
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    name: '',
    visitorTypeId: '',
  })

  const handleClose = () => {
    setPayload({
      name: '',
      visitorTypeId: '',
    })
    setOpen(false)
    setDiaLogBoxBlur(false)
  }

  if (open) {
    setDiaLogBoxBlur(true)
  }
  const [error, setError] = useState({
    name: false,
    visitorTypeId: false,
  })


  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (payload.name.trim() === '' && payload.visitorTypeId.trim() === '') {
      Toast(t('COMMON015'), 'error')
      return
    }

    props.applyFilter(payload)
    setPayload({
      name: '',
      visitorTypeId: '',
    })
    handleClose()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadVisitorType()
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event.id,
    })
  }

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <FilterCardTemplate />
        </FilterIcon>
      </Tooltip>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t('COMMON024')}
        cancelText={t('Cancel')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '16px 32px', width: '100%', backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid item xs={12} sm={10.5} style={{ marginTop: '15px' }}>
            <CustomTextfield
              variant="outlined"
              label={t('COMMON069')}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              style={{ margin: '1px' }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5} mt={5}>
            <AutocompleteComponent
              label="Visitor Type"
              name="visitorTypeId"
              error={error.visitorTypeId}
              value={payload.visitorTypeId}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectVisitorType(e)}
              options={visitorData}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
