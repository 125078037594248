import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import eyeIcon from '../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg';
import ViewIcon from "../../Pages/Invitations/ViewIcon";
import { getRestrictTypeById } from "../../slices/RestirctTypeSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import "./profileCard.css";

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflowY: "clip",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    overflowY: "visible",
    borderRadius: "20px",
    minWidth: "1000px",
    height: "484px",
    opacity: 1,
    backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
  },
  '& .MuiDialogContent-root ': {
    padding: '16px 24px',
    position: ' relative',
    bottom: '80px',
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BlockedView(props) {
  const [open, setOpen] = React.useState(false);
  const [restrictName, setRestrictName] = useState("");
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  if (open) {
    setDiaLogBoxBlur(true)
  }
  const handleClose = () => {
    setOpen(false);
    setDiaLogBoxBlur(false)
  };

  useEffect(() => {
    loadRestrictName(props.rowData.blackListId.restrictReasonType);
  }, []);

  const loadRestrictName = (restrictId) => {
    dispatch(getRestrictTypeById(restrictId))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          console.log(data.data.name)
          setRestrictName(data.data.name);
        } else {
          setRestrictName("");
        }
      });
  };

  return (
    <div>
      <div onClick={handleClickOpen}>
        {darkMode ? (
          <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
        ) : (
          <ViewIcon />
        )}
      </div>
      <Grid>
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          darkMode={darkMode}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(2px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          }}
        >
          <BootstrapDialogTitle onClose={handleClose} sx={{ color: darkMode ? '#fff' : '' }}>
            <Avatar
              alt={props.rowData.visitor.firstName}
              src={props.rowData.visitor.profilePhotoURL !== undefined ? props.rowData.visitor.profilePhotoURL : null}
              sx={{
                width: 150,
                height: 150,
                bottom: "80px",
                left: "421px",
                border: "solid #FFFF",
                borderWidth: "7px",
              }}
            />
            <div className="CssTextProfileViewPageName" style={{ color: darkMode ? '#fff' : '' }}>
              {props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName : props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone" style={{ color: darkMode ? '#fff' : '' }}>
              {props.rowData.visitor.phone}
            </div>
          </BootstrapDialogTitle>
          <Divider variant="inset" className="MuiDividerCss" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
          <DialogContent style={{ marginLeft: "70px", marginRight: "70px" }}>
            <Grid
              container
              spacing={12.25}
              className="CssTextProfileViewPageContent"
              marginTop={0.5}
              style={{ color: darkMode ? '#fff' : '' }}
            >
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Email
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {" "}
                  {props.rowData.visitor.email}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Company Name
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.visitor.visitorCompanyName}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Blacklist Type
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {restrictName}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Status
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? props.rowData.status === 'INITIATED_FOR_BLOCKED' ? 'Blacklist Initiated' : props.rowData.status : props.rowData.status === 'INITIATED_FOR_BLOCKED' ? 'Block Initiated' : props.rowData.status}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Blacklisted By
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.hostName}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Reason
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.blackListId.restrictReason}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default BlockedView;
