import React, { useState, useContext } from 'react'
// import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import SubmitButtons from '../DialogBoxComponent/SubmitButtons'
import CancelButtons from '../DialogBoxComponent/CancelButtons'
import { Divider } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import { ReactComponent as CloseSvg } from '../../../src/assets/Close.svg'
import CloseIcon from '@mui/icons-material/Close'
import { InputAdornment } from '@mui/material'
import { DialogActions } from '@mui/material'
import FilterForm from './FilterForm'
import USACustomTextfield from '../CustomInputs/USACustomTextfield'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../ToastContainer/CustomToast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { updatePassword } from '../../slices/User/UserSlice'
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '20px',
    minWidth: '600px',
  },
}))

export default function ChangePasswordDialog(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { onSubmit, children, onClose, ...other } = props
  const [payload, setPayload] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const { darkMode } = useContext(ThemeContext);
  const handleCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword)
  const handleNewPassword = () => setShowNewPassword(!showNewPassword)
  const handleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClose = () => {
    props.onClose(false)
  }

  const handleChange = (event) => {
    setPayload({
      ...payload,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.currentPassword)) {
      Toast(t('CURRENTPASSWORDEMPTY'), 'error')
      return;
    }

    if (
      !payload.currentPassword.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/,
      )
    ) {
      Toast(t('CURRENTPASSWORDERROR'), 'error')
      return;
    }

    if (CommonUtil.isEmptyString(payload.newPassword)) {
      Toast(t('NEWPASSWORDEMPTY'), 'error')
      return;
    }
    if (
      !payload.newPassword.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/,
      )
    ) {
      Toast(t('NEWPASSWORDERROR'), 'error')
      return;
    }

    if (CommonUtil.isEmptyString(payload.confirmPassword)) {
      Toast(t('CONFIRMPASSWORDEMPTY'), 'error')
      return;
    }
    if (
      !payload.confirmPassword.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/,
      )
    ) {
      Toast(t('CONFIRMPASSWORDERROR'), 'error')
      return;
    }
    if (payload.newPassword !== payload.confirmPassword) {
      Toast(t('PASSWORDMATCHERROR'), 'error')
      return;
    }
    if(payload.currentPassword === payload.newPassword){
      Toast(t('SAMEPASSWORDERROR') , 'error')
      return;
    }
    var decoded_jwtToken = jwt_decode(
      localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    )
    let dto = {
      oldPassword: payload.currentPassword,
      newPassword: payload.newPassword,
      userId: decoded_jwtToken.userId,
    }
    dispatch(updatePassword(dto))
      .unwrap()
      .then((res) => {
        if (res.code === 'UASI0005') {
          Toast(res.message, 'success')
          handleClose()
          return
        } else {
          Toast(res.message, 'error')
          return
        }
      })
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        {...props}
        open={props.open}
        PaperProps={{
          style: {
            backgroundColor: darkMode ? '#0F273B' : '#fff',
            color: darkMode ? '#fff' : '#434547'
          },
        }}
      >
        <DialogTitle variant="h4" onClick={handleClose} style={{color: darkMode ? '#fff' : 'black'}} >
          <IconButton
            onClick={handleClose}
            // style={{backgroundColor: darkMode ? '#0F273B' : '#fff'}}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider style={{backgroundColor: darkMode ? '#fff' : ''}}/>
        <DialogContent>
          <Grid padding={2}>
            <Typography style={{ color: darkMode ? '#fff' : '#3D4977', opacity: 0.8 }}>
           {t('CHANGE_PASSWORD_TEXT')}
            </Typography>
          </Grid>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="outlined-basic"
                label={t('CHEANGE_PASSWORD_LABLE_1')}
                name="currentPassword"
                variant="outlined"
                type={showCurrentPassword ? 'text' : 'password'}
                autoComplete="new-password"
                onChange={handleChange}
                value={payload.currentPassword}
                // style={{ width: '100%', marginTop: '10px' }}
                sx={{
                  width: '100%',
                  marginTop: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: darkMode ? '#fff' : 'default',
                  },
                  '& .MuiInputBase-input': {
                    color: darkMode ? '#fff' : 'default',
                  },
                  '& input:-webkit-autofill': {
                    '-webkit-box-shadow': `0 0 0 100px ${darkMode ? '#0F273B' : '#fff'} inset !important`, // Set the background color
                    '-webkit-text-fill-color': darkMode ? '#fff !important' : '#000 !important', // Set the text color
                    transition: 'background-color 5000s ease-in-out 0s', // Prevent background flash
                  },
                  '& input:-webkit-autofill:hover': {
                    '-webkit-text-fill-color': darkMode ? '#fff !important' : '#000 !important',
                    '-webkit-box-shadow': `0 0 0 100px ${darkMode ? '#0F273B' : '#fff'} inset !important`,
                  },
                  '& input:-webkit-autofill:focus': {
                    '-webkit-text-fill-color': darkMode ? '#fff !important' : '#000 !important',
                    '-webkit-box-shadow': `0 0 0 100px ${darkMode ? '#0F273B' : '#fff'} inset !important`,
                  },
                  '& input:-webkit-autofill:active': {
                    '-webkit-text-fill-color': darkMode ? '#fff !important' : '#000 !important',
                    '-webkit-box-shadow': `0 0 0 100px ${darkMode ? '#0F273B' : '#fff'} inset !important`,
                  },
                }}
                inputProps={{maxLength : 25}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="PasswordIcon"
                        onClick={handleCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <Visibility className="visibleIcons" />
                        ) : (
                          <VisibilityOff className="visibleIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="outlined-basic"
                label={t('CHANGE_PASSWORD_LABLE_2')}
                variant="outlined"
                autoComplete="new-password"
                name="newPassword"
                value={payload.newPassword}
                onChange={handleChange}
                type={showNewPassword ? 'text' : 'password'}
                // style={{ width: '100%' }}
                sx={{
                  width: '100%',
                  marginTop: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: darkMode ? '#fff' : 'default',
                  },
                  '& .MuiInputBase-input': {
                    color: darkMode ? '#fff' : 'default',
                  },
                }}
                inputProps={{maxLength : 25}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="PasswordIcon"
                        onClick={handleNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <Visibility className="visibleIcons" />
                        ) : (
                          <VisibilityOff className="visibleIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="outlined-basic"
                label={t('CHANGE_PASSWORD_LABLE_3')}
                variant="outlined"
                autoComplete="new-password"
                name="confirmPassword"
                value={payload.confirmPassword}
                onChange={handleChange}
                type={showConfirmPassword ? 'text' : 'password'}
                // style={{ width: '100%' }}
                sx={{
                  width: '100%',
                  marginTop: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : 'default',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: darkMode ? '#fff' : 'default',
                  },
                  '& .MuiInputBase-input': {
                    color: darkMode ? '#fff' : 'default',
                  },
                }}
                inputProps={{maxLength : 25}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="PasswordIcon"
                        onClick={handleConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility className="visibleIcons" />
                        ) : (
                          <VisibilityOff className="visibleIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
            marginLeft: '120px',
            backgroundColor: darkMode ? '#0F273B' : '#fff'
          }}
        >
          <CancelButtons onClick={handleClose}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {props.cancelText}
            </Typography>
          </CancelButtons>

          <SubmitButtons onClick={handleSubmit} type="submit">
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {props.acceptText}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}
