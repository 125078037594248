import { Tooltip } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { Divider, Grid } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch } from 'react-redux'
import SimpleDialogDemo from '../../Pages/Approvals/ImageDailogBox'
import SimpleDialogBox from '../../Pages/Approvals/ImageDialog'

import eyeIcon from '../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg'
import ViewIcon from '../../Pages/Invitations/ViewIcon'
import CommonUtil from '../../Util/CommonUtils'
import '../../components/VisitorActionsComponents/profileCard.css'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  getDlImages,
  getvisitorDetails,
} from '../../slices/Invitations/InvitationsSlice'
import { ViewDeliveryReportsDetails } from '../../slices/Reports/DeliveryReportSlice'
import { getSiteById } from '../../slices/Site/SiteSlice'
import { getAllVisitorDocuments } from '../../slices/VisitorDocuments/VisitorDocumentSlice'
import { ThemeContext } from '../../theme/ThemeContext'

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY: 'clip',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '20px',
    minWidth: '120vh',
    height: '75%',
    opacity: 1,
    backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
  },
  '& .MuiDialogContent-root ': {
    padding: '16px 24px',
    position: 'relative',
    bottom: '80px',
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

function VisitorRegistrationViewPage(props) {
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [open, setOpen] = React.useState(false)
  const [siteName, setSiteName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const rowDataMode = props.rowData.visitMode
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [Dlmode, setDlMode] = useState(false)
  const [dlFrontImage, setDlFrontImage] = useState('')
  const [dlRearImage, setDlRearImage] = useState('')
  const [responseData, setResponseData] = useState({})
  const [imageArray, setImageArray] = useState([])
  const [objectKeys, setObjectKeys] = useState()
  const objeckKeyss = []

  const [payload, setPayload] = useState({
    livePhoto: props.rowData.livePhoto ? props.rowData.livePhoto : '',
    signature: props.rowData.signature ? props.rowData.signature : '',
  })
  const [CheckInpayload, setCheckInPayload] = useState({
    checkInlivePhotoKey: props.rowData.checkInlivePhotoKey
      ? props.rowData.checkInlivePhotoKey
      : '',
  })
  const [checkInPhotoUrl, setCheckInPhotoUrl] = useState('')

  const [livePhotoUrl, setLivePhotoUrl] = useState('')
  const [signatureUrl, setSignaturUrl] = useState('')

  const handleClickOpenSignature = () => {
    if (!CommonUtil.isEmptyString(props.rowData.signature)) {
      loadSignatureData(props.rowData.signature)
    }
    setOpen(true)
  }

  // useEffect(() => {
  //     loadCheckInLivePhoto();
  //   }, [props.rowData.checkInlivePhotoKey]);

  const loadSignatureData = () => {
    if (!CommonUtil.isEmptyString(payload.signature)) {
      let objectKeys = []
      if (!CommonUtil.isEmptyString(payload.signature)) {
        objectKeys.push(payload.signature)
      }
      const dto = {
        objectKeys,
      }
      dispatch(ViewDeliveryReportsDetails(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'STSI0000') {
            data.data.map((obj) => {
              if (payload.signature === obj.objectKey) {
                setSignaturUrl(obj.preSignedUrl)
              }
            })
          }
        })
    }
  }

  const handleClickOpenVisitorDocuments = () => {
    if (!CommonUtil.isEmptyString(props.rowData)) {
      loadVisitorDocumentObjectKeys(props.rowData)
    }
    setOpen(true)
  }

  const loadVisitorDocumentObjectKeys = () => {
    dispatch(getAllVisitorDocuments(props))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000' && data.data.length > 0) {
          const imageUrls = data.data
            .filter((imageData) => 'url' in imageData)
            .map((imageData) => imageData.url)
          setImageArray(imageUrls)
        }
      })
  }

  const loadCheckInLivePhoto = () => {
    if (!CommonUtil.isEmptyString(CheckInpayload.checkInlivePhotoKey)) {
      let objectKeys = []
      if (!CommonUtil.isEmptyString(CheckInpayload.checkInlivePhotoKey)) {
        objectKeys.push(CheckInpayload.checkInlivePhotoKey)
      }
      const dto = {
        objectKeys,
      }
      dispatch(ViewDeliveryReportsDetails(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'STSI0000') {
            data.data.map((obj) => {
              if (CheckInpayload.checkInlivePhotoKey === obj.objectKey) {
                setCheckInPhotoUrl(obj.preSignedUrl)
                setLivePhotoUrl(obj.preSignedUrl)
              }
            })
          }
        })
    }
  }

  const handleClickOpenLivePhoto = () => {
    if (!CommonUtil.isEmptyString(props.rowData.livePhoto)) {
      loadLivePhotoData(props.rowData.livePhoto)
    }
    setOpen(true)
  }

  const loadLivePhotoData = () => {
    if (!CommonUtil.isEmptyString(payload.livePhoto)) {
      let objectKeys = []
      if (!CommonUtil.isEmptyString(payload.livePhoto)) {
        objectKeys.push(payload.livePhoto)
      }
      const dto = {
        objectKeys,
      }
      dispatch(ViewDeliveryReportsDetails(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'STSI0000') {
            data.data.map((obj) => {
              if (payload.livePhoto === obj.objectKey) {
                setLivePhotoUrl(obj.preSignedUrl)
                setCheckInPhotoUrl(obj.preSignedUrl)
              }
            })
          }
        })
    }
  }

  const handleClickOpen = () => {
    loadCheckInLivePhoto()
    loadSiteName(props.rowData.siteId)
    loadDrivingLicense()
    setOpen(true)
  }
  if (open) {
    setDiaLogBoxBlur(true)
  }
  const handleClose = () => {
    setOpen(false)
    setDiaLogBoxBlur(false)
  }

  useEffect(() => {
    setEndDate(
      moment(
        CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate),
      ).format('DD-MM-YY HH:mm'),
    )
    setStartDate(
      moment(
        CommonUtil.getLocalDateTimeInYearFormat(
          props.rowData.scheduledStartDate,
        ),
      ).format('DD-MM-YY HH:mm'),
    )
  }, [])

  const loadDrivingLicense = () => {
    const object = {
      visitorId: props.rowData.visitorId,
      documentId: 'DRIVING_LICENSE',
    }
    dispatch(getvisitorDetails(object))
      .unwrap()
      .then((res) => {
        console.log(res)
        const keys = res.map((item) =>
          item.documentImages.map((image) => image.objectKey),
        )
        objeckKeyss.push(keys)
        if (res !== null) {
          const objectKeyPayload = {
            objectKeys: objeckKeyss[0][0],
          }
          if (objectKeyPayload.objectKeys !== undefined) {
            dispatch(getDlImages(objectKeyPayload))
              .unwrap()
              .then((res) => {
                if (res.code === 'STSI0000') {
                  setDlMode(true)
                  console.log(res)
                  setDlRearImage(res.data[0].preSignedUrl)
                  setDlFrontImage(res.data[1].preSignedUrl)
                }
              })
          }
        }
      })
  }

  const loadSiteName = (siteId) => {
    dispatch(getSiteById(siteId))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000') {
          console.log(JSON.stringify(data))
          setSiteName(data.data.name)
        } else {
          setSiteName('')
        }
      })
  }
  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const [date, setDate] = useState(false)

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(data.data.dateFormat)
        }
      })
  }, [])

  const handleClickOpenDlFront = () => {
    setOpen(true)
  }
  return (
    <div>
      <Tooltip title={t('VIEW')}>
        <div
          onClick={() => {
            handleClickOpen()
            handleClickOpenLivePhoto()
            handleClickOpenSignature()
            handleClickOpenVisitorDocuments()
          }}
        >
          {darkMode ? (
            <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
          ) : (
            <ViewIcon />
          )}
        </div>
      </Tooltip>
      <Grid>
        <BootstrapDialog
          darkMode={darkMode}
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(2px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          }}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            <Avatar
              alt={props.rowData.visitor.firstName}
              src={
                props.rowData.visitor.profilePhotoURL !== undefined
                  ? props.rowData.visitor.profilePhotoURL
                  : null
              }
              sx={{
                width: 150,
                height: 150,
                bottom: '80px',
                margin: 'auto',
                border: 'solid #FFFF',
                borderWidth: '7px',
              }}
            />
            <div className="CssTextProfileViewPageName" style={{ color: darkMode ? '#fff' : '#3D4977' }}>
              {props.rowData.visitor.lastName === null
                ? props.rowData.visitor.firstName
                : props.rowData.visitor.firstName +
                ' ' +
                props.rowData.visitor.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone" style={{ color: darkMode ? '#fff' : '#3D4977' }}>
              {props.rowData.visitor.phone}
            </div>
          </BootstrapDialogTitle>
          <Divider variant="inset" className="MuiDividerCss" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
          <DialogContent
            style={{
              marginLeft: '70px',
              marginRight: '70px',
              height: '400px',
              overflow: 'hidden',
            }}
          >
            <PerfectScrollbar>
              <Grid
                container
                spacing={12.25}
                className="CssTextProfileViewPageContent"
                marginTop={0.5}
              >
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('COMMON042')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Tooltip title={props.rowData.visitor.email}>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {handleTextOverflow(props.rowData.visitor.email)}
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('commonsTextFeildHostValue')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Tooltip title={props.rowData.hostName}>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {handleTextOverflow(props.rowData.hostName)}
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('PURPOSETYPE')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    marginLeft={10}
                    className="CssTextProfileViewPageContentValue"
                  >
                    {props.rowData.purposeType?.name}
                  </Grid>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('COMMON068')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    marginLeft={10}
                    className="CssTextProfileViewPageContentValue"
                  >
                    {props.rowData.visitorType?.name}
                  </Grid>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('SITE013')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Tooltip title={siteName}>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {handleTextOverflow(siteName)}
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('VISUPDATE0006')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Tooltip title={props.rowData.visitor.visitorCompanyName}>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {handleTextOverflow(
                        props.rowData.visitor.visitorCompanyName,
                      )}
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('INVITESTARTDATE')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    marginLeft={10}
                    className="CssTextProfileViewPageContentValue"
                  >
                    {/* {inviteDate()}  */}
                    {date === "MM-dd-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("MM-DD-YYYY HH:mm")
                      : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("DD-MM-YYYY HH:mm")
                        : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("YYYY-MM-DD HH:mm") : ''}
                  </Grid>
                </Grid>
                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('INVITENDATE')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    marginLeft={10}
                    className="CssTextProfileViewPageContentValue"
                  >
                    {/* {inviteEndDate()} */}
                    {date === "MM-dd-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("MM-DD-YYYY HH:mm")
                      : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YYYY HH:mm")
                        : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("YYYY-MM-DD HH:mm") : ''}
                  </Grid>
                </Grid>


                {/* {props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Invite End Date</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                { (moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YY HH:mm"))}
                                </Grid>
                            </Grid> : "" } */}

                {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('Type')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {props.rowData.visitMode === 'WALKIN_REGISTARTION'
                        ? t('WALKINREPORTLBL')
                        : props.rowData.visitMode === 'INVITATION'
                          ? t('labelDashBoardTotalReschedule')
                          : props.rowData.visitMode ===
                            'WALKIN_REGISTARTION_KIOSK'
                            ? 'Walkin Registration Kiosk'
                            : props.rowData.visitMode === 'SELF_REGISTARTION'
                              ? t('APPROVALITEMS002')
                              : props.rowData.visitMode}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('Type')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {props.rowData.visitMode === 'WALKIN_REGISTARTION'
                        ? 'Walk-In Registration'
                        : props.rowData.visitMode === 'INVITATION'
                          ? 'Invitation'
                          : props.rowData.visitMode ===
                            'WALKIN_REGISTARTION_KIOSK'
                            ? 'Walkin Registration Kiosk'
                            : props.rowData.visitMode === 'SELF_REGISTARTION'
                              ? 'Self Registration'
                              : props.rowData.visitMode}
                    </Grid>
                  </Grid>
                )}

                {/* <Grid
                                //  style={{ display: props.rowData.verifyTypeIn ? 'block' : 'none' }}
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Access Level</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.verifyTypeIn}</Grid>
                            </Grid> */}

                <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                  <Grid item xs={3}>
                    {t('COMMON001')}
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    marginLeft={10}
                    className="CssTextProfileViewPageContentValue"
                  >
                    <span
                      style={{
                        color: '#EDBD37',
                        backgroundColor: '#EDBD3726',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'SCHEDULED'
                        ? t('VISSTATUS011')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#A6C212',
                        backgroundColor: '#A6C21226',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'CONFIRMED'
                        ? t('VISSTATUS012')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#E3393C',
                        backgroundColor: '#E3393C26',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'CANCELLED'
                        ? t('VISSTATUS013')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#E3393C',
                        backgroundColor: '#E3393C26',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'REJECTED'
                        ? t('VISSTATUS014')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#287ADC',
                        backgroundColor: '#287ADC26',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'CHECKED_IN'
                        ? t('VISSTATUS017')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#03ACBF',
                        backgroundColor: '#03ACBF26',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'CHECKED_OUT'
                        ? t('VISSTATUS018')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#36C96D',
                        backgroundColor: '#36C96D26',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'APPROVED'
                        ? t('VISSTATUS015')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#ED8637',
                        backgroundColor: '#ED863726',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'MISSED'
                        ? t('VISSTATUS016')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#D53033',
                        backgroundColor: '#D5303326',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'BLOCKED'
                        ? t('VISSTATUS019')
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#D53033',
                        backgroundColor: '#D5303326',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'INITIATED_FOR_BLOCKED'
                        ? 'Blacklist Initiated'
                        : ''}
                    </span>

                    <span
                      style={{
                        color: '#008000',
                        backgroundColor: '#ccffcc',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'COMPLETED' ? ' Completed' : ''}
                    </span>

                    <span
                      style={{
                        color: '#008000',
                        backgroundColor: '#ccffcc',
                        opacity: 1,
                      }}
                    >
                      {props.rowData.status === 'LOGGED' ? ' Logged' : ''}
                    </span>
                  </Grid>
                </Grid>

                {props.rowData.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
                  <Grid container spacing={2} p={1.25}>
                    <Grid item xs={3}>
                      {t('COMMON016')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {props.rowData.remarks}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}

                {props.rowData.visitMode === 'WALKIN_REGISTARTION' ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('COMMON016')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Tooltip title={props.rowData.approvalReason}>
                      <Grid
                        item
                        marginLeft={10}
                        className="CssTextProfileViewPageContentValue"
                      >
                        {handleTextOverflow(props.rowData.approvalReason)}
                      </Grid>
                    </Tooltip>
                  </Grid>
                ) : (
                  ''
                )}

                {props.rowData.visitMode === 'INVITATION' ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('COMMON016')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      {props.rowData.approvalReason}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}

                {props.rowData.visitMode === 'SELF_REGISTARTION' ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('COMMON016')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Tooltip title={props.rowData.remarks}>
                      <Grid
                        item
                        marginLeft={10}
                        className="CssTextProfileViewPageContentValue"
                      >
                        {handleTextOverflow(props.rowData.remarks)}
                      </Grid>
                    </Tooltip>
                  </Grid>
                ) : (
                  ''
                )}

                {props.rowData.signature ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('Signature')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      <SimpleDialogBox
                        button={
                          <Avatar
                            style={{
                              cursor: 'pointer',
                              width: '95px',
                              height: '50px',
                              borderRadius: '1px',
                            }}
                            alt={payload.signature}
                            src={signatureUrl}
                            onClick={handleClickOpenSignature}
                          ></Avatar>
                        }
                        child={
                          <Avatar
                            style={{
                              width: '455px',
                              height: '300px',
                              border: '12px solid #FFFF',
                              fontSize: '100px',
                              borderRadius: '1px',
                              cursor: 'pointer',
                            }}
                            alt={payload.signature}
                            src={signatureUrl}
                            onClick={handleClickOpenSignature}
                          ></Avatar>
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                {rowDataMode === 'WALKIN_REGISTARTION' ? (
                  Dlmode && dlFrontImage ? (
                    <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                      <Grid item xs={3}>
                        DL Image
                      </Grid>
                      <Grid item xs={3}>
                        :
                      </Grid>
                      <Grid
                        item
                        marginLeft={10}
                        className="CssTextProfileViewPageContentValue"
                      >
                        <SimpleDialogBox
                          button={
                            <Avatar
                              style={{
                                cursor: 'pointer',
                                width: '95px',
                                height: '50px',
                                borderRadius: '1px',
                              }}
                              alt={payload.signature}
                              src={dlFrontImage}

                            //  onClick={handleClickOpenDlFront}
                            ></Avatar>
                          }
                          child={
                            <Avatar
                              style={{
                                width: '455px',
                                height: '300px',
                                border: '12px solid #FFFF',
                                fontSize: '100px',
                                borderRadius: '1px',
                                cursor: 'pointer',
                              }}
                              alt={payload.signature}
                              src={dlFrontImage}

                            //   onClick={handleClickOpenDlFront}
                            ></Avatar>
                          }
                        />
                        <div className="vertical">
                          <SimpleDialogBox
                            button={
                              <Avatar
                                style={{
                                  cursor: 'pointer',
                                  width: '95px',
                                  height: '50px',
                                  borderRadius: '1px',
                                  left: '15px',
                                  bottom: '8px',
                                }}
                                alt={payload.signature}
                                src={dlRearImage}
                              //  onClick={handleClickOpenDlBack}
                              ></Avatar>
                            }
                            child={
                              <Avatar
                                style={{
                                  width: '455px',
                                  height: '300px',
                                  border: '12px solid #FFFF',
                                  fontSize: '100px',
                                  borderRadius: '1px',
                                  cursor: 'pointer',
                                }}
                                alt={payload.signature}
                                src={dlRearImage}

                              //   onClick={handleClickOpenDlBack}
                              ></Avatar>
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                      <Grid item xs={3}>
                        DL Image
                      </Grid>
                      <Grid item xs={3}>
                        :
                      </Grid>
                      <Grid
                        item
                        marginLeft={8.5}
                        className="CssTextProfileViewPageContentValue"
                      >
                        <div>
                          <SimpleDialogBox
                            button={
                              <Avatar
                                style={{
                                  cursor: 'pointer',
                                  width: '95px',
                                  height: '50px',
                                  borderRadius: '1px',
                                  left: '10px',
                                  bottom: '9px',
                                }}
                                alt={payload.signature}
                                src={dlRearImage}
                              //  onClick={handleClickOpenDlBack}
                              ></Avatar>
                            }
                            child={
                              <Avatar
                                style={{
                                  width: '455px',
                                  height: '300px',
                                  border: '12px solid #FFFF',
                                  fontSize: '100px',
                                  borderRadius: '1px',
                                  cursor: 'pointer',
                                }}
                                alt={payload.signature}
                                src={dlRearImage}

                              //   onClick={handleClickOpenDlBack}
                              ></Avatar>
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )
                ) : (
                  ''
                )}

                {(props.rowData.status === 'CHECKED_IN' ||
                  props.rowData.status === 'CHECKED_OUT') &&
                  (props.rowData.checkInlivePhotoKey === null ||
                    props.rowData.checkInlivePhotoKey === '') ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('CHECKINPHOTO')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      <Grid>
                        <SimpleDialogDemo
                          button={
                            <Avatar
                              style={{
                                cursor: 'pointer',
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                              }}
                              alt={payload.livePhoto}
                              src={livePhotoUrl}
                              onClick={handleClickOpenLivePhoto}
                            ></Avatar>
                          }
                          child={
                            <Avatar
                              style={{
                                width: '300px',
                                height: '300px',
                                cursor: 'pointer',
                              }}
                              alt={payload.livePhoto}
                              src={livePhotoUrl}
                              onClick={handleClickOpenLivePhoto}
                            ></Avatar>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      {t('CHECKINPHOTO')}
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      marginLeft={10}
                      className="CssTextProfileViewPageContentValue"
                    >
                      <Grid>
                        <SimpleDialogDemo
                          button={
                            <Avatar
                              style={{
                                cursor: 'pointer',
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                              }}
                              alt={payload.livePhoto}
                              src={checkInPhotoUrl}
                            ></Avatar>
                          }
                          child={
                            <Avatar
                              style={{
                                width: '300px',
                                height: '300px',
                                cursor: 'pointer',
                              }}
                              alt={payload.livePhoto}
                              src={checkInPhotoUrl}
                            ></Avatar>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {rowDataMode === 'WALKIN_REGISTARTION' ||
                  rowDataMode === 'SELF_REGISTARTION' ||
                  rowDataMode === 'WALKIN_REGISTARTION_KIOSK' ? (
                  <Grid container spacing={2} p={1.25} style={{ color: darkMode ? '#fff' : '#3D4977' }}>
                    <Grid item xs={3}>
                      Captured Images
                    </Grid>
                    <Grid item xs={3}>
                      :
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      spacing={1}
                      marginLeft={-15}
                    >
                      {imageArray.map((imageUrl, index) => (
                        <Grid item key={index}>
                          <SimpleDialogDemo
                            button={
                              <Avatar
                                style={{
                                  cursor: 'pointer',
                                  width: '60px',
                                  height: '60px',
                                  borderRadius: '50%',
                                  marginRight: '5px', // Adjust margin to control spacing
                                }}
                                alt={`Image ${index + 1}`}
                                src={imageUrl}
                                onClick={handleClickOpenVisitorDocuments}
                              />
                            }
                            child={
                              <Avatar
                                style={{
                                  width: '300px',
                                  height: '300px',
                                  cursor: 'pointer',
                                }}
                                alt={`Image ${index + 1}`}
                                src={imageUrl}
                                onClick={handleClickOpenVisitorDocuments}
                              />
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </PerfectScrollbar>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  )
}

export default VisitorRegistrationViewPage
