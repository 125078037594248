import { Dialog, makeStyles } from '@material-ui/core'
import { DialogActions, DialogTitle, Divider, Typography } from '@mui/material'
import jwt_decode from 'jwt-decode'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import SubmitButtons from '../DialogBoxComponent/SubmitButtons'
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '20px',
    minWidth: '600px',
  },
}))

export default function WhatsNewDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles()
  const { darkMode } = useContext(ThemeContext);
  const latestVersion = props.settings.latestVersion
  const settingId = props.settings.id
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const [payload, setPayload] = useState(props.settings)

  const handleClose = () => {
    props.onClose(false)
  }
  const dispatch = useDispatch()

  const handleSubmit = () => {
    const settingPayload = {
      ...payload,
      id: settingId,
      viewedVersion: latestVersion,
    }
    dispatch(updateSettings(settingPayload))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0071') {
          handleClose()
        }
      })
  }

  const handleOkClick = (e) => {
    handleSubmit()
    e.stopPropagation()
  }
  const handleHeaderClick = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        {...props}
        open={props.open}
        onClose={() => { }}
      >
        <DialogTitle variant="h4" onClick={handleHeaderClick} style={{
          backgroundColor: darkMode ? '#0F273B' : '#fff',
          color: darkMode ? '#fff' : '#434547'
        }}>
          {props.Header}
        </DialogTitle>
        <Divider  style={{backgroundColor:darkMode ? '#fff' : '#0F273B'}}
         />

        <Typography
          variant="h5"
          style={{
            marginBottom: '10px',
            marginLeft: '20px',
            marginTop: '20px',
            backgroundColor: darkMode ? '#0F273B' : '',
            color: darkMode ? '#fff' : '#434547'
          }}
        >
          {t('WHAT_NEW_IMPLEMENTATION') }
        </Typography>
        <br/>
        <ul style={{
          marginLeft: '20px', backgroundColor: darkMode ? '#0F273B' : '#fff',
          color: darkMode ? '#fff' : '#434547'
        }}>
          <li>{t('WHAT_NEW_REPORT_AND_CREATE_INTEGRATIONS')}</li>
          <li>{t('WHAT_NEW_TWO_FACTOR_AUTHEN')}</li>
          <li>{t('WHAT_NEW_AUTO_GENERATE')}</li>
          <li>{t('WHAT_NEW_DYNAMIC_PURPOSE')}</li>
          <li>{t('WHAT_NEW_DYNAMIC_VISITOR')}</li>
          <li>{t('WHAT_NEW_CRIMINAL_OFFENCE')}</li>
          <li>{t('WHAT_NEW_DARK_MODE')}</li>
          <li>{t('WHAT_NEW_MULTIPLE_DATE_FORMATS')}</li>
        </ul>

        <DialogActions
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
            marginLeft: '120px',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleOkClick}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {t('OK_BUTTON')}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}
