import { createTheme, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Toast from "../../components/ToastContainer/CustomToast";
import api from "../../config/http-common";
import { rescheduleVisitor } from "../../slices/Invitations/InvitationsSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from "../../Util/CommonUtils";
import CustomDateTimePicker from "../CustomInputs/CustomDateTimePicker";
import CancelButtons from "../DialogBoxComponent/CancelButtons";
import SubmitButtons from "../DialogBoxComponent/SubmitButtons";

const MuiThemeTwo = createTheme({
    palette: {
        type: "light",
        primary: {
            main: process.env.REACT_APP_BG_ICON,
        },
        secondary: {
            main: "#f50057"
        }
    },
});

const useStyles = makeStyles((theme) => ({
    cssLabel: {
        color: '#0000008a'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#1976d2 !important',
        }
    },
    cssFocused: {
        color: '#1976d2 !important'
    },

    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#C4C4C4 !important'
    },

}));
const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: process.env.REACT_APP_BG_ICON,
        color: '#FFFF'
    },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: process.env.REACT_APP_BG_ICON,
        color: '#FFFF'
    },
}));
export default function RescheduleVisitor(props) {
    const { darkMode } = useContext(ThemeContext);
    const { open, handleClose } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [date, setDate] = useState();
    const [changeDate, setDateChange] = useState(true);
    const [error, setError] = React.useState({
        scheduledStartDate: false,
        scheduledEndDate: false,
    });

    const formatDayAndMonth = (numValue) => {
        if (numValue <= 0) {
            return "-" + (numValue + 12);
        }
        if (numValue < 10) {
            return "-0" + numValue;
        } else {
            return "-" + numValue;
        }
    };
    var today = new Date();
    var endDate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T23:59";
    var startDate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T" +
        (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
        ":" +
        (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

    const [state, setState] = React.useState({
        id: props.CustomerID,
        scheduledStartDate: startDate,
        scheduledEndDate: endDate,
    });

    console.log("start", state.scheduledStartDate);
    console.log("end", state.scheduledEndDate);

    const onFailure = (data) => {
        if (CommonUtil.isEmpty(data)) {
            Toast(t("COMMON000"), 'error')
            return;
        }
        switch (data.code) {
            case "CVAE1021":
                setError({ ...error, scheduledStartDate: true });
                break;
            case "CVAE1022":
                setError({ ...error, scheduledEndDate: true });
                break;
            case "CVAE1023":
                setError({ ...error, scheduledEndDate: true });
                break;
        }
        Toast((data.message), 'error')
    };

    const handleSumbit = () => {

        if (
            state.scheduledStartDate === null ||
            state.scheduledStartDate === undefined ||
            state.scheduledStartDate === ""
        ) {
            setError({ scheduledStartDate: true });
            Toast(t("INVITATIONFORM011"), 'error');
            return;
        }

        if (
            state.scheduledEndDate === null ||
            state.scheduledEndDate === undefined ||
            state.scheduledEndDate === ""
        ) {
            setError({ scheduledEndDate: true });
            Toast(t("INVITATIONFORM012"), 'error')
            return;
        }

        if (new Date(state.scheduledStartDate) < new Date()) {
            Toast(t("INVITATIONFORM010"), 'error')
            return;
        }

        if (new Date(state.scheduledStartDate) < new Date(state.scheduledEndDate)) {
            const formstate = {};
            formstate.scheduledStartDate = CommonUtil.formatToUtc(
                state.scheduledStartDate
            );
            formstate.scheduledEndDate = CommonUtil.formatToUtc(
                state.scheduledEndDate
            );
            formstate.id = state.id
            dispatch(rescheduleVisitor(formstate))
                .unwrap().then((data) => {
                    if (data.code === 'CVAI0069') {
                        handleClose(false);
                        setDateChange(true);
                        props.reschedule();
                        Toast(t("RESCHEDULEFORM001"), 'success')
                    } else {
                        onFailure(data);
                    }
                });
        }
    }

    useEffect(() => {
        api.securedAxios().get('/web/getSettings').then((res) => {
            if (res.code === "CVAI0000") {
                setDate(res.data.data.dateFormat)
            }
        })
    }, [props.open])

    return (
        <>
            <Dialog open={open} fullWidth={true}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(8px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
                    },
                }}
            >
                <DialogTitle variant="h4" onClick={() => handleClose(false)} style={{ backgroundColor: darkMode ? '#0F273B' : '', color: darkMode ? '#fff' : '' }}>
                    <IconButton
                        onClick={() => handleClose(false)}
                        sx={{
                            position: "absolute",
                            right: 15,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {t('RESCHEDULEFORM002')}
                </DialogTitle>
                <Divider variant="middle" />
                <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '' }}>
                    <Grid container justifyContent="center" direction="column" alignItems={"center"}  >
                        {/* <Grid  style={{marginTop:'15px'}}> */}
                        <FormControl variant="outlined" size="small" style={{ minWidth: "440px", paddingTop: '15px' }}>
                            <CustomDateTimePicker
                                onChange={(scheduledStartDate) =>
                                    setState({
                                        ...state,
                                        scheduledStartDate: scheduledStartDate,
                                    })
                                }
                                value={state.scheduledStartDate}
                                label={t("INVITESTARTDATEERROR1")}
                                changeDate={changeDate}
                                date={props.date}
                            />
                            <Grid sx={{ mt: 5 }}>
                                <CustomDateTimePicker
                                    onChange={(scheduledEndDate) =>
                                        setState({
                                            ...state,
                                            scheduledEndDate: scheduledEndDate,
                                        })
                                    }
                                    value={state.scheduledEndDate}
                                    minDateTime={state.scheduledStartDate}
                                    label={t("INVITESTARTDATEERROR2")}
                                    changeDate={changeDate}
                                    date={props.date}
                                />
                            </Grid>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    justifyContent: "center",
                    backgroundColor: darkMode ? '#0F273B' : ''
                }}>
                    <SubmitButtons variant='contained' onClick={handleSumbit}>
                        <Typography variant="body1" style={{ textTransform: "none" }}>
                            {t("COMMON007")}
                        </Typography>
                    </SubmitButtons>
                    <CancelButtons variant='contained' color="secondary" onClick={() => handleClose(false)}>
                        <Typography variant="body1" style={{ textTransform: "none" }}>
                            {t("COMMON008")}
                        </Typography>
                    </CancelButtons>
                </DialogActions>
            </Dialog>
        </>
    );

}