import { Avatar, Grid, Tooltip, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import copyIcon from '../../assets/USA_Images/copyIcon.svg';
import HeaderToolbar from '../../components/HeaderToolbar';
import { getUserDetails } from '../../slices/Dealer/DealerSlice';

function KeyConfigView(props) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const dispatch = useDispatch();
  const loginDetails = props.location.state.loginDetailsDTOV4s || [];

  const handleTextOverflow = (text, limit = 35) => text && text.length > limit ? text.substring(0, 25) + '...' : text;

  useEffect(() => {
    loadUserDetails();
  }, []);

  const loadUserDetails = () => {
    dispatch(getUserDetails('rgf'))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0033') {
          setFirstName(data.data.firstName);
          setLastName(data.data.lastName);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => setCopied(true))
      .catch((error) => console.error('Error copying text: ', error));
  };

  const generateActivationKeyText = (platform) => {
    const iosKeys = `
      pdf Detector activationKey: ${props.location.state.pdfDetectorActivationKey}
      Mrz Detector activationKey: ${props.location.state.mrzDetectorActivationKey}
      pdf Parser activationKey: ${props.location.state.pdfParserActivationKey}
    `;
    const androidKeys = `
      private const val PARSER_KEY: ${props.location.state.privateConstValParserKey}
      private const val SCANNER_KEY: ${props.location.state.privateConstValScannerKey}
    `;
    return platform === 'ios' ? iosKeys : androidKeys;
  };
  console.log(props.location.state.loginDetailsDTOV4s)
  return (
    <>
      <HeaderToolbar back_button={true} title={t('KEYCONFIGURATIONVIEW')} />

      <Grid container sx={{ mt: '20px', bgcolor: 'white' }}>
        <Grid item padding={2}>
          <Avatar
            alt={firstName}
            src={''} // Update with actual src
            sx={{ height: '180px', width: '180px', ml: 4 }}
          />
        </Grid>
        <Grid item xs sx={{ ml: 2, mt: 2, borderRight: '3px solid #E1E9F5' }}>
          {[
            { label: t('DEALERFIRSTNAME'), value: props.location.state.firstName },
            { label: t('DEALERLASTNAME'), value: props.location.state.lastName },
            { label: t('DEALEREMAIL'), value: props.location.state.customerEmail },
            { label: t('VIEWPAGEMOBLIENUMBER'), value: props.location.state.customerMobile }
          ].map((field, index) => (
            <Grid container wrap="nowrap" sx={{ mt: index === 0 ? 0 : 2 }} key={field.label}>
              <Grid item xs={6}>
                <Typography noWrap sx={{ opacity: 0.5 }}>{field.label}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={field.value} placement="bottom-start">
                  <Typography noWrap>{handleTextOverflow(field.value)}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* More Details Section */}
        <Grid item xs sx={{ ml: 2, mt: 2, borderRight: '3px solid #E1E9F5' }}>
          {[
            { label: t('CUSTDATABLE22'), value: props.location.state.dealerName },
            { label: t('DEALERCOMPANYNAME'), value: props.location.state.customerName },
            { label: t('CUSTDATABLE04'), value: props.location.state.firstName + " " + props.location.state.lastName },
            { label: t('LABELNAVITEMSSUBSCRIPTION'), value: props.location.state.customerSubscriptionName + "/" + props.location.state.subscriptionMode }
          ].map((field, index) => (
            <Grid container wrap="nowrap" sx={{ mt: index === 0 ? 0 : 2 }} key={field.label}>
              <Grid item xs={6}>
                <Typography noWrap sx={{ opacity: 0.5 }}>{field.label}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={field.value} placement="bottom-start">
                  <Typography noWrap>{handleTextOverflow(field.value)}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs sx={{ ml: 2, mt: 2, borderRight: '3px solid #E1E9F5' }}>
          {[
            { label: t('KEYCONFIGURATIONNUMBERNOT'), value: props.location.state.numberOfDevices }
          ].map((field, index) => (
            <Grid container wrap="nowrap" sx={{ mt: index === 0 ? 0 : 2 }} key={field.label}>
              <Grid item xs={6}>
                <Typography noWrap sx={{ opacity: 0.5 }}>{field.label}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={field.value} placement="bottom-start">
                  <Typography noWrap>{handleTextOverflow(field.value)}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/**IOS KEY COPY */}
      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height: '160px' }}
      >
        <Grid item padding={2} sx={{ marginLeft: "10px" }}>
          <Typography>
            <b>{t('CUSTDATABLE31')}:</b> &nbsp;
            <Tooltip title={t('COPY_TOOL_TIP')}>
              <img
                style={{
                  height: '20px',
                }}
                alt="Copy"
                src={copyIcon}
                onClick={() => handleCopy(generateActivationKeyText('ios'))}
              />
            </Tooltip>
          </Typography>
          <Typography sx={{ marginTop: '14px' }}>
            {t('KEYCONFIGURATIONPDFDETECTOR')} : {props.location.state.pdfDetectorActivationKey}
          </Typography>
          <Typography sx={{ marginTop: '14px' }}>
            {t('KEYCONFIGURATIONMRZDETECTOR')}  : {props.location.state.mrzDetectorActivationKey}
          </Typography>
          <Typography sx={{ marginTop: '14px' }}>
            {t('KEYCONFIGURATIONPDFPARSER')}  : {props.location.state.pdfParserActivationKey}
          </Typography>
        </Grid>
      </Grid>
      {/**ANDIROD KEY COPY */}
      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height: '150px' }}
      >
        <Grid item padding={2} sx={{ marginLeft: "10px" }}>
          <Typography>
            <b>{t('CUSTDATABLE32')}:</b> &nbsp;
            <Tooltip title={t('COPY_TOOL_TIP')}>
              <img
                style={{
                  height: '20px',
                }}
                alt="Copy"
                src={copyIcon}
                onClick={() => handleCopy(generateActivationKeyText('android'))}
              />
            </Tooltip>
          </Typography>
          <Typography sx={{ marginTop: '14px' }}>
            {t('KEYCONFIGURATIONANDROIDKEYPRIVATEPARSER')} : {props.location.state.privateConstValParserKey}
          </Typography>
          <Typography sx={{ marginTop: '14px' }}>
            {t('KEYCONFIGURATIONANDROIDKEYPRIVATESCANNER')} : {props.location.state.privateConstValScannerKey}
          </Typography>
        </Grid>
      </Grid>
      {/* Device History Table */}
      <Grid container  sx={{ mt: '20px', bgcolor: 'white', height: '250px'}}>
        <Grid item padding={2} sx={{ ml: 1, maxHeight: '200px' }}>
          <Typography>
            <b>{t('VIEWPAGEKEYCONFIGURATIONDEVICEHISTORY')}:</b>
          </Typography>
          <Table sx={{ mt: 2, borderCollapse: 'collapse' }}>
            <TableHead>
              <TableRow sx={{ borderBottom: 'none' }}>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>S.No</TableCell>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>Device Type</TableCell>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>Device Name OS</TableCell>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>OS Version</TableCell>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>Hardware ID</TableCell>
                <TableCell sx={{ p: '2px 20px', borderBottom: 'none' }}>Latitude & Longitude</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loginDetails.map((detail, index) => (
                <TableRow key={detail.id || index} sx={{ '& td': { borderBottom: 'none', padding: '10px 20px' }, mt: 1 }}>
                  <TableCell sx={{ p: '2px 20px', borderRight: '0.5px solid' }}>{index + 1}.</TableCell>
                  <TableCell sx={{ p: '2px 20px', borderRight: '0.5px solid' }}>{detail.deviceType || ''}</TableCell>
                  <TableCell sx={{ p: '2px 20px', borderRight: '0.5px solid' }}>{detail.deviceNameModel || ''}</TableCell>
                  <TableCell sx={{ p: '2px 20px', borderRight: '0.5px solid' }}>{detail.osModel || ''}</TableCell>
                  <TableCell sx={{ p: '2px 20px', borderRight: '0.5px solid' }}>
                    {Array.isArray(detail.deviceIds) ? detail.deviceIds.join(', ') : detail.deviceId || ''}
                  </TableCell>
                  <TableCell sx={{ p: '2px 20px' }}>{detail.latitudeAndLongitude || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
}

export default KeyConfigView;