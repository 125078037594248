import api from '../../config/http-common'

const getAccountStatics = (payload) => {
  return api.securedAxios().get('/web/account/statics?cascadeLimit=' + 2)
}

const getAccountList = (payload) => {
  return api
    .securedAxios()
    .post(
      '/web/accounts?cascadeLimit=' +
      payload.cascadeLimit +
      '&pageNumber=' +
      payload.page.pageNumber +
      '&pageSize=' +
      payload.page.pageSize,
      payload.accountDTO,
    )
}

const postChildSwitchCompany = (payload) => {
  return api.securedAxios().post('/web/impersonate/user/' + payload.id, payload)
}

const adminDashboardService = {
  getAccountStatics,
  getAccountList,
  postChildSwitchCompany,
}

export default adminDashboardService
