import { Box, Link, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import AddSiteIcon from '../../../assets/AddSiteIcon.svg'
import DataTable from '../../../components/DataTable/DataTable'
import HeaderToolbar from '../../../components/HeaderToolbar'
import Search from '../../../components/SearchTab/Search'
import Toast from '../../../components/ToastContainer/CustomToast'
import { getAddressTypes } from '../../../slices/Site/AddressTypeSlice'
import { ThemeContext } from '../../../theme/ThemeContext'
import ChecklistTemplateDetails from '../../Checklist/ChecklistTemplateDetails'
import EditChecklistIcon from '../../Checklist/EditChecklistIcon'
import DeleteIcon from '../../Watchlist/DeleteIcon'
import AddressTypeFilter from './AddressTypeFilter'
import CreateAddressType from './CreateAddressType'
import DeleteAddressType from './DeleteAddressType'

function AddressType() {
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchedRows, setSerchedRows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [clear, setClear] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDeleteAddressType, setOpenDeleteAddressType] = useState(false)
  const [addressTypeId, setAddressTypeId] = useState()
  const [addressTypeName, setAddressTypeName] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })
  const [query, setQuery] = useState({
    name: '',
    code: '',
  })

  const defaultQuery = {
    name: '',
    code: '',
  }

  const [selectedObject, setSelectedObject] = useState({
    id: '',
    name: '',
    code: '',
    description: '',
  })

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(query, paging)
  }, [query, paging, clear])

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setQuery(defaultQuery)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const loadData = (query, paging) => {
    dispatch(getAddressTypes(Object.assign(query, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000' && data.data) {
          setRows(data.data.addressTypes)
          setTotalRecords(data.data.totalCount)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => { })
  }

  const handleOpen = () => {
    setOpen(true)
    setDiaLogBoxBlur(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEditAddressType = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
    setDiaLogBoxBlur(true)
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(query, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const handleDeleteAddressType = (params) => {
    setOpenDeleteAddressType(!openDeleteAddressType)
    setAddressTypeId(params.row.id)
    setAddressTypeName(params.row.name)
    setDiaLogBoxBlur(true)
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        test.code.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.description
          ? test.description.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setQuery({
      name: data.name,
      code: data.code,
    })
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },
    {
      field: 'name',
      headerName: t('COMMON044'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.name}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.name)}</span>
        </Tooltip>
      ),
    },
    process.env.REACT_APP_ENVIRONMENT === 'USA'
      ? {
        field: 'code',
        headerName: t('COMMON067'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.code,
      }
      : {
        field: 'code',
        headerName: t('COMMON045'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.code,
      },
    {
      field: 'description',
      headerName: t('ATYPE009'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.description}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.description)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <ChecklistTemplateDetails
              rowData={params.row}
              title={
                <span style={{ color: darkMode ? '#fff' : '' }}>{t('ATYPE014')}</span>
              }
            />
          }
          label={t('VIEW')}
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('COMMON005')}
          onClick={() => handleEditAddressType(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t('COMMON004')}
          onClick={() => handleDeleteAddressType(params)}
        />,
      ],
    },
  ])

  return (
    <>
      <HeaderToolbar
        src={AddSiteIcon}
        onClick={handleOpen}
        title={t('ATYPE001')}
        tooltipTitle={t('ATYPE002')}
      />
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>
        <Box item>
          <AddressTypeFilter
            onSubmit={(data) => {
              handleFilter(data)
            }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(query, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {open && (
        <CreateAddressType
          open={open}
          selectedObject={selectedObject}
          handleClose={() => {
            setOpen(false)
            loadData(query, paging)
            setDiaLogBoxBlur(false)
          }}
        />
      )}
      ,
      {openEditDialog && (
        <CreateAddressType
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            setDiaLogBoxBlur(false)
            setSelectedObject({
              id: '',
              name: '',
              code: '',
              description: '',
            })
            loadData(query, paging)
          }}
        />
      )}
      ,
      {openDeleteAddressType && (
        <DeleteAddressType
          open={openDeleteAddressType}
          handleClose={(data) => {
            setOpenDeleteAddressType(data)
            setDiaLogBoxBlur(false)
          }}
          delete={() => setDel(true)}
          DeleteAddressTypeId={addressTypeId}
          DeleteAddressTypeName={addressTypeName}
        />
      )}
    </>
  )
}

export default AddressType
