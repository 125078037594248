import {
  Box, FormGroup, Grid, Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Divider, IconButton, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { countriesList } from "../../../components/CustomInputs/CountriesList";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import CancelButtons from "../../../components/DialogBoxComponent/CancelButtons";
import SubmitButtons from "../../../components/DialogBoxComponent/SubmitButtons";
import Toast from "../../../components/ToastContainer/CustomToast";
import { getAddressTypes } from '../../../slices/Site/AddressTypeSlice';
import { createSite, getAllSites, updateSite } from "../../../slices/Site/SiteSlice";
import { getSiteTypeList } from "../../../slices/Site/SiteTypeSlice";
import { ThemeContext } from '../../../theme/ThemeContext';
import CommonUtil from "../../../Util/CommonUtils";
import {
  cityData,
  stateData,
  timeZoneData
} from "./AddressFormData";


const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: "both",
  },
  heading: {
    fontSize: '16px',
    fontWeight: 500,
    width: "100%",
    textAlign: "left",
    color: process.env.REACT_APP_COLOR_ACCORDION
  },
  dialogbox: {
    '& .MuiDialog-paper': {
      minWidth: '110vh',
      borderRadius: '14px',
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: process.env.REACT_APP_BG_SWITCH,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: process.env.REACT_APP_BG_SWITCH,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function AddSite(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [open, setOpen] = React.useState(props.open);
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [selectArea, setSelectArea] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedSite, setExpandedSite] = React.useState(true);
  const [typeSite, setTypeSite] = React.useState('');
  const [parentSiteId, setParentSiteId] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [str, setStr] = useState("");
  const [selectedAddressTypeId, setSelectedAddressTypeId] = React.useState('');
  const [selectedTimeZone, setSelectedTimeZone] = React.useState('');
  const { darkMode } = useContext(ThemeContext);
  const handleValidation = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleValidationSite = (panel) => (event, isExpanded) => {
    setExpandedSite(isExpanded ? panel : false);
  };

  const [state, setState] = React.useState({
    id: "",
    name: "",
    description: "",
    parentId: "",
    siteTypeId: "",
    address: {
      id: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      country: "",
      state: "",
      city: "",
      areaCode: "",
      latitude: "",
      longitude: "",
      radius: "",
      timeZone: "",
      dayLightSaving: true,
      isDefault: true,
      addressTypeId: "",
      employeeId: "",
      userId: "",
      siteId: "",
    },
  });

  const [error, setError] = React.useState({
    name: false,
    description: false,
    parentId: false,
    siteTypeId: false,
    address: {
      addressLine1: false,
      addressLine2: false,
      addressLine3: false,
      country: false,
      state: false,
      city: false,
      areaCode: false,
      latitude: false,
      longitude: false,
      radius: false,
      timeZone: false,
      dayLightSaving: false,
      isDefault: false,
      addressTypeId: false,
      employeeId: false,
      userId: false,
      siteId: false,
    },
  });

  useEffect(() => {
    setTypeSite(siteTypes.find((site) => {
      return site.id === props.selectedObject.siteTypeId;
    }))
  }, [siteTypes?.length > 0 && !CommonUtil.isEmptyString(props.selectedObject?.siteTypeId)])

  useEffect(() => {
    setParentSiteId(parentSites.find((parent) => {
      return parent.id === props.selectedObject.parentId;
    }))
  }, [parentSites?.length > 0 && !CommonUtil.isEmptyString(props.selectedObject?.parentId)])

  useEffect(() => {
    setSelectedCountry(countries.find((countryObj) => {
      return countryObj.name === props.selectedObject.address.country;
    }))
  }, [countries?.length > 0 && !CommonUtil.isEmptyString(props.selectedObject?.address?.country)])

  useEffect(() => {
    setSelectedAddressTypeId(addressTypes.find((addressTypeObj) => {
      return addressTypeObj.id === props.selectedObject.address.addressTypeId;
    }))
  }, [addressTypes?.length > 0 && !CommonUtil.isEmptyString(props.selectedObject?.address?.addressTypeId)])


  useEffect(() => {
    setSelectedTimeZone(timeZones.find((zoneTime) => {
      return zoneTime.id === props.selectedObject.address.timeZone;
    }))
  }, [timeZones?.length > 0 && !CommonUtil.isEmptyString(props.selectedObject?.address?.timeZone)])

  useEffect(() => {
    setOpen(props.open);
    if (
      !CommonUtil.isEmpty(props.selectedObject) &&
      !CommonUtil.isEmpty(props.selectedObject.address)
    ) {
      setState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: parentSiteId,
        siteTypeId: typeSite,
        address: {
          id: props.selectedObject.address.id,
          addressLine1: props.selectedObject.address.addressLine1,
          addressLine2: props.selectedObject.address.addressLine2,
          addressLine3: props.selectedObject.address.addressLine3,
          country: selectedCountry,
          state: props.selectedObject.address.state,
          city: props.selectedObject.address.city,
          areaCode: props.selectedObject.address.areaCode,
          latitude: props.selectedObject.address.latitude,
          longitude: props.selectedObject.address.longitude,
          radius: props.selectedObject.address.radius,
          timeZone: selectedTimeZone,
          dayLightSaving: props.selectedObject.address.dayLightSaving,
          isDefault: props.selectedObject.address.isDefault,
          addressTypeId: selectedAddressTypeId,
          employeeId: props.selectedObject.address.employeeId,
          userId: props.selectedObject.address.userId,
          siteId: props.selectedObject.address.siteId,
        },
      });
    }
  }, [!CommonUtil.isEmptyString(typeSite) && !CommonUtil.isEmptyString(selectedCountry)]);
  // }, [props.open, props.selectedObject]);

  useEffect(() => {
    setOpen(props.open);
    if (
      !CommonUtil.isEmpty(props.selectedObject) &&
      CommonUtil.isEmpty(props.selectedObject.address)
    ) {
      setState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: props.selectedObject.parentId,
        siteTypeId: props.selectedObject.siteTypeId,
        address: {
          id: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          country: "",
          state: "",
          city: "",
          areaCode: "",
          latitude: "",
          longitude: "",
          radius: "",
          timeZone: "",
          dayLightSaving: true,
          isDefault: true,
          addressTypeId: "",
          employeeId: "",
          userId: "",
          siteId: "",
        },
      });
    }
  }, [props.open, props.selectedObject]);


  useEffect(() => {
    if (props.selectedObject.id) {
      setState({
        ...state,
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: parentSiteId,
        siteTypeId: typeSite,
        address: {
          id: props.selectedObject.address.id,
          addressLine1: props.selectedObject.address.addressLine1,
          addressLine2: props.selectedObject.address.addressLine2,
          addressLine3: props.selectedObject.address.addressLine3,
          country: selectedCountry,
          state: props.selectedObject.address.state,
          city: props.selectedObject.address.city,
          areaCode: props.selectedObject.address.areaCode,
          latitude: props.selectedObject.address.latitude,
          longitude: props.selectedObject.address.longitude,
          radius: props.selectedObject.address.radius,
          timeZone: selectedTimeZone,
          dayLightSaving: props.selectedObject.address.dayLightSaving,
          isDefault: props.selectedObject.address.isDefault,
          addressTypeId: selectedAddressTypeId,
          employeeId: props.selectedObject.address.employeeId,
          userId: props.selectedObject.address.userId,
          siteId: props.selectedObject.address.siteId,
        },
      })
    }
  }, [selectedAddressTypeId, parentSiteId, typeSite, selectedTimeZone, selectedCountry])

  const payload = {
    page: 1,
    rowsPerPage: 10000,
    code: '',
    name: ''
  };

  const payloadSiteType = {
    page: 1,
    rowsPerPage: 10000,
    filter: {
      code: '',
      name: ''
    }
  };

  useEffect(() => {

    dispatch(getAllSites(payload)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setParentSites(data.data.site);
      });

    dispatch(getSiteTypeList(payloadSiteType)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000')
          setSiteTypes(data.data.siteTypes);
      });

    dispatch(getAddressTypes(payload)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000')
          setAddressTypes(data.data.addressTypes);
      });

    setCountries(countriesList);
    setSelectStates(stateData);
    setCities(cityData);
    setTimeZones(timeZoneData);
  }, []);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.value,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange1 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.checked,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Site', JSON.stringify(state));

    if (CommonUtil.isEmptyString(state.name)) {
      Toast(t("ENTER_NAME"), 'error');
      setError({ name: true });
      return;
    }
    if (CommonUtil.isEmptyString(state.siteTypeId)) {
      Toast(t("SITE1"), 'error');
      setError({ siteTypeId: true });
      return false;
    }

    setExpanded("panel1");
    if (CommonUtil.isEmptyString(state.address.addressLine1)) {
      Toast(t("PERSONINFO2"), 'error');
      setError({ addressLine1: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.address.country)) {
      Toast(t("ATYPE0012"), 'error');
      setError({ country: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.address.city)) {
      Toast(t("ATYPE0019"), 'error');
      setError({ city: true });
      return false;
    }


    if (process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' : CommonUtil.isEmptyString(state.address.latitude)) {
      Toast(t("ATYPE0015"), 'error');
      setError({ latitude: true });
      return false;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' : CommonUtil.isEmptyString(state.address.longitude)) {
      Toast(t("ATYPE0014"), 'error');
      setError({ longitude: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.address.addressTypeId)) {
      Toast(t("ATYPE0011"), 'error');
      setError({ addressTypeId: true });
      return false;
    }
    const newState = {
      id: state.id,
      name: state.name,
      description: state.description,
      parentId: state.parentId ? state.parentId.id : "",
      siteTypeId: state.siteTypeId ? state.siteTypeId.id : "",
      address: {
        id: state.address.id,
        addressLine1: state.address.addressLine1,
        addressLine2: state.address.addressLine2,
        addressLine3: state.address.addressLine3,
        country: state.address.country.name,
        state: state.address.state,
        city: state.address.city,
        areaCode: state.address.areaCode,
        latitude: state.address.latitude,
        longitude: state.address.longitude,
        radius: state.address.radius,
        timeZone: state.address.timeZone ? state.address.timeZone.id : "",
        dayLightSaving: state.address.dayLightSaving,
        isDefault: state.address.isDefault,
        addressTypeId: state.address.addressTypeId ? state.address.addressTypeId.id : "",
        employeeId: state.address.employeeId,
        userId: state.address.userId,
        siteId: state.address.siteId,
      }
    }

    if (state.id === '')
      dispatch(createSite(newState)).unwrap().then((data) => {
        if (data.code === 'LMSI6010') {
          handleClose()
          Toast((data.message), 'success')
        }
        else
          Toast(data.message, 'error')
      });
    else
      dispatch(updateSite(newState)).unwrap().then((data) => {
        if (data.code === 'LMSE0042') {
          Toast((data.message), 'error')
        }
        if (data.code === 'LMSI6011') {
          handleClose()
          Toast((data.message), 'success')
        }
        else
          Toast(data.message, 'error')
      });
  };


  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        className={classes.dialogbox}
        BackdropProps={{
          style: {
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
          },
        }}

      >
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Box display="flex" m={1} >
            <Box flexGrow={1}>
              <Typography variant="h4" style={{ color: darkMode ? '#fff' : 'black', fontSize: '20px', fontWeight: '450' }}>
                {CommonUtil.isEmptyString(state.id)
                  ? t("SITE002")
                  : t("SITE008")}
              </Typography>
            </Box>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            > <CloseIcon /></IconButton>
          </Box>
        </DialogTitle>
        <Divider style={{ backgroundColor: darkMode ? '#fff' : '' }} />
        <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <>
            <Grid container justifyContent="center" alignItems="center" style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }} >
              <Accordion
                expanded={expandedSite}
                onChange={handleValidationSite(expandedSite ? false : true)}
                sx={{
                  width: '100%',
                  backgroundColor: darkMode ? '#0F273B' : '#fff'
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: process.env.REACT_APP_COLOR_ACCORDION, }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: process.env.REACT_APP_BG_ACCORDION,
                    borderRadius: "7px",
                    marginTop: "20px",
                    backgroundColor: darkMode ? "#1A364E" : "#E8E8E8",

                  }}
                >
                  <Typography className={classes.heading} style={{ color: darkMode ? "#fff" : "#737373" }}>
                    {t("SITE001")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form noValidate autoComplete="off">
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center" >
                          <CustomTextfield
                            required
                            error={error.name}
                            name="name"
                            label={t("COMMON044")}
                            value={state.name}
                            handleChange={(e) => handleChange(e)}
                            helperText=''
                            inputProps={{ className: classes.input, maxLength: 50 }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <Autocomplete
                          style={{ width: '100%' }}
                          noOptionsText={'No Options found'}
                          name='siteTypeId'
                          value={state.siteTypeId}
                          options={CommonUtil.isEmptyArray(siteTypes) ? [] : siteTypes}
                          getOptionLabel={(option) => (option.name ? (option.name !== "DEFAULT" ? option.name : "") : "")}
                          onChange={(event, newValue) => {
                            if (!CommonUtil.isEmpty(newValue)) {
                              setState({ ...state, siteTypeId: newValue });
                            } else {
                              setState({ ...state, siteTypeId: '' });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='siteTypeId'
                              value={state.siteTypeId}
                              error={error.siteTypeId}
                              variant='outlined'
                              fullWidth
                              label={t('STYPE001') + ' * '}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                  },
                                  '&:hover fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>


                      <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                        <Autocomplete
                          style={{ width: '100%' }}
                          noOptionsText={'No Options found'}
                          name='parentId'
                          value={state.parentId}
                          options={CommonUtil.isEmptyArray(parentSites) ? [] : parentSites}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          onChange={(event, newValue) => {
                            if (!CommonUtil.isEmpty(newValue)) {
                              setState({ ...state, parentId: newValue });
                            } else {
                              setState({ ...state, parentId: '' });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='parentId'
                              value={state.parentId}
                              error={error.parentId}
                              variant='outlined'
                              fullWidth
                              label={t('COMMON050')}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                  },
                                  '&:hover fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomTextfield
                          inputProps={{ className: classes.textarea, maxLength: 120 }}
                          label={t("SITE009")}
                          value={state.description}
                          helperText=''
                          name="description"
                          handleChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleValidation("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: process.env.REACT_APP_COLOR_ACCORDION, }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: process.env.REACT_APP_BG_ACCORDION,
                    borderRadius: "0px",
                    backgroundColor: darkMode ? '#1A364E' : '#E8E8E8'
                  }}
                >
                  <Typography className={classes.heading} style={{ color: darkMode ? "#fff" : "#737373" }} s>
                    {t("ADD012")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
                  <form noValidate autoComplete="off" >
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            required
                            error={error.addressLine1}
                            name="addressLine1"
                            label={t("ADD001")}
                            helperText=''
                            value={state.address.addressLine1}
                            handleChange={handleAddressChange}
                            inputProps={{ className: classes.input, maxLength: 50 }}
                          // validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.addressLine2}
                            name="addressLine2"
                            label={t("ADD002")}
                            helperText=''
                            value={state.address.addressLine2}
                            handleChange={handleAddressChange}
                            inputProps={{ className: classes.input, maxLength: 50 }}
                          // validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '' :
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" justifyContent="center">
                            <CustomTextfield
                              error={error.addressLine3}
                              name="addressLine3"
                              label={t("ADD017")}
                              helperText=''
                              value={state.address.addressLine3}
                              handleChange={handleAddressChange}
                              inputProps={{ className: classes.input, maxLength: 50 }}
                              validation='alphabets'
                            />
                          </Box>
                        </Grid>}

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.city}
                            name="city"
                            label={t("ADD005")}
                            helperText=''
                            value={state.address.city}
                            handleChange={handleAddressChange}
                            inputProps={{ className: classes.input, maxLength: 50 }}
                            // validation='alphabets'
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.state}
                            name="state"
                            label={t("ADD004")}
                            helperText=''
                            value={state.address.state}
                            handleChange={handleAddressChange}
                            inputProps={{ className: classes.input, maxLength: 50 }}
                          // validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                        <Autocomplete
                          style={{ width: '100%' }}
                          noOptionsText={'No Options found'}
                          name='country'
                          value={state.address.country}
                          options={CommonUtil.isEmptyArray(countries) ? [] : countries}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          onChange={(event, newValue) => {
                            if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                              setState({
                                ...state, address: { ...state.address, country: newValue }
                              });
                            } else {
                              setState({ ...state, address: { ...state.address, country: '' } });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} name='country' value={state.address.country} error={error.country} variant='outlined' fullWidth label={t('ADD003') + ' * '}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                  },
                                  '&:hover fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.areaCode}
                            name="areaCode"
                            label={t("DEALERZIPCODE")}
                            helperText=''
                            value={state.address.areaCode}
                            handleChange={handleAddressChange}
                            inputProps={{ className: classes.input, maxLength: 10 }}
                            validation='alpha-numeric'
                          />
                        </Box>
                      </Grid>

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '' :
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" justifyContent="center">
                            <CustomTextfield
                              required
                              error={error.latitude}
                              name="latitude"
                              label={t("ADD007")}
                              helperText=''
                              value={state.address.latitude}
                              handleChange={handleAddressChange}
                              inputProps={{ className: classes.input, maxLength: 20 }}
                              validation='numeric'
                            />
                          </Box>
                        </Grid>}

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '' :
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" justifyContent="center">
                            <CustomTextfield
                              error={error.longitude}
                              name="longitude"
                              label={t("ADD008")}
                              helperText=''
                              required
                              value={state.address.longitude}
                              handleChange={handleAddressChange}
                              inputProps={{ className: classes.input, maxLength: 20 }}
                              validation='numeric'
                            />
                          </Box>
                        </Grid>}

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '' :
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" justifyContent="center">
                            <CustomTextfield
                              error={error.radius}
                              name="radius"
                              label={t("ADD009")}
                              helperText=''
                              value={state.address.radius}
                              handleChange={handleAddressChange}
                              inputProps={{ className: classes.input, maxLength: 20 }}
                              validation='numeric'
                            />
                          </Box>
                        </Grid>}

                      <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                        <Autocomplete
                          style={{ width: '100%' }}
                          noOptionsText={'No Options found'}
                          name='timeZone'
                          value={state.address.timeZone}
                          options={CommonUtil.isEmptyArray(timeZones) ? [] : timeZones}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          onChange={(event, newValue) => {
                            if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                              setState({
                                ...state, address: { ...state.address, timeZone: newValue }
                              });
                            } else {
                              setState({ ...state, address: { ...state.address, timeZone: '' } });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} name='timeZone' value={state.address.timeZone} error={error.timeZone} variant='outlined' fullWidth label={t('ADD010')}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                  },
                                  '&:hover fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                        <Autocomplete
                          style={{ width: '100%' }}
                          noOptionsText={'No Options found'}
                          name='addressTypeId'
                          value={state.address.addressTypeId}
                          options={CommonUtil.isEmptyArray(addressTypes) ? [] : addressTypes}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          onChange={(event, newValue) => {
                            if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                              setState({
                                ...state, address: { ...state.address, addressTypeId: newValue }
                              });
                            } else {
                              setState({ ...state, address: { ...state.address, addressTypeId: '' } });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} name='addressTypeId' value={state.address.addressTypeId} error={error.addressTypeId} variant='outlined' fullWidth label={t('ATYPE001') + ' * '}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                  },
                                  '&:hover fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" className="ml-3 mt-2">
                          <FormGroup>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                                style={{ color: darkMode ? "#fff" : "rgba(0, 0, 0, 0.87)" }}
                              >
                                <Grid item>{t("ADD011")}</Grid>
                                <Grid item>{t("DAYLIGHTOFF")}</Grid>
                                <Grid item >
                                  <IOSSwitch
                                    checked={state.address.dayLightSaving}
                                    onChange={handleAddressChange1}
                                    name="dayLightSaving"
                                  />
                                </Grid>
                                <Grid item>{t("DAYLIGHTON")}</Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        </DialogContent>
        <DialogActions style={{
          justifyContent: 'center', backgroundColor: darkMode ? '#0F273B' : '#fff'
        }}>
          <SubmitButtons
            onClick={handleSubmit}
            variant="contained"
          >
            {t("COMMON035")}
          </SubmitButtons>
          <CancelButtons
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            {t("COMMON008")}
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
