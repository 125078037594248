import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (filter) => {
  if (filter.page === undefined) {
    filter.page = 1
  }
  if (filter.rowsPerPage === undefined) {
    filter.rowsPerPage = 10
  }

  let query = '?pageNumber=' + filter.page + '&pageSize=' + filter.rowsPerPage

  if (!CommonUtil.isEmptyString(filter.firstName)) {
    query = query + '&firstName=' + filter.firstName
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + '&lastName=' + filter.lastName
  }
  if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
    query = query + '&scheduledEndDate=' + filter.scheduledEndDate
  }
  if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
    query = query + '&scheduledStartDate=' + filter.scheduledStartDate
  }
  if (!CommonUtil.isEmptyString(filter.scheduledOn)) {
    query = query + '&scheduledOn=' + filter.scheduledOn
  }
  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + '&email=' + filter.email
  }
  if (!CommonUtil.isEmptyString(filter.phone)) {
    query = query + '&phone=' + filter.phone
  }
  if (!CommonUtil.isEmptyString(filter.visitorTypeId)) {
    query = query + '&visitorTypeId=' + filter.visitorTypeId
  }
  if (!CommonUtil.isEmptyString(filter.purposeTypeId)) {
    query = query + '&purposeTypeId=' + filter.purposeTypeId
  }
  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = query + '&siteId=' + filter.siteId
  }
  if (!CommonUtil.isEmptyString(filter.hostId)) {
    query = query + '&hostId=' + filter.hostId
  }
  if (!CommonUtil.isEmptyString(filter.blockStatus)) {
    query = query + '&blockStatus=' + filter.blockStatus
  }
  if (!CommonUtil.isEmptyString(filter.visitMode)) {
    query = query + '&visitMode=' + filter.visitMode
  }
  if (!CommonUtil.isEmpty(filter.status)) {
    query = query + '&status=' + filter.status
  }
  if (!CommonUtil.isEmptyString(filter.isRestricted)) {
    query = query + '&isRestricted=' + filter.isRestricted
  }

  return query
}

const create = (visitorInvitationDTO) => {
  return api.securedAxios().post('/web/visit/create', visitorInvitationDTO)
}

const createMultipleInvitation = (listofVisitorsDTO) => {
  return api.securedAxios().post('/web/multipleVisits', listofVisitorsDTO)
}

const get = (filter) => {
  return api.securedAxios().get('/web/visit/list' + buildQuery(filter))
}

const cancel = (payload) => {
  return api.securedAxios().put('/web/visit/cancel/' + payload.id)
}

const reschedule = (payload) => {
  return api.securedAxios().put('/web/visit/reschedule/' + payload.id, payload)
}

const resendInvitation = (id) => {
  return api.securedAxios().put('/web/visit/resendNotification/' + id)
}

const forceCheckout = (registrationId) => {
  return api
    .securedAxios()
    .put('/api/v2.0/visitor/forcecheckOut/' + registrationId)
}

const updatevisitor = (payload) => {
  return api
    .securedAxios()
    .put('/visitor_workflow/visitor/' + payload.id, payload)
}

const validateinvite = (payload) => {
  return api
    .securedAxios()
    .get('/visitor_workflow/visit_registration/' + payload)
}

const confirmVisit = (id) => {
  return api
    .securedAxios()
    .put('/visitor_workflow/visit_registration/' + id + '/confirm')
}

const rejectVisit = (code) => {
  return api.securedAxios().put('/web/visit/reject/' + code)
}

const submitCheckListanswers = (payload) => {
  return api
    .securedAxios()
    .post('/visitor_workflow/check_list/' + payload.id, payload.checkList)
}

const rejectApprovalVisit = (payload) => {
  return api.securedAxios().put('/web/visit/visitReject', payload)
}

const acceptApprovalVisit = (payload) => {
  return api.securedAxios().put('/web/visit/visitApprove', payload)
}

const QRCodeForVisitors = (code) => {
  return api.unsecuredAxios().get('/web/visit/qrCode/' + code)
}

const LinkQRCode = () => {
  return api.securedAxios().get('/web/qrCodeLink')
}

const visitorUpdate = (payload) => {
  return api.unsecuredAxios().put('/web/visit/visitor/' + payload.id, payload)
}

const createSelf = (payload) => {
  return api.securedAxios().post('/web/visit/selfRegisrtaion', payload)
}

const getvisitorDetails = (payload) => {
  return api
    .securedAxios()
    .get(
      '/api/v2.0/employee/visitorDetails/' +
        payload.visitorId +
        '/' +
        payload.documentId,
    )
}

const getDlImages = (payload) => {
  return api.securedAxios().post('/web/image/download', payload)
}

const getVisitorDetailsById = (payload) => {
  return api.securedAxios().get('/web/visit/' + payload.id)
}

const InvitationsService = {
  create,
  get,
  reschedule,
  resendInvitation,
  forceCheckout,
  cancel,
  updatevisitor,
  confirmVisit,
  submitCheckListanswers,
  validateinvite,
  rejectVisit,
  rejectApprovalVisit,
  acceptApprovalVisit,
  QRCodeForVisitors,
  LinkQRCode,
  visitorUpdate,
  createSelf,
  createMultipleInvitation,
  getvisitorDetails,
  getDlImages,
  getVisitorDetailsById,
}

export default InvitationsService
