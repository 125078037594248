import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import MuiTheme from './theme'
import { LeftSidebar } from './layout-blueprints'
import {
  REACT_URL_DASHBOARD,
  REACT_URL_APPROVAL,
  REACT_URL_VISITORLOGS,
  REACT_URL_USERPROFILE,
  REACT_URL_COMPANYRPROFILE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_ADDRESSTYPE,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_CHECKLIST_ITEM,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_VISITORACCESSLEVEL,
  REACT_URL_ADDDEVICES,
  REACT_URL_FLOWCHART,
  REACT_URL_ADDCHECKOUT,
  REACT_VISITOR_QR_PAGE,
  REACT_DEVICE_LIST,
  REACT_DEVICE_INFO,
  REACT_URL_DEVICELIST,
  REACT_DEVICE_ADD_INSTRUCTION,
  REACT_ADD_DEVICE,
  REACT_ACCESSLEVELS_LIST,
  REACT_TIME_SLOTS,
  REACT_VIEW_DEVICE,
  REACT_MANAGE_ACCESSLEVELS,
  REACT_ADD_TIMESLOTS,
  REACT_ADD_DEVICE_TO_ACCESSLEVEL,
  REACT_UPDATE_TIMESLOT,
  REACT_URL_ZONE,
  REACT_URL_WALKINREGISTRATION,
  REACT_URL_REPORTINVITATION,
  REACT_URL_REPORTREGISTRATION,
  REACT_URL_CARDTEMPLATE,
  REACT_URL_ADD_CARD_TEMPLATE,
  REACT_URL_ADDITIONAL_SETTINGS,
  REACT_URL_VISITORTYPE,
  REACT_URL_HEALTHQUESTIONARIES,
  REACT_URL_CHECKLISTTEMPLATE_USA,
  REACT_VISITOR_ACCESS_LEVEL_LIST,
  REACT_URL_BLACKLISTTYPE,
  REACT_URL_PURPOSETYPE_USA,
  REACT_URL_USERROLE,
  REACT_DEALER,
  REACT_SUBSCRIPTION_MANAGEMENT,
  REACT_CREATE_SUBSCRIPTION,
  REACT_TRANSACTION_DETAILS,
  REACT_CREATE_DEALER,
  REACT_URL_EMPLOYEE,
  REACT_URL_VISITINVITE,
  REACT_URL_WALKINVISIT,
  REACT_URL_DAILYREPORT,
  REACT_URL_INVITATIONREPORT,
  REACT_URL_WALKINREGISTRATIONREPORT,
  REACT_URL_SELFREGISTRATIONREPORT,
  REACT_URL_BLACKLISTVISITORS,
  REACT_URL_WATCHLISTGLOBAL,
  REACT_URL_USER_LIST,
  REACT_URL_OFFENCE,
  REACT_URL_DEALER_DETAILS,
  REACT_URL_CUSTOMER_TRANSACTION_DETAILS,
  REACT_URL_MONTHLY_INCOME,
  REACT_URL_CREATE_LEVEL,
  REACT_URL_LEVEL,
  REACT_SUBSCRIPTION_CARDS,
  REACT_DELIVERY_REPORT,
  REACT_SMS_TEMPLATE,
  REACT_SMS_EDIT,
  REACT_ATLAS_INTEGRATION,
  REACT_CREATE_CUSTOMER,
  REACT_CUSTOMERS_LIST,
  REACT_VIEW_CUSTOMERS_DETAILS,
  REACT_VIEW_DEALER_DETAILS,
  REACT_DEALER_REPORT,
  REACT_DEALER_CUSTOMER_REPORT,
  REACT_ADMIN_TRANSACTIONS,
  REACT_DEALER_TRANSACTION,
  REACT_CUSTOMER_REPORT,
  REACT_CUSTOMER_VIEW_REPORT,
  REACT_CUSTOMER_TRANSACTION_VIEW_PAGE,
  REACT_CUSTOMER_TRANSACTION_VIEW,
  REACT_CUSTOMER_REPORT_VIEW,
  REACT_DASHBOARD_PANEL,
  REACT_EDIT_SUBSCRIPTION,
  REACT_URL_USERS,
  REACT_CREATE_USER,
  REACT_URL_DISCOUNTS,
  REACT_CREATE_DISCOUNT,
  REACT_SELF_REGISTRATION_LIST,
  REACT_DATEFORMAT_ADMIN_DEALER,
  REACT_KEY_CONFIGURATION,
  REACT_URL_PAYMENT_METHOD,
  REACT_CREATE_KEY_CONFIGURATION,
  REACT_EMAIL_REPORT,
  REACT_VIEW_KEY_CONFIGURATION,
  REACT_PLAN_PURCHASED,
  REACT_DEVICE_TYPE,
  REACT_URL_EMERGENCYMESSAGE,
  REACT_CREATE_DEVICE_TYPE,
  REACT_LOGIN_AUTHENTICATE,
  REACT_UNAUTHORIZED,
  REACT_INTEGRATION,
} from './actions/EndPoints'

import Dashboard from './Pages/DashBoard/dashboard.js'
import Approvals from './Pages/Approvals/Approvals'
import VisitorLogs from './Pages/Logs/VisitorLogs'
import LoginFormV2 from './Pages/OnBoardV2/LoginFormV2'
import CustomCarousel from './Pages/OnBoardV2/CustomCarousel'
import CompanyProfile from './Pages/ProfilePages/CompanyProfile'
import UserProfile from './Pages/ProfilePages/UserProfile'
import SignUpScreenV2 from './Pages/OnBoardV2/SignUpScreenV2'
import SelectCompany from './Pages/OnBoardV2/SelectCompany'
import EmailSentScreen from './Pages/OnBoardV2/EmailSentScreen'
import ForgotPasswordScreen from './Pages/OnBoardV2/ForgotPasswordScreen'
import SuccessScreen from './Pages/OnBoardV2/SuccessScreen'
import ChangePassword from './Pages/OnBoardV2/ChangePassword'
import ThankYouScreen from './Pages/OnBoardV2/ThankYouScreen'
import PasswordSuccess from './Pages/OnBoardV2/PasswordSuccess'
import CreateCompany from './Pages/OnBoardV2/CreateCompany'
import PrivacyPolicyScreen from './Pages/OnBoardV2/PrivacyPolicyScreen'
import UserAgreement from './Pages/OnBoardV2/UserAgreement'
import LinkExpired from './Pages/OnBoardV2/LinkExpired'
import Site from './Pages/SiteManagement/Site/Site'
import SiteType from './Pages/SiteManagement/SiteType/SiteType'
import AddressType from './Pages/SiteManagement/AddressType/AddressType'
import CheckInCheckOutDevices from './Pages/SiteManagement/CheckInCheckOutDevices/CheckInCheckOutDevices'
import PurposeType from './Pages/PurposeType/PurposeType'
import WatchlistTemplate from './Pages/Watchlist/WatchlistTemplate'
import ChecklistTemplate from './Pages/Checklist/ChecklistTemplate'
import VisitorAccessLevelsPage from './Pages/PurposeType/VisitorAccessLevelsPage'
import ChecklistItems from './Pages/Checklist/ChecklistItems'
import RestrictType from './Pages/RestrictType/RestrictType'
import EmergencyMessage from './Pages/EmergencyMessage/EmergencyMessage.js'
import AddCheckInOutDevices from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckInOutDevices'
import VisitSuccess from './Pages/Invitations/VisitSuccess'
import ChecklistForm from './Pages/Invitations/ChecklistForm'
import SecuredRoute from './config/SecuredRoute'
import RejectVisit from './Pages/Invitations/RejectVisit'
import ResultPage from './Pages/Invitations/ResultPage'
import Zlink from './Pages/OnBoardV2/Zlink'
import VisitingFlowchart from './Pages/VisitingFlowchart/VisitingFlowchart'
import AddCheckOutDevice from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckOutDevice'
import SelfRegistration from './Pages/SelfRegistration/SelfRegistration'
import SelfReject from './Pages/SelfRegistration/SelfReject'
import SelfCancel from './Pages/SelfRegistration/SelfCancel'
import Loader from './components/Loader/Loader'
import QRcode from './Pages/QRCode/QRcode'
import AddDeviceInfo from './Pages/SiteManagement/CheckInCheckOutDevices/AddDeviceInfo'
import AddCheckOutDeviceInfo from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckOutDeviceInfo'
import ActivateAccount from './Pages/Person/ActivateAccount'
import Device from './Pages/DeviceManagementV2/Device'
import ZlinkAddDeviceInfo from './Pages/DeviceManagementV2/ZlinkAddDeviceInfo'
import AddDevicePage from './Pages/DeviceManagementV2/AddDevicePage'
import VisitingAccessLevels from './Pages/VisitingAccessLevels/VisitingAccessLevels'
import { TimeSlotList } from './Pages/TimeSlots/TimeSlotList'
import { ViewDevice } from './Pages/DeviceManagementV2/ViewDevice'
import ManageAccessLevels from './Pages/VisitingAccessLevels/ManageAccessLevel'
import AddTimeSlots from './Pages/TimeSlots/AddTimeSlots'
import AccessAddDevice from './Pages/VisitingAccessLevels/AddDeviceToAccessLevel'
import updateTime from './Pages/TimeSlots/UpdateTimeSlot'
import ZoneList from './Pages/ZoneManagment/ZoneList'
import ReportInvitation from './Pages/Invitations/ReportInvitation'
import ReportSelfRegistration from './Pages/Invitations/ReportSelfRegistration'
import ReportWalkinRegistartion from './Pages/Invitations/ReportWalkinRegistartion'
import CardTemplate from './Pages/CardTemplate/CardTemplate'
import AddCardTemplate from './Pages/CardTemplate/AddCardTemplate'
import AdditionalSettings from './Pages/AdditionalSettings/AdditionalSettings'
import HealthQuestionaries from './Pages/HealthQuestionaries/HealthQuestionaries'
import ChecklistTemplateUSA from './Pages/ChecklistTemplateUSA/ChecklistTemplateUSA'
import VisitorType from './Pages/VisitorType/VisitorType'
import VisitorAddAccessLevelDialog from './Pages/PurposeType/VisitorAddAccessLevelDialog'
import BlacklistType from './Pages/BlacklistType/BlacklistType'
import UserRole from './Pages/UserRole/UserRole'
import PurposeTypeUSA from './Pages/PurposeTypeUSA/PurposeTypeUSA'
import Dealer from './Pages/Dealer/Dealer'
import CreateDealer from './Pages/Dealer/CreateDealer'
import Employee from './Pages/Employee/Employee'
import ConfirmVisitV2 from './Pages/Invitations/ConfirmVisitV2'
import DailyReports from './Pages/DailyReports/DailyReports'
import InvitationReport from './Pages/DailyReports/InvitationReport'
import WalkInRegistrationReport from './Pages/DailyReports/WalkInRegistrationReport'
import InvitationsList from './Pages/Invitations/InvitationsList'
import RegistrationList from './Pages/Registrations/RegistrationList'
import BlacklistVisitors from './Pages/BlacklistVisitors/BlacklistVisitors'
import WatchlistTemplateList from './Pages/Watchlist/WatchlistTemplateList'
import UserList from './Pages/UserRole/UserList'
import Offence from './Pages/Offence/Offence'
import SelfRegisterReport from './Pages/DailyReports/SelfRegisterReport'
import DealerDetails from './Pages/Dealer/DealerDetails'
import MonthlyIncome from './Pages/Dealer/MonthlyIncome'
import CustomerTransactionDetails from './Pages/Dealer/CustomerTransactionDetails'
import CreateLevel from './Pages/Dealer/CreateLevel'
import Level from './Pages/Dealer/Level'
import SubscriptionCards from './Pages/Dealer/SubscriptionCards'
import DeliveryReport from './Pages/DeliveryReport/DeliveryReport'
import DymoPrint from './Pages/DymoPrint/DymoPrint'
import SelfRegistrationSuccess from './Pages/SelfRegistration/SelfRegistrationSuccess'
import SmsTemplate from './Pages/SmsTemplate/SmsTemplate'
import EditSmsTemplate from './Pages/SmsTemplate/EditSmsTemplate'
import AtlasIntegration from './Pages/AtlasIntegration/AtlasIntegration.js'
import CreateCustomer from './Pages/Customer/CreateCustomer.js'
import CustomersList from './Pages/Customer/CustomersList.js'
import ViewCustomer from './Pages/Customer/ViewCustomer.js'
import ViewDealerDetails from './Pages/Dealer/ViewDealerDetails'
import RejectVisitByAdmin from './Pages/Invitations/RejectVisitByAdmin.js'
import DealersReport from './Pages/Dealer/DealersReport.js'
import CustomersReportByDealerId from './Pages/Customer/CustomersReportByDealerId.js'
import AdminTransactions from './Pages/Dealer/AdminTransactions.js'
import DealerTransaction from './Pages/Dealer/DealerTransaction.js'
import CustomerReports from './Pages/Customer/CustomerReports.js'
import CustomerReportViewDetails from './Pages/Customer/CustomerReportViewDetails.js'
import TransactionReportForCustomer from './Pages/Customer/TransactionReportForCustomer.js'
import TransactionReportViewPage from './Pages/Customer/TransactionReportViewPage.js'
import SubscriptionManagement from './Pages/Dealer/SubscriptionManagement.js'
import CreateSubscription from './Pages/Dealer/CreateSubscription.js'
import CustomerTransactionView from './Pages/Customer/CustomerTransactionView.js'
import CustomersReportView from './Pages/Customer/CustomersReportView.js'
import EditSubscription from './Pages/Dealer/EditSubscription.js'
import DashboardPanel from './Pages/DashboardPanel/DashboardPanel.js'
import LoginFormForHost from './Pages/OnBoardV2/LoginFormForHost.js'
import User from './Pages/User/User.js'
import CreateUser from './Pages/User/CreateUser.js'
import Discount from './Pages/Discount/Discount.js'
import CreateDiscount from './Pages/Discount/CreateDiscount.js'
import SelfRegistrationList from './Pages/SelfRegistration/SelfRegistrationList.js'
import DateFormatForHqAndDealer from './Pages/SettingForHq&Dealer/DateFormatForHqAndDealer.js'
import KeyConfiguration from './Pages/KeyConfiguration/KeyConfiguration.js'
import PaymentMethod from './Pages/PaymentMethod/PaymentMethod.js'
import CreateKeyConfiguration from './Pages/KeyConfiguration/CreateKeyConfiguration.js'
import KeyConfigView from './Pages/KeyConfiguration/KeyConfigView.js'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js'
import { useEffect } from 'react'
import EmailReportCreate from './Pages/EmailReport/EmailReportCreate.js'
import PlanPurchased from './Pages/Dealer/PlanPurchased.js'
import DeviceTypeLists from './Pages/UploadAPKFile/DeviceTypeLists.js'
import CreateDeviceType from './Pages/UploadAPKFile/CreateDeviceType.js'
import LoginAuthentication from './Pages/LoginAuthentication/LoginAuthentication.js'
import UnAuthorizedAccess from './Pages/UnAuthorizedAccess/UnAuthorizedAccess.js'
import Integration from './Pages/Integrations/Integration.js'

const Routes = () => {
  const location = useLocation()

  useEffect(() => {
    window.removeEventListener('beforeunload', function () {
      window.onbeforeunload = null
    })
  }, [location])

  return (
    <ThemeProvider theme={MuiTheme}>
      <Loader />
      <Switch>
        <Redirect exact from="/" to="/vis/login" />
        <Route path="/vis/login" component={LoginFormV2} />
        <Route path="/vis/verify-company" component={SelectCompany} />
        <Route path="/vis/carousel" component={CustomCarousel} />
        <Route path="/vis/signup" component={SignUpScreenV2} />
        <Route path="/vis/email-sent" component={EmailSentScreen} />
        <Route path="/vis/link-expired" component={LinkExpired} />
        <Route path="/vis/forgot-password" component={ForgotPasswordScreen} />
        <Route path="/vis/success-screen" component={SuccessScreen} />
        <Route path="/vis/reset-password" component={ChangePassword} />
        <Route path="/vis/user-created" component={ThankYouScreen} />
        <Route path="/vis/password-success" component={PasswordSuccess} />
        <Route path="/vis/create-company" component={CreateCompany} />
        <Route path="/vis/privacy-policy" component={PrivacyPolicyScreen} />
        <Route path="/vis/user-agreement" component={UserAgreement} />
        <Route path="/vis/confirm-page" component={ConfirmVisitV2} />
        <Route
          path="/vis/selfRegistration-confirm-page"
          component={SelfRegistrationSuccess}
        />
        <Route path="/vis/check-list-form" component={ChecklistForm} />
        <Route path="/vis/visit-success" component={VisitSuccess} />
        <Route path="/vis/reject" component={RejectVisit} />
        <Route path="/vis/result-page" component={ResultPage} />
        <Route path="/vis/activate-account" component={ActivateAccount} />
        <Route path="/vis/zlink" component={Zlink} />
        <Route path="/vis/selfRegistartion" component={SelfRegistration} />
        <Route path="/vis/confirms-page/rejects" component={SelfReject} />
        <Route path="/vis/cancel-page/cancel" component={SelfCancel} />
        <Route path="/vis/rejectByAdmin" component={RejectVisitByAdmin} />
        <Route path="/vis/LoginPageForHost" component={LoginFormForHost} />
        <Route path={REACT_VISITOR_QR_PAGE} component={QRcode} />
        <Route
          path={REACT_LOGIN_AUTHENTICATE}
          component={LoginAuthentication}
        />

        <Route path={REACT_UNAUTHORIZED} component={UnAuthorizedAccess} />
        <LeftSidebar>
          <SecuredRoute
            path={REACT_URL_DASHBOARD}
            component={Dashboard}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_DASHBOARD_PANEL}
            component={DashboardPanel}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['PARTNER', 'NONE']}
          />
          <SecuredRoute
            path={REACT_URL_VISITINVITE}
            component={InvitationsList}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_WALKINVISIT}
            component={RegistrationList}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_APPROVAL}
            component={Approvals}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_VISITORLOGS}
            component={VisitorLogs}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_USERPROFILE}
            component={UserProfile}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER', 'PARTNER', 'NONE']}
          />

          <SecuredRoute
            path={REACT_URL_COMPANYRPROFILE}
            component={CompanyProfile}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER', 'PARTNER', 'NONE']}
          />
          <SecuredRoute
            path={REACT_URL_SITE}
            component={Site}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_SITETYPE}
            component={SiteType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_ADDRESSTYPE}
            component={AddressType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_CHECKINOUTDEVICES}
            component={CheckInCheckOutDevices}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_PURPOSETYPE}
            component={PurposeType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_RESTRICTTYPE}
            component={RestrictType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_WATCHLIST}
            component={WatchlistTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_WATCHLISTGLOBAL}
            component={WatchlistTemplateList}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_CHECKLIST}
            component={ChecklistTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_VISITORACCESSLEVEL}
            component={VisitorAccessLevelsPage}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_CHECKLIST_ITEM}
            component={ChecklistItems}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_DEVICELIST}
            component={Device}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_ADDDEVICES}
            component={AddCheckInOutDevices}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_ADDCHECKOUT}
            component={AddCheckOutDevice}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_EMERGENCYMESSAGE}
            component={EmergencyMessage}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_FLOWCHART}
            component={VisitingFlowchart}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_DEVICE_LIST}
            component={AddDeviceInfo}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_DEVICE_INFO}
            component={AddCheckOutDeviceInfo}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_DEVICE_ADD_INSTRUCTION}
            component={ZlinkAddDeviceInfo}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_ADD_DEVICE}
            component={AddDevicePage}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_ACCESSLEVELS_LIST}
            component={VisitingAccessLevels}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_TIME_SLOTS}
            component={TimeSlotList}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_VIEW_DEVICE}
            component={ViewDevice}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_MANAGE_ACCESSLEVELS}
            component={ManageAccessLevels}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_ADD_TIMESLOTS}
            component={AddTimeSlots}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_ADD_DEVICE_TO_ACCESSLEVEL}
            component={AccessAddDevice}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_UPDATE_TIMESLOT}
            component={updateTime}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_ZONE}
            component={ZoneList}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_WALKINREGISTRATION}
            component={ReportWalkinRegistartion}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_REPORTINVITATION}
            component={ReportInvitation}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_REPORTREGISTRATION}
            component={ReportSelfRegistration}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_CARDTEMPLATE}
            component={CardTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_ADD_CARD_TEMPLATE}
            component={AddCardTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_ADDITIONAL_SETTINGS}
            component={AdditionalSettings}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_VISITORTYPE}
            component={VisitorType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_HEALTHQUESTIONARIES}
            component={HealthQuestionaries}
            llowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_CHECKLISTTEMPLATE_USA}
            component={ChecklistTemplateUSA}
            llowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_VISITOR_ACCESS_LEVEL_LIST}
            component={VisitorAddAccessLevelDialog}
            llowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_BLACKLISTTYPE}
            component={BlacklistType}
            llowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_USERROLE}
            component={UserRole}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_PURPOSETYPE_USA}
            component={PurposeTypeUSA}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_EMPLOYEE}
            component={Employee}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_INVITATIONREPORT}
            component={InvitationReport}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_WALKINREGISTRATIONREPORT}
            component={WalkInRegistrationReport}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_SELFREGISTRATIONREPORT}
            component={SelfRegisterReport}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_DAILYREPORT}
            component={DailyReports}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_URL_BLACKLISTVISITORS}
            component={BlacklistVisitors}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_DEALER}
            component={Dealer}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_CREATE_DEALER}
            component={CreateDealer}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_SUBSCRIPTION_MANAGEMENT}
            component={SubscriptionManagement}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE', 'PARTNER']}
          />
          <SecuredRoute
            path={REACT_CREATE_SUBSCRIPTION}
            component={CreateSubscription}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_EDIT_SUBSCRIPTION}
            component={EditSubscription}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute path={REACT_URL_USER_LIST} component={UserList} />
          <SecuredRoute path={REACT_URL_OFFENCE} component={Offence} />
          <SecuredRoute
            path={REACT_URL_DEALER_DETAILS}
            component={DealerDetails}
          />
          <SecuredRoute
            path={REACT_URL_CUSTOMER_TRANSACTION_DETAILS}
            component={CustomerTransactionDetails}
          />
          <SecuredRoute
            path={REACT_URL_MONTHLY_INCOME}
            component={MonthlyIncome}
          />
          <SecuredRoute path={REACT_URL_CREATE_LEVEL} component={CreateLevel} />
          <SecuredRoute path={REACT_URL_LEVEL} component={Level} />
          <SecuredRoute
            path={REACT_SUBSCRIPTION_CARDS}
            component={SubscriptionCards}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER', 'PARTNER']}
          />
          <SecuredRoute
            path={REACT_DELIVERY_REPORT}
            component={DeliveryReport}
            allowedRoles={['Owner', 'Admin', 'Front Desk']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute path={'/vis/print'} component={DymoPrint} />

          <SecuredRoute
            path={REACT_SMS_TEMPLATE}
            component={SmsTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_SMS_EDIT}
            component={EditSmsTemplate}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_ATLAS_INTEGRATION}
            component={AtlasIntegration}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />

          <SecuredRoute
            path={REACT_CREATE_CUSTOMER}
            component={CreateCustomer}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />
          <SecuredRoute
            path={REACT_CUSTOMERS_LIST}
            component={CustomersList}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />
          <SecuredRoute
            path={REACT_VIEW_CUSTOMERS_DETAILS}
            component={ViewCustomer}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />
          <SecuredRoute
            path={REACT_VIEW_DEALER_DETAILS}
            component={ViewDealerDetails}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_DEALER_REPORT}
            component={DealersReport}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_DEALER_CUSTOMER_REPORT}
            component={CustomersReportByDealerId}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_ADMIN_TRANSACTIONS}
            component={AdminTransactions}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_DEALER_TRANSACTION}
            component={DealerTransaction}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />

          <SecuredRoute
            path={REACT_CUSTOMER_REPORT}
            component={CustomerReports}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />
          <SecuredRoute
            path={REACT_CUSTOMER_VIEW_REPORT}
            component={CustomerReportViewDetails}
          />
          <SecuredRoute
            path={REACT_TRANSACTION_DETAILS}
            component={TransactionReportForCustomer}
          />
          <SecuredRoute
            path={REACT_CUSTOMER_TRANSACTION_VIEW_PAGE}
            component={TransactionReportViewPage}
          />
          <SecuredRoute
            path={REACT_CUSTOMER_TRANSACTION_VIEW}
            component={CustomerTransactionView}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_CUSTOMER_REPORT_VIEW}
            component={CustomersReportView}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE', 'PARTNER']}
          />
          <SecuredRoute
            path={REACT_SELF_REGISTRATION_LIST}
            component={SelfRegistrationList}
            allowedRoles={['Owner', 'Admin', 'Front Desk', 'Employee']}
            allowedCompanyType={['CUSTOMER']}
          />
          <SecuredRoute
            path={REACT_URL_USERS}
            component={User}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE', 'PARTNER']}
          />
          <SecuredRoute
            path={REACT_CREATE_USER}
            component={CreateUser}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE', 'PARTNER']}
          />

          <SecuredRoute
            path={REACT_URL_DISCOUNTS}
            component={Discount}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute
            path={REACT_URL_PAYMENT_METHOD}
            component={PaymentMethod}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />

          <SecuredRoute
            path={REACT_CREATE_DISCOUNT}
            component={CreateDiscount}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute
            path={REACT_DATEFORMAT_ADMIN_DEALER}
            component={DateFormatForHqAndDealer}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE', 'PARTNER']}
          />

          <SecuredRoute
            path={REACT_KEY_CONFIGURATION}
            component={KeyConfiguration}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute
            path={REACT_CREATE_KEY_CONFIGURATION}
            component={CreateKeyConfiguration}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute
            path={REACT_VIEW_KEY_CONFIGURATION}
            component={KeyConfigView}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_EMAIL_REPORT}
            component={EmailReportCreate}
          />

          <SecuredRoute
            path={REACT_PLAN_PURCHASED}
            component={PlanPurchased}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['PARTNER']}
          />

          <SecuredRoute
            path={REACT_DEVICE_TYPE}
            component={DeviceTypeLists}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />

          <SecuredRoute
            path={REACT_CREATE_DEVICE_TYPE}
            component={CreateDeviceType}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['NONE']}
          />
          <SecuredRoute
            path={REACT_INTEGRATION}
            component={Integration}
            allowedRoles={['Owner', 'Admin']}
            allowedCompanyType={['CUSTOMER']}
          />
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  )
}

export default Routes
