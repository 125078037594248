import { Avatar, Grid } from '@material-ui/core'
import { Box, CircularProgress, Link, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CommonUtil from '../../Util/CommonUtils'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import InvitationFilterFromReport from '../../components/InvitationFilterFromReport'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import { exportSelfRegistrationVisitors } from '../../services/Invitations/ExportService'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getAllVisitors } from '../../slices/Approvals/selfRegistarationApprovalSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import BlacklistVisitorForm from '../BlacklistVisitors/BlacklistVisitorForm'
import BlockVisitorIcon from '../Invitations/BlockVisitorIcon'
import FilterExportDialog from './FilterExportDialog'
import SelfRegisterReportView from './SelfRegisterReportView'
import TransactionHistory from './TransactionHistory'
import { ThemeContext } from '../../theme/ThemeContext';
import BlockVisitorIcon1 from '../../../src/assets/USA_Images/login_images_visitor/BlockVisitorIcon1.svg'

function SelfRegisterReport(props) {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [clear, setClear] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const nowDate = new Date()
  const UsFormatter = new Intl.DateTimeFormat('en-US')
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false)
  const [blacklistId, setBlacklistId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true)

  const status = [
    'CONFIRMED',
    'APPROVED',
    'REJECTED',
    'CANCELLED',
    'MISSED',
    'CHECKED_IN',
    'CHECKED_OUT',
    'BLOCKED',
    'INITIATED_FOR_BLOCKED',
  ]

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  var currDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const [filter, setFilter] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    status: [
      'CONFIRMED',
      'APPROVED',
      'REJECTED',
      'CANCELLED',
      'MISSED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'BLOCKED',
      'INITIATED_FOR_BLOCKED',
      'COMPLETED',
      'LOGGED',
    ],
    purposeTypeId: '',
    visitorTypeId: '',
    siteId: '',
    fromDate: null,
    toDate: null,
    hostId: '',
    createdAt: '',
    visitMode: ['SELF_REGISTARTION'],
    isPagable: true,
  })

  const defaultFilter = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    status: [
      'CONFIRMED',
      'APPROVED',
      'REJECTED',
      'CANCELLED',
      'MISSED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'BLOCKED',
      'INITIATED_FOR_BLOCKED',
      'COMPLETED',
      'LOGGED',
    ],
    purposeTypeId: '',
    visitorTypeId: '',
    siteId: '',
    fromDate: null,
    toDate: null,
    hostId: '',
    createdAt: '',
    visitMode: ['SELF_REGISTARTION'],
    isPagable: true,
  }

  const applyFilter = (filterData) => {
    setLoading(true)
    setClear(true)
    var today = new Date(filterData.createdAt)
    var startDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      'T' +
      today.getHours() +
      ':' +
      today.getMinutes()
    today = new Date(filterData.scheduledEndDate)
    var endDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      'T' +
      today.getHours() +
      ':' +
      today.getMinutes()

    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      visitorTypeId: filterData.visitorTypeId,
      purposeTypeId: filterData.purposeTypeId,
      siteId: filterData.siteId,
      status: filterData.status,
      hostId: filterData.hostId,
      createdAt: CommonUtil.formatToUtc(filterData.createdAt),
      scheduledStartDate: CommonUtil.formatToUtc(filterData.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(filterData.scheduledEndDate),
      visitMode: [filterData.visitMode],
    }

    setFilter({
      ...filter,
      ...query,
    })
  }

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setFilter({
      ...defaultFilter,
    })
  }
  const handleChange = (newPage, size) => {
    setLoading(true)
    setRowsPerPage(size)
    setPage(newPage)
    loadData(newPage, size)
  }

  useEffect(() => {
    loadData(1, rowsPerPage)
    loadSetting()
  }, [filter])

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    }
    dispatch(getAllVisitors(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setLoading(false)
          setPageSize(data.data.pageSize)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage)
      setDel(false)
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN'
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT'
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? searchedVal === 'Blacklist Initiated'
        : searchedVal === 'Block Initiated'
    ) {
      searchedVal = 'INITIATED_FOR_BLOCKED'
    }

    if (
      searchedVal === 'Blacklist' ||
      searchedVal.toLowerCase() === 'blacklist'
    ) {
      searchedVal = 'BLOCKED'
    }

    const filteredRows = rows.filter((test) => {
      if (test.purposeType !== null) {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.hostName
            ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.scheduledStartDate
            ? test.visitor.scheduledStartDate
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          (test.visitor.email
            ? test.visitor.email
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.phone
            ? test.visitor.phone
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.purposeType.name
            ? test.purposeType.name
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      } else {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.hostName
            ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.scheduledStartDate
            ? test.visitor.scheduledStartDate
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          (test.visitor.email
            ? test.visitor.email
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.phone
            ? test.visitor.phone
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      }
    })
    setRows(filteredRows)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor)
    setBlacklistId(params.row.id)
    setDiaLogBoxBlur(true)
  }

  const totalduration = (params) => {
    let start = moment(params.row.checkIn)
    let end = moment(params.row.checkOut)
    let duration = moment.duration(end.diff(start))
    let hours = Math.floor(duration.asHours())
    let minutes = duration.minutes()
    let formattedDuration =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0')
    return formattedDuration
  }

  const getStatus = (params) => {
    if (
      params.row.status === 'BLOCKED' ||
      params.row.status === 'INITIATED_FOR_BLOCKED'
    ) {
      return true
    }
  }

  const handleSelfRegistrationExport = (data) => {
    setClear(true)
    const query = {
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      visitMode: ['SELF_REGISTARTION'],
      status: [
        'CONFIRMED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'MISSED',
        'CHECKED_IN',
        'CHECKED_OUT',
        'BLOCKED',
        'INITIATED_FOR_BLOCKED',
        'COMPLETED',
      ],
    }
    setFilter({
      ...filter,
      ...query,
    })
    const stats = {
      page: 1,
      rowsPerPage: 1000,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: [
        'CONFIRMED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'MISSED',
        'CHECKED_IN',
        'CHECKED_OUT',
        'BLOCKED',
        'INITIATED_FOR_BLOCKED',
        'COMPLETED',
      ],
    }

    dispatch(
      exportSelfRegistrationVisitors(
        onSuccess,
        onFailure,
        stats,
        page,
        rowsPerPage,
      ),
    )
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          onSuccess()
        } else {
          onFailure()
        }
      })
      .catch((er) => { })
  }

  const onSuccess = () => {
    return Toast(t('EXPORTSUCC001'), 'success')
  }

  const onFailure = () => {
    Toast(t('COMMON000'), 'error')
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const [date, setDate] = useState()

  const loadSetting = () => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const renderCellCheckIn = (date) => (params) => {
    const row = params.row || {};
    const checkIn = row.checkIn || null;
    if (!checkIn) return <span className="table-cell-trucate"></span>;
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(checkIn)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const renderCellCheckOut = (date) => (params) => {
    const row = params.row || {};
    const checkOut = row.checkOut || null;
    if (!checkOut) return <span className="table-cell-trucate"></span>;
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(checkOut)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const ZoonHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '12px solid #FFFF',
            backgroundColor: 'transparent',
            borderRadius: '1px',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoonHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.lastName !== null
            ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
            : params.row.visitor.firstName
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                ' ' +
                params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'purpose',
      headerName: t('Purpose'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.visitor.purposeType !== null
          ? params.row.purposeType?.name
          : '',
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.purposeType !== null
            ? params.row.purposeType?.name
            : ''
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.purposeType !== null
                ? params.row.purposeType?.name
                : '',
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'scheduledStartDate',
      headerName: t('INVITEDATELBL'),
      flex: 1,
      width: 10,
      renderCell: renderCellScheduledStartDate(date)
    },
    {
      field: 'checkIn',
      headerName: t('Check In'),
      flex: 1,
      width: 10,
      renderCell: renderCellCheckIn(date)
    },

    {
      field: 'checkOut',
      headerName: t('Check Out'),
      flex: 1,
      width: 10,
      renderCell: renderCellCheckOut(date)
    },

    {
      field: 'duration',
      headerName: 'Total Duration (HH:MM)',
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.checkOut && params.row.checkIn ? totalduration(params) : '',
    },

    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />
      },
    },

    {
      field: 'Transaction History',
      headerName: 'Transaction History',
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<TransactionHistory rowData={params.row} date={date} />}
          label="Transaction History"
        />,
      ],
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SelfRegisterReportView rowData={params.row} />}
          label="View"
        />,
        <Tooltip title="BlackList Visitor">
          <div>
            <GridActionsCellItem
              icon={darkMode ? <img src={BlockVisitorIcon1} alt="Block Visitor Icon" /> : <BlockVisitorIcon />}
              label="BlockVisitor"
              onClick={() => handleBlacklistVisit(params)}
              disabled={getStatus(params)}
            />
          </div>
        </Tooltip>
        ,
      ],
    },
  ])

  return (
    <>
      {/* {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )} */}
      <HeaderToolbar
        title={t('SELFREGISTRATIONLBL')}
      />


      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterFromReport
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            applyFilter={(data) => applyFilter(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <FilterExportDialog
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleSelfRegistrationExport(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <Grid item xs={12}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>

      {openBlacklistVisitor && (
        <BlacklistVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data)
            loadData(page, rowsPerPage)
            setDiaLogBoxBlur(false)
          }}
          disable={() => setDel(true)}
          BlacklistVisitorId={blacklistId}
        />
      )}
    </>
  )
}

export default SelfRegisterReport
