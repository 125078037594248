import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Avatar,
  createFilterOptions,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import i18next from 'i18next'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cameraIcon from '../../assets/cameraIcon.svg'
import CompanyBackgroundImg_blue from '../../assets/company_blue.svg'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import { WebcamCapture } from '../../components/CustomInputs/WebCam'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import VisitorAssetsDialog from '../../components/DialogBoxComponent/VisitorAssetsDialog'
import Loader from '../../components/Loader/Loader'
import ProfileContext from '../../components/ProfileContext'
import Toast from '../../components/ToastContainer/CustomToast'
import httpCommon from '../../config/http-common'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getCompanyById } from '../../slices/Company/CompanySlice'
import { getAllSites } from '../../slices/Site/SiteSlice'
import { getUserByClientCredentials } from '../../slices/User/UserSlice1'
import {
  deteleAssetImage,
  uploadVisitorAsset,
} from '../../slices/VisitorDocuments/VisitorDocumentSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import SelfRegistrationCustomPhone1 from '../OnBoardV2/SelfRegistrationCustomPhone'
import SelfRegistrCustomPhone from '../OnBoardV2/SelfRegistrCustomPhone'
import CustomTextfield1 from '../../components/CustomInputs/CustomTextfield1'

isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const styles = {
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}

function SelfRegistration({ classes }) {
  localStorage.setItem('dark', false);
  const history = useHistory()
  const { t } = useTranslation()
  const [siteData, setSiteData] = useState([])
  const [comapanyId, setComapanyId] = useState('')
  const [userId, setUserId] = useState('')
  const [visitorData, setVisitorData] = useState([])
  const [purposeData, setPurposeData] = useState([])
  const [profile, setProfile] = React.useState()
  const [profileImage, setProfileImage] = React.useState()
  const [base64ImageData, setbase64ImageData] = useState('');
  const [phoneInput, setPhoneInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hostList, setHostList] = useState([])
  const [selectedHost, setSelectHost] = useState({})
  const [intervalTime, setIntervalTime] = useState('')
  const dispatch = useDispatch()
  const [purposeTypeMandatory, setPurposeTypeMandatory] = useState('')
  const [visitorTypeMandatory, setVisitorTypeMandatory] = useState('')
  const [siteTypeMandatory, setSiteTypeMandatory] = useState('')
  const [companyNameMandatory, setCompanyNameMandatory] = useState('')
  const [profilePhotoMandatory, setProfilePhotoMandatory] = useState('')
  const [purposeTypeToggle, setPurposeTypeToggle] = useState('')
  const [visitorTypeToggle, setVisitorTypeToggle] = useState('')
  const [siteTypeToggle, setSiteTypeToggle] = useState('')
  const [companyNameToggle, setCompanyNameToggle] = useState('')
  const [profilePhotoToggle, setProfilePhotoToggle] = useState('')
  const [dateFormat, setDateFormat] = useState('')
  const [country, setCountry] = useState('')
  const [defaultHostId, setDefaultHostId] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [companylogo, setCompanyLogo] = useState('')
  const [defaultDateFormat, setDefaultDateFormat] = useState('')
  const [autoContactInfo, setAutoContactIfo] = useState(false)
  const [userPhone, setUserPhone] = useState('')
  const [userEmail, setuserEmail] = useState('')
  const { setCurrentTotalVisitCount } = useContext(ProfileContext)
  const [assetsImages, setAssetsImages] = useState([])
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [objectKeys, setObjectKeys] = useState([])
  const [selfImagesCaptureLimit, setSelfImagescaptureLimit] = useState()
  const [webCam, setWebCam] = useState(false)
  const [selfImageCapture, setSelfImageCapture] = useState(false)
  const [assetuploadData, setAssetUploadData] = useState({})
  const [selfAligment] = useState('selfAligment')
  const [enableWebCam, setEnableWebCam] = useState(false)
  const [key, setKey] = useState(0);
  const [darkMode] = useState(ThemeContext)
  const [language, setLanguage] = useState(false)
  var today = new Date()

  useEffect(() => {
    setPayload({
      ...payload,
      scheduledEndDate: CommonUtil.formatToUtc(
        moment(startDate).add(intervalTime, 'minutes'),
      ),
    })
  }, [intervalTime])

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  const startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    startDate +
    endDate

  var dt = new Date()
  var addMinutes = new Date().setMinutes(dt.getMinutes() + 30)
  var endDate = CommonUtil.formatToUtc(addMinutes)

  var changeDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = useState({
    id: '',
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    phoneCountryCode: '',
    purposeTypeId: '',
    visitorTypeId: '',
    hostId: '',
    visitorCompanyName: '',
    siteId: '',
    visitMode: 'SELF_REGISTARTION',
    profilePhoto: '',
    verifyTypeIn: '',
    walkInScan: '',
    invitationScan: '',
    selfScan: '',
    code: '',
    remarks: '',
    userId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: '',
    scheduledchangeDate: CommonUtil.formatToUtc(changeDate),
  })

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    visitorTypeId: false,
    purposeTypeId: false,
    hostId: false,
    siteId: false,
    remarks: false,
    visitorCompanyName: false,
    scheduledStartDate: false,
    scheduledEndDate: false,
    profilePhoto: false,
    selectedHost: false,
  })

  const [filter, setFilter] = useState({
    firstName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    code: '',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  useEffect(() => {
    window.history.forward()
    var urlValue = window.location.href
    var url = new URL(urlValue)
    var code = url.searchParams.get('company')
    var lang = url.searchParams.get('lang')
    lang = lang !== null ? lang : 'en'
    const userSelectLanguage = lang
    if (userSelectLanguage === 'es') {
      setLanguage(true)
    }
    i18next.changeLanguage(lang)
    decodeAndSetCompanyDetails(code)
  }, [])

  const decodeAndSetCompanyDetails = (code) => {
    var companyDetails = atob(code)
    var details = companyDetails.split(':')
    setPayload({
      ...payload,
      companyName: details[1],
    })
    localStorage.setItem('companyId', details[0])
    localStorage.setItem('userId', details[2])
    setComapanyId(details[0])
    setUserId(details[2])
    loadData()
    loadCompany(details[0])

    const userPayload = {
      userId: details[2],
      companyId: details[0],
    }

    dispatch(getUserByClientCredentials(userPayload))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0033')) {
          setUserPhone(data.data.phone)
          setuserEmail(data.data.email)
        }
      })
      .catch((er) => { })
  }

  const loadData = () => {
    loadVisitorType()
    loadPurposeType()
    loadSites()
    loadSetting()
    loadEmployees()
  }

  const loadEmployees = (event) => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    }
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=1&pageSize=0',
        config,
      )
      .then((data) => {
        if (data.data.code === 'OMSI0000') {
          setHostList(data.data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  useEffect(() => {
    const defaultHost = hostList.find((host) => host.id === defaultHostId)
    if (defaultHost?.id) {
      setSelectHost(defaultHost)
      setPayload({
        ...payload,
        hostId: defaultHost.id,
      })
    }
  }, [defaultHostId, hostList])

  const loadCompany = (comapanyId) => {
    dispatch(getCompanyById(comapanyId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          setCompanyLogo(data.data.imagePreSignedURL)
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      dispatch(getListSettingsById(comapanyId))
        .unwrap()
        .then((data) => {
          setPayload({
            ...payload,
            verifyTypeIn: data.data.selfRegistartionVerifyMode,
            selfScan: data.data.selfRegistartionScanLimit,
            hostId: newValue.id,
            userId: userId,
          })
        })
      setPayload({
        ...payload,
        hostId: newValue.id,
      })
      setSelectHost(newValue)
    } else {
      setPayload({
        ...payload,
        hostId: '',
      })
      setSelectHost({})
    }
  }

  const scheduledEndDate1 = new Date(payload.scheduledEndDate)

  scheduledEndDate1.setMinutes(
    scheduledEndDate1.getMinutes() - parseInt(intervalTime, 10),
  )

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, lastName, email }) =>
      `${firstName} ${lastName} ${email}`,
    limit: 1,
  })

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  }

  const handleText = (event) => {
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=1&pageSize=0&firstName=' +
        event.target.value,
        config,
      )
      .then((data) => {
        if (data.data.code === 'OMSI0000') {
          // getSelectedItem(data.data.data.employees[0]);
          setHostList(data.data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  const loadVisitorType = () => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    }
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/getVisitorType?isPagable=true&pageNumber=1&pageSize=1000&status=ACTIVE',
        config,
      )
      .then((data) => {
        if (data.data.code === 'CVAI0000' && data.data.data) {
          setVisitorData(data.data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
        } else {
          setSiteData([])
        }
      })
  }

  const loadSetting = () => {
    const comapanyId = localStorage.getItem('companyId')
    dispatch(getListSettingsById(comapanyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDateFormat(data.data.dateFormat)
          setIntervalTime(data.data.selfIntervalTime)
          setPurposeTypeMandatory(data.data.checkSelfRegistrationPurposeType)
          setVisitorTypeMandatory(data.data.checkSelfRegistrationVisitorType)
          setSiteTypeMandatory(data.data.checkSelfRegistrationSiteType)
          setCompanyNameMandatory(data.data.checkSelfRegistrationCompanyName)
          setProfilePhotoMandatory(data.data.checkSelfRegistrationProfilePhoto)
          setPurposeTypeToggle(data.data.selfRegistrationPurposeType)
          setVisitorTypeToggle(data.data.selfRegistrationVisitorType)
          setSiteTypeToggle(data.data.selfRgistrationSiteType)
          setCompanyNameToggle(data.data.selfRegistrationCompanyName)
          setProfilePhotoToggle(data.data.selfRegistrationProfilePhoto)
          setSelfImageCapture(data.data.selfImageCapture)
          setSelfImagescaptureLimit(data.data.selfImagesCaptureLimit)
          setDefaultHostId(data.data.defaultHostId)
          setAutoContactIfo(data.data.selfAutoContactInfo)
          setPayload({
            ...payload,
            scheduledEndDate: CommonUtil.formatToUtc(
              moment(startDate).add(data.data.selfIntervalTime, 'minutes'),
            ),
          })
          if (data.data.dateFormat === 'dd-MM-yyyy') {
            setDefaultDateFormat('dd-MM-yyyy')
          }
          if (data.data.dateFormat === 'MM-dd-yyyy') {
            setDefaultDateFormat('MM-dd-yyyy')
          }
          if (data.data.dateFormat === 'yyyy-MM-dd') {
            setDefaultDateFormat('yyyy-MM-dd')
          }
        } else if (data.code === 'CVAE0065') {
          setIntervalTime(30)
        }
      })
  }

  const loadPurposeType = () => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    }
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/getPurposeType?isPagable=true&pageNumber=1&pageSize=1000&status=ACTIVE',
        config,
      )
      .then((data) => {
        if (data.data.code === 'CVAI0000' && data.data.data) {
          setPurposeData(data.data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    })
    setProfileImage(file)
  }

  const setImageFromCam = (image) => {
    setProfileImage(image)
    setbase64ImageData('data:image/jpeg;base64,' + image)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }
  const handleFirstNameChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setPayload({
      ...payload,
      [name]: value,
    })
  }
  const handleCompanyName = (event) => {
    const name = event.target.name
    const value = event.target.value
    setPayload({
      ...payload,
      [name]: value,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ''),
    })
    setError({ phoneNumber: false })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event,
    })
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.profilePhotoURL)
    bodyFormData.append('file', profileImage)
    httpCommon
      .unsecuredMultipartAxios()
      .post('/web/employee/profile', bodyFormData)
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) { })
  }

  const uploadAssetImages = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', assetuploadData.preSignedURL)
    bodyFormData.append('file', data)

    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) { })
      .catch(function (response) { })
  }
  const handleSubmit = () => {
    if (profilePhotoMandatory) {
      if (profileImage === '' || profileImage === undefined) {
        Toast(language ? t('Se requiere foto de perfil') : t('PROFILE_PHOTO'), 'error')
        return
      }
    }

    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(language ? t('Por favor, introduzca el nombre') : t('USERPROFSCREEN007'), 'error')
      setError({ firstName: true })
      return
    }
    if (!CommonUtil.isValidName(payload.firstName)) {
      Toast(language ? t('Por favor ingrese el nombre correcto') : t('FIRSTNAME'), 'error')
      setError({ firstName: true })
      return
    }

    if (CommonUtil.isEmptyString(payload.lastName)) {
      Toast(language ? t('Por favor ingrese el apellido') : t('USERPROFSCREEN008'), 'error')
      setError({ lastName: true })
      return
    }
    if (!CommonUtil.isValidNames(payload.lastName)) {
      Toast(language ? t('Por favor ingrese el apellido correcto') : t('LASTNAME'), 'error')
      setError({ lastName: true })
      return
    }

    if (!autoContactInfo) {
      if (
        CommonUtil.isEmptyString(payload.emailId) &&
        CommonUtil.isEmptyString(payload.phoneNumber)
      ) {
        Toast(language ? t('El correo electrónico o el número de móvil es obligatorio.') : t('ENTER_MOBILE_OR_EMAIL'), 'error')
        setError({ emailId: true, phoneNumber: true })
        return
      }
      if (!CommonUtil.isValidEmails(payload.emailId)) {
        setError({ ...error, emailId: true })
        Toast(language ? t('Correo electrónico no válido') : t('COMP0007'), 'error')
        return
      }
      if (!CommonUtil.isEmptyString(payload.phoneNumber)) {
        if (payload.phoneNumber.length < 8 || payload.phoneNumber.length > 15) {
          setError({ ...error, phoneNumber: true })
          Toast(language ? t('Por favor proporcione un número de teléfono válido entre 8 y 15') : t('COMPPROFSCREEN003'), 'error')
          return
        }
      }

      if (
        payload.phoneNumber !== null &&
        payload.phoneNumber !== undefined &&
        payload.phoneNumber !== ''
      ) {
        if (
          payload.phoneCountryCode + '-' + payload.phoneNumber === userPhone ||
          payload.phoneCountryCode + '-' + payload.phoneNumber ===
          selectedHost.phone
        ) {
          setError({ phoneNumber: false })
          Toast(language ? t('El número de teléfono del visitante no puede ser el mismo que el del anfitrión o el número de teléfono del usuario registrado') : t('INVITATIONFORM009'), 'error')
          return
        }
      }
    }
    if (CommonUtil.isEmpty(selectedHost)) {
      Toast(language ? t('El nombre del host es obligatorio') : t('INVITATIONFORM007'), 'error')
      setError({ selectedHost: true })
      return
    }

    if (
      payload.emailId.trim() === userEmail ||
      payload.emailId.trim() === selectedHost.email
    ) {
      setError({ emailId: true })
      Toast(language ? t('El correo electrónico del visitante no puede ser el mismo que el del anfitrión o el del usuario registrado') :
        t('INVITATIONFORM006'),
        'error',
      )
      return
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ''
    ) {
      setError({ scheduledStartDate: true })
      Toast(language ? t('La fecha de inicio programada es obligatoria') : t('INVITATIONFORM011'), 'error')
      return
    }
    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ''
    ) {
      setError({ scheduledEndDate: true })
      Toast(language ? t('¡La fecha de finalización programada es obligatoria!') : t('INVITATIONFORM012'), 'error')
      return
    }

    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(language ? t('La hora de finalización debe ser mayor que la hora de inicio') : t('INVITATIONFORM003'), 'error')
      return
    }

    if (
      new Date(payload.scheduledStartDate) <
      new Date(payload.scheduledchangeDate)
    ) {
      Toast(language ? t('La hora de inicio no puede ser inferior a la hora actual') : t('START_TIME_CANNOT_LESS'), 'error')
      return
    }

    if (
      companyNameMandatory &&
      CommonUtil.isEmptyString(payload.visitorCompanyName)
    ) {
      Toast(language ? t('Nombre de la empresa requerido') : t('Company Name Required'), 'error')
      setError({ visitorCompanyName: true })
      return
    }
    if (
      purposeTypeMandatory &&
      CommonUtil.isEmptyString(payload.purposeTypeId)
    ) {
      Toast(language ? t('Se requiere el propósito de la visita') : t('Purpose of Visit is Required'), 'error')
      setError({ purposeTypeId: true })
      return
    }
    if (
      visitorTypeMandatory &&
      CommonUtil.isEmptyString(payload.visitorTypeId)
    ) {
      Toast(language ? t('Tipo de visitante requerido') : t('Visitor Type Required'), 'error')
      setError({ visitorTypeId: true })
      return
    }
    if (siteTypeMandatory && CommonUtil.isEmptyString(payload.siteId)) {
      Toast(language ? t('El sitio es obligatorio') : t('Site is Mandatory'), 'error')
      setError({ siteId: true })
      return
    }

    setIsLoading(true)
    const dto = {
      id: payload.id,
      firstName: payload.firstName,
      lastName: payload.lastName,
      purposeTypeId: payload.purposeTypeId.id,
      visitorTypeId: payload.visitorTypeId.id,
      emailId: payload.emailId,
      phoneNumber: payload.phoneNumber,
      phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber)
        ? payload.phoneCountryCode
        : '',
      visitMode: 'SELF_REGISTARTION',
      profilePhoto: payload.firstName,
      visitorCompanyName: payload.visitorCompanyName,
      siteId: payload.siteId.id,
      hostId: payload.hostId,
      scheduledStartDate: payload.scheduledStartDate,
      verifyTypeIn: payload.verifyTypeIn,
      invitationScan: payload.invitationScan,
      walkInScan: payload.walkInScan,
      selfScan: payload.selfScan,
      scheduledEndDate: payload.scheduledEndDate,
      earlyCheckin: payload.earlyCheckin,
      remarks: payload.remarks,
      userId: payload.userId,
      objectKeys: objectKeys,
    }

    const config = {
      headers: { companyId: comapanyId },
    }

    httpCommon
      .unsecuredAxios()
      .post('/web/visit/selfRegisrtaion', dto, config)
      .then((data) => {
        if (data.data.code === 'OMSE0222') {
          Toast(language ? t('El teléfono ya existe.') : t('PHONE_NUMBER_EXISTS'), 'error')
        }
        if (data.data.code === 'OMSE0184') {
          Toast(language ? t('El correo electrónico ya existe.') : t('EMAIL_ID_ALREADY_EXIST'), 'error')
        }
        if (data.data.code === 'CVAE1025') {
          Toast(language ? t('Ya está prevista la reunión.') : t('MEETING_ALREADY_SCHEDULED'), 'error')
        }
        if (data.data.code === 'CVAE0310') {
          Toast(data.data.message, 'error')
          return
        }
        if (data.data.code === 'CVAE0066') {
          Toast(data.data.message, 'error')
          return
        }

        if (data.data.code === 'CVAI0067') {
          setCurrentTotalVisitCount(data.data.data.currentVisitCount)
          if (!CommonUtil.isEmptyString(data.data.data.profilePhotoURL)) {
            uploadProfilePhoto(data.data.data)
          }
          if (data.data.data.checkListItem?.questionaries?.length > 0) {
            const enabledlistItems = data.data.data.checkListItem?.questionaries.filter(
              (question) => {
                return question.disabled === false
              },
            )
            if (enabledlistItems.length > 0) {
              history.push({
                pathname: '/vis/selfRegistration-confirm-page',
                state: {
                  success: true,
                  listValue: enabledlistItems,
                  id: data.data.data.id,
                  language: language
                },
              })
            } else {
              handleClose()
            }
          } else {
            handleClose()
          }
        } else {
          Toast(data.data.message, 'error')
        }
        setIsLoading(false)
      })
  }
  const handleClose = (data) => {
    Toast(language ? t('Autorregistro creado exitosamente') : t('SELF_REGISTRATION_CREATE'), 'success')
    history.push({
      pathname: '/vis/visit-success',
      state: { success: true },
    })
  }

  const handleCancel = () => {
    if (objectKeys.length > 0) {
      handleDeleteAssets()
    }
    Toast(language ? t('Visita no creada') : t("VISIT_NOT_CREATED"), "error")
  }

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue.replace(/\s\s+/g, ' '))
  }

  const handleOptionSelected = (event, value) => {
    if (value) {
      getSelectedItem(value)
      setSelectHost(value)
    } else {
      getSelectedItem()
      setSelectHost()
    }
  }

  const handleAddAssets = () => {
    if (profilePhotoToggle && (profileImage === undefined || profileImage === null || profileImage === "")) {
      setEnableWebCam(false)
    } else if (base64ImageData) {
      setEnableWebCam(true)
    }
    // setEnableWebCam(false)
    if (objectKeys.length >= selfImagesCaptureLimit) {
      Toast(language ? t('Se superó el límite de captura de imagen') : t('IMAGE_LIMIT'), 'error')
      return
    }
    let payload = {
      fileName: 'VISITOR_ASSET' + assetsImages.length + 1,
      scope: 'Application',
      applicationName: 'MSGP',
      companyId: localStorage.getItem('companyId'),
    }

    dispatch(uploadVisitorAsset(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4042' && res.data) {
          setAssetUploadData(res.data)
          setWebCam(!webCam)
          setObjectKeys((prevData) => [...prevData, res.data.objectKey])
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleDeleteAsset = (index) => {
    let objectKey = objectKeys.filter((_, i) => i == index)
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKey,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
          Toast(language ? t('Activo eliminado correctamente') : t('REMOVE_ASSET_IMAGE'), 'success')
          let newImages = assetsImages.filter((_, i) => i !== index)
          setAssetsImages(newImages)
          let newObjectKeys = objectKeys.filter((_, i) => i !== index)
          setObjectKeys(newObjectKeys)
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleWithOutCaptureImage = () => {
    if (profilePhotoToggle && (profileImage === undefined || profileImage === null || profileImage === "")) {
      setEnableWebCam(false)
    } else {
      setEnableWebCam(true)
    }
    setKey(prevKey => prevKey + 1);
    const index = objectKeys.length - 1
    let objectKey = objectKeys.filter((_, i) => i == index)
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKey,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
          // Toast("Asset removed Successfully", 'success')
          let newImages = assetsImages.filter((_, i) => i !== index)
          setAssetsImages(newImages)
          let newObjectKeys = objectKeys.filter((_, i) => i !== index)
          setObjectKeys(newObjectKeys)
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleDeleteAssets = () => {
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKeys,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleEnableWebCam = () => {
    setEnableWebCam(!enableWebCam)
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          // justifyContent: "space-between",
          alignItems: 'center',
          backgroundColor: '#F4F6F8',
        }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ height: '80px' }}
            display="flex"
            justifyContent="flex-end"
          >
            <img src={USA_visLogo} alt="logo" height="50px" width="160px" />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
          >
            <Avatar
              src={companylogo ? companylogo : CompanyBackgroundImg_blue}
              sx={{ height: '120px', width: '120px', objectFit: 'fill' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ height: '20px' }}
          ></Grid>
        </Grid>
      </Grid>

      <Paper>
        <Typography
          gutterBottom
          variant="h2"
          style={{ padding: '20px', textAlign: 'center', color: '#3D4977' }}
        >
          {language ? t('Auto registro') : t('SELFREGISTRATIONLBL')}
        </Typography>
        <Grid container p={5} spacing={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={3.5}
            sm={12}
          >

            {(profilePhotoToggle && !enableWebCam) && (
              <Grid style={{ height: 200, width: 220, borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2' }}
                onClick={handleEnableWebCam}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <img src={cameraIcon} alt="Camera Icon" height={50} width={50} />
                </Grid>

              </Grid>
            )

            }

            {(enableWebCam) ? (
              <Grid>
                <WebcamCapture
                  key={key}
                  onsetProfile={(profile) => handleProfile(profile)}
                  profilePhoto={profileImage}
                  base64Image={(data) => setImageFromCam(data)}
                  webCam={() => {
                    setEnableWebCam(!enableWebCam)
                    setbase64ImageData("")
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container md={8} sm={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                name={'firstName'}
                label={language ? t('Nombre') : t('COMMON020')}
                value={payload.firstName}
                required={true}
                handleChange={handleFirstNameChange}
                error={error.firstName}
                validation="restrictLengthWithAlphaNumeric"
                autoComplete="off"
                inputProps={{ className: classes.input, maxLength: 100 }}
                helperText={error.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                name={'lastName'}
                label={language ? t('Apellido') : t('COMMON021')}
                value={payload.lastName}
                handleChange={handleFirstNameChange}
                error={error.lastName}
                validation="restrictLengthWithAlphaNumeric"
                autoComplete="off"
                required={true}
                inputProps={{ className: classes.input, maxLength: 100 }}
                helperText={error.lastName}
              />
            </Grid>
            {!autoContactInfo ? (
              <Grid item xs={12} sm={6}>
                <CustomTextfield1
                  label={language ? t('Correo electrónico') : t('COMMON042')}
                  name={'emailId'}
                  error={error.emailId}
                  value={payload.emailId}
                  validation="email"
                  handleChange={(e) => handleChange(e)}
                  autoComplete="off"
                  inputProps={{ className: classes.input, maxLength: 36 }}
                />
              </Grid>
            ) : (
              ''
            )}
            {!autoContactInfo ? (
              <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
                <SelfRegistrCustomPhone
                  value={phoneInput}
                  specialLabel={false}
                  companyId={comapanyId}
                  country={country}
                  handleChange={(value, data, event, formattedValue) =>
                    handlePhoneChange(value, data, event, formattedValue)
                  }
                  inputProps={{ className: classes.input }}
                />
              </Grid>
            ) : (
              ''
            )}
            {purposeTypeToggle ? (
              <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  options={
                    CommonUtil.isEmptyArray(purposeData) ? [] : purposeData
                  }
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      onselectPurposeType(newValue)
                    } else {
                      onselectPurposeType('')
                    }
                    setError({ purposeTypeId: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="purposeTypeId"
                      value={payload.purposeTypeId}
                      error={error.purposeTypeId}
                      variant="outlined"
                      fullWidth
                      label={
                        purposeTypeMandatory
                          ? (language ? t('Propósito de la visita *') : t('PURPOSELABEL1') + ' *')
                          : (language ? t('Propósito de la visita') : t('PURPOSELABEL1'))
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            {visitorTypeToggle ? (
              <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name="visitorTypeId"
                  value={payload.visitorTypeId}
                  options={
                    CommonUtil.isEmptyArray(visitorData) ? [] : visitorData
                  }
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      onselectVisitorType(newValue)
                    } else {
                      onselectVisitorType('')
                    }
                    setError({ visitorTypeId: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="visitorTypeId"
                      value={payload.visitorTypeId}
                      error={error.visitorTypeId}
                      variant="outlined"
                      fullWidth
                      label={
                        visitorTypeMandatory
                          ? (language ? t('Tipo de visitante *') : t('COMMON068') + ' *')
                          : (language ? t('Tipo de visitante') : t('COMMON068'))
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={selectedHost}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  handleOptionSelected(event, newValue)
                  setError({ selectedHost: false })
                }}
                inputValue={searchTerm}
                onInputChange={handleInputChange}
                id="autocomplete"
                options={hostList}
                // filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  option.lastName
                    ? option.firstName + ' ' + option.lastName
                    : option.firstName
                      ? option.firstName
                      : ''
                }
                renderInput={(params) => (
                  <CustomTextfield
                    {...params}
                    label={language ? t('Anfitrión (persona a conocer)') : t('HOST_SELF')}
                    placeholder={language ? t('Anfitrión (Persona con quien reunirse)') : t('commonsTextFeildHost')}
                    error={error.selectedHost}
                  />
                )}
              />
            </Grid>
            {companyNameToggle ? (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  label={
                    companyNameMandatory
                      ? (language ? t('TNombre de la empresa *') : t('VISUPDATE0006') + ' *')
                      : (language ? t('Nombre de la empresa') : t('VISUPDATE0006'))
                  }
                  name={'visitorCompanyName'}
                  value={payload.visitorCompanyName}
                  handleChange={handleCompanyName}
                  error={error.visitorCompanyName}
                  autoComplete="on"
                  inputProps={{ className: classes.input, maxLength: 100 }}
                  helperText={error.visitorCompanyName}
                  validation="restrictLengthWithAlphaNumeric"
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              {defaultDateFormat === 'dd-MM-yyyy' && (
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                      scheduledEndDate: CommonUtil.formatToUtc(
                        moment(scheduledStartDate).add(intervalTime, 'minutes'),
                      ),
                    })
                  }
                  disablePast
                  value={payload.scheduledStartDate}
                  label={language ? t('Fecha de inicio de la invitación *') : t('INVITESTARTDATEERROR1')}
                  date={'dd-MM-yyyy HH:mm'}
                />
              )}
              {defaultDateFormat === 'MM-dd-yyyy' && (
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                      scheduledEndDate: CommonUtil.formatToUtc(
                        moment(scheduledStartDate).add(intervalTime, 'minutes'),
                      ),
                    })
                  }
                  disablePast
                  value={payload.scheduledStartDate}
                  label={language ? t('Fecha de inicio de la invitación *') : t('INVITESTARTDATEERROR1')}
                  date={'MM-dd-yyyy HH:mm'}
                />
              )}
              {defaultDateFormat === 'yyyy-MM-dd' && (
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                      scheduledEndDate: CommonUtil.formatToUtc(
                        moment(scheduledStartDate).add(intervalTime, 'minutes'),
                      ),
                    })
                  }
                  disablePast
                  value={payload.scheduledStartDate}
                  label={language ? t('Fecha de inicio de la invitación *') : t('INVITESTARTDATEERROR1')}
                  date={'yyyy-MM-dd HH:mm'}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              {defaultDateFormat === 'dd-MM-yyyy' && (
                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  disablePast
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label={language ? t('Fecha de finalización de la invitación *') : t('INVITESTARTDATEERROR2')}
                  date={'dd-MM-yyyy HH:mm'}
                />
              )}
              {defaultDateFormat === 'MM-dd-yyyy' && (
                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  disablePast
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label={language ? t('Fecha de finalización de la invitación *') : t('INVITESTARTDATEERROR2')}
                  date={'MM-dd-yyyy HH:mm'}
                />
              )}
              {defaultDateFormat === 'yyyy-MM-dd' && (
                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  disablePast
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label={language ? t('Fecha de finalización de la invitación *') : t('INVITESTARTDATEERROR2')}
                  date={'yyyy-MM-dd HH:mm'}
                />
              )}
            </Grid>
            {siteTypeToggle ? (
              <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name="siteId"
                  value={payload.siteId}
                  options={CommonUtil.isEmptyArray(siteData) ? [] : siteData}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      setPayload({ ...payload, siteId: newValue })
                    } else {
                      setPayload({ ...payload, siteId: '' })
                    }
                    setError({ siteId: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="siteId"
                      value={payload.siteId}
                      error={error.siteId}
                      variant="outlined"
                      fullWidth
                      label={
                        siteTypeMandatory
                          ? (language ? t('Sitio *') : t('SITE013') + ' *')
                          : (language ? t('Sitio') : t('SITE013'))
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              sm={
                (Number(!purposeTypeToggle) +
                  Number(!visitorTypeToggle) +
                  Number(!companyNameToggle) +
                  Number(!siteTypeToggle)) %
                  2 ===
                  1
                  ? 12
                  : 6
              }
            >
              <CustomTextfield
                label={language ? t('Observaciones') : t('COMMON016')}
                name={'remarks'}
                value={payload.remarks}
                handleChange={handleCompanyName}
                error={error.remarks}
                autoComplete="on"
                inputProps={{ className: classes.input, maxLength: 100 }}
                helperText={
                  error.remarks ? 'Only accepts alphabetic characters' : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              spacing={3}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                columnGap: '15px',
              }}
            >
              {assetsImages.map((imageSrc, index) => (
                <div
                  key={index}
                  style={{ position: 'relative' }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    key={index}
                    src={imageSrc}
                    alt={`Image ${index + 1}`}
                    height="60"
                    width="60"
                  />

                  {hoveredIndex === index && (
                    <Tooltip title={language ? t('Eliminar activo') : t('REMOVE_ASSET')}>
                      <CloseIcon
                        key={index}
                        style={{
                          position: 'absolute',
                          right: '-10px',
                          top: '-10px',
                          backgroundColor: '#DDDDDD',
                          borderRadius: '50px',
                          height: '17px',
                          width: '17px',
                        }}
                        onClick={() => handleDeleteAsset(index)}
                      />
                    </Tooltip>
                  )}
                </div>
              ))}
              {selfImageCapture && assetsImages.length < 5 && (
                <Tooltip title={language ? t('Agregar activos') : t('ADD_ASSET')}>
                  <img
                    src={cameraIcon}
                    alt="Invalid Icon"
                    onClick={handleAddAssets}
                    height="30"
                    width="30"
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid
            container
            lg={12}
            md={12}
            xs={11}
            p={5}
            pt={0}
            spacing={3}
            justifyContent="flex-end"
          >
            <Grid item>
              <SubmitButtons
                onClick={handleSubmit}
                variant="contained"
                disabled={isLoading}
              >
                <Typography>{language ? t('Enviar') : t('COMMON007')}</Typography>
              </SubmitButtons>
            </Grid>
            <Grid item>
              <CancelButtons
                color="secondary"
                onClick={handleCancel}
                variant="contained"
              >
                <Typography>{language ? t('Cancelar') : t('COMMON008')}</Typography>
              </CancelButtons>
            </Grid>
          </Grid>
        )}
      </Paper>
      {webCam && (
        <VisitorAssetsDialog
          open={webCam}
          selfAligment={selfAligment}
          handleClose={() => {
            setProfileImage(base64ImageData)
            handleWithOutCaptureImage()
            setWebCam(!webCam)
          }}
          content={assetuploadData}
          handleSubmit={(blob, base64Data, content) => {
            setAssetsImages((prevImages) => [...prevImages, base64Data])
            uploadAssetImages(blob)
            setWebCam(!webCam)
            Toast(language ? t('Activo agregado exitosamente') : t('ASSET_SUCCESSFULLY'), 'success')
          }}
        />
      )}
    </>
  )
}
export default withStyles(styles)(SelfRegistration)


