import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { rejectApprovalVisit } from "../../slices/Invitations/InvitationsSlice";
import { ThemeContext } from '../../theme/ThemeContext';

export default function RejectRegistartionInvitationDialog(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = React.useState({
    visitorId: props.RejectRegisterId,
    approvalReason: "",
  });

  const [error, setError] = React.useState({
    approvalReason: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {

    dispatch(rejectApprovalVisit(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1029") {
          Toast(t(data.message), "success");
          props.disable();
          handleClose();
        } else {
          Toast(data.message, "error");
          handleClose();
        }
      })
      .catch((er) => { });
  };

  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t("APPROVE003")}
          </span>
        }
        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px !important",
            minWidth: "35vw",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", backgroundColor: darkMode ? '#0F273B' : '' }}
        >

          <Grid item xs={11} sm={10} mt={2.5}>
            <CustomTextfield
              type={"text"}
              label={t("COMMON016")}
              error={error.approvalReason}
              name="approvalReason"
              value={payload.approvalReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
