import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import React, { useEffect, useState } from 'react'
import vis_CompanyGreen from '../../assets/CompanyGreen.svg'
import vis_HomeWhite from '../../assets/Home-white.svg'
import vis_HomeGreen from '../../assets/HomeGreen.svg'
import vis_Language from '../../assets/LanguageGreen.svg'
import vis_LanguageWhite from '../../assets/LanguageWhite.svg'
import Person from '../../assets/USA_Images/dashboard_icons/profile.svg'
import HomeGreen from '../../assets/USA_Images/user_profile/Home-blue.svg'
import HomeWhite from '../../assets/USA_Images/user_profile/Home-orange.svg'
import Language from '../../assets/USA_Images/user_profile/Language-blue.svg'
import LanguageWhite from '../../assets/USA_Images/user_profile/Language-orange.svg'
import CompanyGreen from '../../assets/USA_Images/user_profile/company profile-blue.svg'
import vis_Person from '../../assets/images/onboard/person_vis.svg'
import HoverHelper from './HoverHelper'
import HoverWhatsNew from './HoverWhatsNew'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import jwt_decode from 'jwt-decode'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  REACT_DASHBOARD_PANEL,
  REACT_URL_COMPANYRPROFILE,
  REACT_URL_DASHBOARD,
  REACT_URL_USERPROFILE,
} from '../../actions/EndPoints'
import vis_CompanyWhite from '../../assets/Company-white.svg'
import CompanyBackgroundImg from '../../assets/CompanyBackgroundImg.svg'
import vis_PersonWhite from '../../assets/Person.svg'
import vis_ProfileGreen from '../../assets/ProfileGreen.svg'
import ProfileGreen from '../../assets/USA_Images/user_profile/Profile-blue.svg'
import PersonWhite from '../../assets/USA_Images/user_profile/Profile-orange.svg'
import blueIcon from '../../assets/USA_Images/user_profile/change_password_blue.svg'
import orangeIcon from '../../assets/USA_Images/user_profile/change_password_orange.svg'
import CompanyWhite from '../../assets/USA_Images/user_profile/company profile-orange.svg'
import vis_helper_hover from '../../assets/help (1).svg'
import vis_helper from '../../assets/help.svg'
import vis_subscription from '../../assets/subscription.svg'
import vis_subscription_hover from '../../assets/subscriptionHover.svg'
import vis_visitor_count from '../../assets/visitorCount.svg'
import vis_visitor_count_hover from '../../assets/visitorCountHover.svg'
import vis_whatsnew from '../../assets/whatsnew.svg'
import vis_whatsnew_hover from '../../assets/whatsnewhover.svg'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getCustomerSubscription } from '../../slices/Subscription/SubscriptionSlice'
import { getUserInfoPro } from '../../slices/User/UserSlice1'
import { revokeUser } from '../../slices/onboard/LoginSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import ChangePasswordDialog from '../DialogBoxComponent/ChangePasswordDialog'
import PlanDetailsDialog from '../DialogBoxComponent/PlanDetailsDialog'
import '../HeaderProfileMenu/HeaderProfileMenu.css'
import ProfileContext from '../ProfileContext'
import HoverImage from './HoverImage'
import SelectLanguageMenu from './SelectLanguageMenu'
import {
  getCompanyById,
  getCompanyInfo,
} from '../../slices/Company/CompanySlice'

function HeaderProfileMenu() {
  const dispatch = useDispatch()
  const history = useHistory()
  const store = useSelector((state) => state)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [picture, setPicture] = useState('')
  const [name, setName] = useState('')
  const zlink = localStorage.getItem('redirectFrom')
  const [lastName, setLastName] = useState('')
  const [planName, setPlanName] = useState('')
  const [changePasswordDialog, setChangePasswordDialog] = useState(false)
  const [viewPlan, setViewPlan] = useState(false)
  const [price, setPrice] = useState('')
  const [customerPlan, setCustomerPlan] = useState({})
  const [companyDetails, setCompanyDetails] = useState({})
  const [parentDetails, setParentDetails] = useState({})

  const {
    userProfilePic,
    setUserProfilePic,
    userProfile,
    currentTotalVisitCount,
    setCurrentTotalVisitCount,
  } = useContext(ProfileContext)
  const [totalVisitLimitCount, setTotalVisitLimitCount] = useState('')
  const [currentVisitCount, setCurrentVisitCount] = useState('')
  const { darkMode, setDarkMode } = useContext(ThemeContext)

  const handleThemeChange = () => {
    setDarkMode(!darkMode)
  }

  const [currentFilter, setCurrentFilter] = useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    loadData()
  }, [store.profile.profileUpdated, userProfile])

  useEffect(() => {
    if (decoded_jwtToken.companyType === 'CUSTOMER') {
      dispatch(getCustomerSubscription(decoded_jwtToken.companyId))
        .unwrap()
        .then((res) => {
          if (res.code === 'MSGS4032' && res.data) {
            setCustomerPlan(res.data)
            setCurrentVisitCount(res.data.currentVisitsCreated)
            setCurrentTotalVisitCount(res.data.currentVisitsCreated)
            setTotalVisitLimitCount(
              res.data.subscriptionPurchaseId.maxVisitsCreation,
            )
          }
        })
    }
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0026') {
          setCompanyDetails(data.data)
          if (data.data.parentId !== undefined || data.data.parentId === null) {
            dispatch(getCompanyById(data.data.parentId))
              .unwrap()
              .then((parentData) => {
                if (parentData.code === 'UASI0026') {
                  setParentDetails(parentData.data)
                }
              })
          }
        }
      })
      .catch((er) => {})
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode')
    } else {
      document.body.classList.remove('darkMode')
    }
    return () => {
      document.body.classList.remove('darkMode')
    }
  }, [darkMode])

  const loadData = () => {
    dispatch(getUserInfoPro(decoded_jwtToken.userId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0000' && data.data)) {
          setUserProfilePic(data.data.imagePreSignedURL)
          setName(data.data.firstName)
          setLastName(data.data.lastName)
        }
      })
      .catch((er) => {})
  }
  const label = { inputProps: { 'aria-label': 'Switch demo' } }
  const handleLogout = () => {
    // localStorage.removeItem('dark');
    history.push('/')
    dispatch(revokeUser())
    history.replace('/vis/login')
    localStorage.clear()
  }

  const handleChangePassword = () => {
    setChangePasswordDialog(!changePasswordDialog)
  }

  const handlePlanClick = () => {
    setViewPlan(!viewPlan)
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'revert', alignItems: 'end', textAlign: 'right' }}>
        <IconButton
          onClick={() => handleClick()}
          onMouseOver={handleClick}
          size="small"
          sx={{ ml: 2, mr: 5 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            src={
              userProfilePic !== ''
                ? userProfilePic
                : process.env.REACT_APP_ENVIRONMENT === 'USA'
                ? Person
                : vis_Person
            }
            sx={[
              userProfilePic === ''
                ? {
                    width: 40,
                    height: 40,
                    background: process.env.REACT_APP_BG_AVATAR,
                    padding: '10px',
                  }
                : '',
              open && {
                background: process.env.REACT_APP_BG_AVATAR,
                opacity: 0.8,
              },
              (theme) => ({
                '&:hover': {
                  background: process.env.REACT_APP_BG_AVATAR,
                  opacity: 0.8,
                },
              }),
            ]}
          ></Avatar>
          <div style={{ padding: '5px' }}>
            <Typography
              variant="h5"
              style={{ color: darkMode ? 'white' : '#2281AB' }}
            >
              {t('HI_TEXT')}{' '}
              {name.length <= 12 ? name : name.substring(0, 12) + '...'}
            </Typography>
          </div>
          <KeyboardArrowDownIcon
            style={{ paddingTop: '2.5px', color: process.env.REACT_APP_ICON }}
          />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        style={{
          border: '0.5px solid lightgray',
          borderRadius: '5px',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            width: '270px !important',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: darkMode ? '#0F273B' : '#fff',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          onMouseLeave={() => {
            setAnchorEl(null)
          }}
          style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Card
            sx={{
              bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
              backgroundImage: `url(${CompanyBackgroundImg})`,
              backgroundPosition: 'bottom right',
              backgroundRepeat: 'no-repeat',
              // opacity: 0.8,
              position: 'relative',
              bottom: 9,
            }}
          >
            <CardContent>
              <Grid container spacing={1.5} textAlign="left" p={1.25}>
                <Grid item xs={12}>
                  <div style={{ color: '#FFFFFF', display: 'flex' }}>
                    {name.length >= 0 ? (
                      <Tooltip title={name + '\n' + lastName}>
                        <Typography variant="h6" style={{ color: '#fff' }}>
                          {name.length <= 15
                            ? name
                            : name.substring(0, 17) + '...'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="h6" style={{ color: '#fff' }}>
                        {name.length <= 15
                          ? name
                          : name.substring(0, 17) + '...'}
                      </Typography>
                    )}
                    &nbsp;
                    {lastName.length >= 0 ? (
                      <Tooltip title={name + '\n' + lastName}>
                        <Typography variant="h6" style={{ color: '#fff' }}>
                          {lastName.length <= 15
                            ? lastName
                            : lastName.substring(0, 17) + '...'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="h6" style={{ color: '#fff' }}>
                        {lastName.length <= 15
                          ? lastName
                          : lastName.substring(0, 17) + '...'}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={10}>
                  {decoded_jwtToken.email.length >= 10 ? (
                    <Tooltip title={decoded_jwtToken.email}>
                      <Typography style={{ color: '#FFFFFF' }}>
                        {decoded_jwtToken.email.length <= 15
                          ? decoded_jwtToken.email
                          : decoded_jwtToken.email.substring(0, 17) + '...'}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography style={{ color: '#FFFFFF' }}>
                      {decoded_jwtToken.email.length <= 15
                        ? decoded_jwtToken.email
                        : decoded_jwtToken.email.substring(0, 17) + '...'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <>
            <Stack
              spacing={2}
              direction="column"
              PaperProps={{
                elevation: 10,

                sx: {
                  opacity: 3,
                  bgcolor: '#FFFFFF',
                  position: 'relative',
                },
              }}
            >
              {!zlink && (
                <HoverImage
                  normalImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? HomeGreen
                      : vis_HomeGreen
                  }
                  hoverImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? HomeWhite
                      : vis_HomeWhite
                  }
                  alt="Home"
                  item={
                    <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                      {t('USERPROFCARD001')}
                    </span>
                  }
                  link={
                    decoded_jwtToken.companyType === 'CUSTOMER'
                      ? REACT_URL_DASHBOARD
                      : REACT_DASHBOARD_PANEL
                  }
                />
              )}

              {!zlink && (
                <HoverImage
                  normalImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? ProfileGreen
                      : vis_ProfileGreen
                  }
                  hoverImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? PersonWhite
                      : vis_PersonWhite
                  }
                  alt="Profile"
                  item={
                    <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                      {t('DEALERTABPROFILE')}
                    </span>
                  }
                  link={REACT_URL_USERPROFILE}
                />
              )}
              <SelectLanguageMenu
                handleClose={() => setAnchorEl(null)}
                normalImage={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? Language
                    : vis_Language
                }
                hoverImage={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? LanguageWhite
                    : vis_LanguageWhite
                }
                alt="Language"
                item={
                  <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                    {t('LANGUAGES_TEXT')}
                  </span>
                }
              />
              {!zlink && (
                <HoverImage
                  normalImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? CompanyGreen
                      : vis_CompanyGreen
                  }
                  hoverImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? CompanyWhite
                      : vis_CompanyWhite
                  }
                  alt="Company"
                  item={
                    <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                      {t('COMPPROFSCREEN006')}
                    </span>
                  }
                  link={REACT_URL_COMPANYRPROFILE}
                />
              )}

              {!zlink && (
                <Box onClick={handleChangePassword}>
                  <HoverImage
                    normalImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? blueIcon
                        : ''
                    }
                    hoverImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? orangeIcon
                        : ''
                    }
                    alt="password"
                    item={
                      <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                        {t('RESETPASS002')}
                      </span>
                    }
                  />
                </Box>
              )}

              {decoded_jwtToken.companyType === 'CUSTOMER' && (
                <Box onClick={handlePlanClick}>
                  <HoverImage
                    normalImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? vis_subscription
                        : vis_subscription
                    }
                    hoverImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? vis_subscription_hover
                        : vis_subscription_hover
                    }
                    item={
                      <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                        {t('PLAN_TEXT') + planName}
                      </span>
                    }
                  />
                </Box>
              )}

              {!zlink && decoded_jwtToken.companyType === 'CUSTOMER' && (
                <HoverImage
                  normalImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? vis_visitor_count
                      : vis_visitor_count
                  }
                  hoverImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? vis_visitor_count_hover
                      : vis_visitor_count_hover
                  }
                  item={
                    <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                      {t('VISITOR_COUNT_TEXT') +
                        ' ' +
                        currentTotalVisitCount +
                        '/' +
                        totalVisitLimitCount}
                    </span>
                  }
                />
              )}

              {!zlink && (
                <Box onClick={() => window.open('../Whats_New.pdf', '_blank')}>
                  <HoverImage
                    normalImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? vis_whatsnew
                        : vis_whatsnew
                    }
                    hoverImage={
                      process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? vis_whatsnew_hover
                        : vis_whatsnew_hover
                    }
                    // alt="Profile"
                    // item={t('CUSTDATABLE34')}
                    item={
                      <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                        {t('CUSTDATABLE34')}
                      </span>
                    }
                  />
                </Box>
              )}

              {!zlink && (
                <HoverHelper
                  normalImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? vis_helper
                      : vis_helper
                  }
                  hoverImage={
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? vis_helper_hover
                      : vis_helper_hover
                  }
                  item={
                    <span style={{ color: darkMode ? '#fff' : '#242424' }}>
                      {t('HELP_TEXT')}
                    </span>
                  }
                />
              )}

              {!zlink && (
                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  sx={[
                    {
                      color: '#F2824C',
                      maxInlineSize: 'fit-content',
                      position: 'relative',
                      left: 95,
                      border: '1px solid #F2824C',
                    },
                    (theme) => ({
                      '&:hover': {
                        color: '#CCCCCC',
                        background: 'transparent',
                        border: '1px solid #00000029',
                      },
                    }),
                  ]}
                >
                  {t('USERPROFCARD003')}
                </Button>
              )}
            </Stack>
          </>
        </div>
      </Menu>
      {changePasswordDialog && (
        <ChangePasswordDialog
          open={changePasswordDialog}
          Header={t('RESETPASS002')}
          cancelText={t('COMMON008')}
          acceptText={t('COMMON007')}
          onClose={() => {
            setChangePasswordDialog(!changePasswordDialog)
          }}
        />
      )}

      {viewPlan && (
        <PlanDetailsDialog
          open={viewPlan}
          planDetails={customerPlan}
          parentDetails={parentDetails}
          Header={t('DEALERPLANDETAILS')}
          onClose={() => {
            setViewPlan(!viewPlan)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default HeaderProfileMenu
