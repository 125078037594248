import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../src/assets/FilterIcon.svg'
import CancelButtons from '../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../components/DialogBoxComponent/SubmitButtons'
import { getAllSites } from '../slices/Site/SiteSlice'
import { getAllVisitorType } from '../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../theme/ThemeContext'
import CommonUtil from '../Util/CommonUtils'
import AutocompleteComponent from './CustomInputs/AutocompleteComponent'
import CustomDateTimePicker from './CustomInputs/CustomDateTimePicker'
import Toast from './ToastContainer/CustomToast'

export default function InvitationFilterForm(props) {
  const [open, setOpen] = React.useState(false)
  const [siteData, setSiteData] = useState([])
  const [visitorData, setVisitorData] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [changeDate, setDateChange] = useState(true)
  const [dateOpen, setDateOpen] = useState('')
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }
  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const [payload, setPayload] = useState({
    purposeTypeId: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: props.selectedObject,
    status: props.status,
  })

  const handleClose = () => {
    setPayload({
      purposeTypeId: '',
      siteId: '',
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
      visitMode: props.selectedObject,
      status: props.status,
    })
    setOpen(false)
    setDateOpen(props.open)
    setDiaLogBoxBlur(false)
  }

  const [error, setError] = useState({
    purposeTypeId: false,
    siteId: false,
  })

  const reset = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (
      payload.purposeTypeId.trim() === '' &&
      payload.siteId.trim() === '' &&
      payload.scheduledStartDate === null &&
      payload.scheduledEndDate === null
    ) {
      Toast(t('COMMON015'), 'error')
      return
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ''
    ) {
      setError({ scheduledStartDate: true })
      Toast(t('INVITESTARTDATEERROR'), 'error')
      return
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ''
    ) {
      setError({ scheduledEndDate: true })
      Toast(t('INVITEENDDATEERROR'), 'error')
      return
    }

    if (
      new Date(payload.scheduledStartDate).toString() === 'Invalid Date' ||
      new Date(payload.scheduledEndDate).toString() === 'Invalid Date'
    ) {
      Toast(t('USERPROFSCREEN013'), 'error')
      return
    }

    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(t('INVITATIONFORM010'), 'error')
      return
    }

    props.applyFilter(payload)
    setPayload({
      purposeTypeId: '',
      siteId: '',
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
      registrationMode: props.selectedObject,
    })
    handleClose()
  }
  if (open) {
    setDiaLogBoxBlur(true)
  }
  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadVisitorType()
    loadSites()
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
          setDateChange(true)
        } else {
          setSiteData([])
        }
      })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event.id,
    })
  }

  const onselectSite = (event) => {
    setPayload({
      ...payload,
      siteId: event.id,
    })
  }
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Tooltip title={<Typography>{t('COMMON006')}</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <InvitationFilterForm />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog open={open}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(8px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
            },
          }}
        >
          <DialogTitle variant="h4" onClick={handleClose} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}

            >
              <CloseIcon />
            </IconButton>
            <Typography style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
              {t('COMMON006')}
            </Typography>
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }} >
            <Grid container justifyContent="center" alignItems={'center'}  >
              <Grid item xs={12} sm={9} style={{ marginTop: '10px' }}>

                <AutocompleteComponent
                  label={t('COMMON068')}
                  name="visitorTypeId"
                  error={error.visitorTypeId}
                  value={payload.visitorTypeId}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value
                  }
                  onSelect={(e) => onselectVisitorType(e)}
                  options={visitorData}
                />
              </Grid>

              <Grid item xs={12} sm={9} style={{ marginTop: '30px', }}>
                <AutocompleteComponent
                  label={t('SITE013')}
                  name="siteId"
                  error={error.siteId}
                  value={payload.siteId}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value
                  }
                  onSelect={(e) => onselectSite(e)}
                  options={siteData}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: '30px', height: '100%' }}
              >
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={t('INVITESTARTDATEERROR1')}
                  changeDate={changeDate}
                  date={props.date}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: '30px', height: '100%' }}
              >
                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label={t('INVITESTARTDATEERROR2')}
                  changeDate={changeDate}
                  date={props.date}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'center',
              backgroundColor: darkMode ? '#0F273B' : '#fff'
            }}
          >
            <SubmitButtons variant="contained" onClick={handleSubmit} >
              <Typography variant="body1" style={{ textTransform: 'none' }}>
                {t('COMMON024')}
              </Typography>
            </SubmitButtons>
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={reset}
            >
              <Typography variant="body1" style={{ textTransform: 'none' }}>
                {t('INVITATION003')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  )
}
