import { Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core'
import { Avatar, Box, Stack, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import jwt_decode from 'jwt-decode'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints'
import customer_image from '../../assets/admin_dashboard_image/customer_image.svg'
import dealer_image from '../../assets/admin_dashboard_image/dealer_image.svg'
import DataTable from '../../components/DataTable/DataTable'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  getAccountList,
  getAccountStatics,
  postChildSwitchCompany,
} from '../../slices/DashboardPanel/DashboardPanelSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import CustomersIcon from '../Customer/CustomersIcon'
import DealerIcon from '../Dealer/DealerIcon'
import ImpersonationIcon from './ImpersonationIcon'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: '18px',
    color: '#0F5A9C',
    fontWeight: 'bold',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return <div {...other}>{value === index && <Box pt={2}>{children}</Box>}</div>
}

function DashBoardData(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [accountStatics, setAccountStatics] = useState({
    totalDealerAccounts: 0,
    totalDealerActiveAccounts: 0,
    totalDealerInActiveAccounts: 0,
    totalCustomerAccounts: 0,
    totalCustomerActiveAccounts: 0,
    totalCustomerInActiveAccounts: 0,
  })
  const { darkMode } = useContext(ThemeContext);
  const [accountRows, setAccountRows] = useState([])
  const [date, setdate] = useState(new Date())
  const [accountState, setAccountState] = useState({
    type: 'PARTNER',
  })
  const [companyType, setCompanyType] = useState('')
  const [cascadeLimit, setCascadeLimit] = useState(1)
  const [count, setCount] = useState(4)
  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)
  const [accountStateDealer, setAccountStateDealer] = useState({
    type: 'CUSTOMER',
  })
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const repeatedItems = Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    name: 'ABC Infotech',
    description: 'Subscription Expiry',
  }))

  const handleChange = (event, newValue) => {
    setPaging({
      pageNumber: 1,
      pageSize: 10,
    })
    if (newValue === 0) {
      setAccountState({
        ...accountState,
        type: 'PARTNER',
      })
      setCascadeLimit(1)
    } else {
      setAccountState({
        ...accountState,
        type: 'CUSTOMER',
      })
      setCascadeLimit(2)
    }
    setValue(newValue)
  }

  useEffect(() => {
    const decoded = jwt_decode(localStorage.getItem('VISJwtToken'))
    setCompanyType(decoded.companyType)
  }, [companyType])

  useEffect(() => {
    loadAccountStatics()
    loadAccountList(cascadeLimit, paging, accountState)
  }, [])

  useEffect(() => {
    loadAccountList(cascadeLimit, paging, accountState)
  }, [value, paging])

  const loadAccountStatics = () => {
    dispatch(getAccountStatics(cascadeLimit))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4009' && data.data) {
          setLoading(false)
          setAccountStatics({
            totalDealerAccounts: data.data.totalDealerAccounts,
            totalDealerActiveAccounts: data.data.totalDealerActiveAccounts,
            totalDealerInActiveAccounts: data.data.totalDealerInActiveAccounts,
            totalCustomerAccounts: data.data.totalCustomerAccounts,
            totalCustomerActiveAccounts: data.data.totalCustomerActiveAccounts,
            totalCustomerInActiveAccounts:
              data.data.totalCustomerInActiveAccounts,
          })
        }
      })
  }

  const loadAccountList = () => {
    const decoded = jwt_decode(localStorage.getItem('VISJwtToken'))
    let payload
    if (decoded.companyType === 'PARTNER') {
      payload = {
        cascadeLimit: 2,
        accountDTO: accountStateDealer,
        page: paging,
      }
    } else {
      payload = {
        cascadeLimit: cascadeLimit,
        accountDTO: accountState,
        page: paging,
      }
    }

    dispatch(getAccountList(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4009' && data.data) {
          setAccountRows(data.data.companies)
          setTotalRecords(data.data.totalCount)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          if (data.data.pageSize === 10) {
            props.count(7)
          }
          if (data.data.pageSize === 5) {
            props.count(4)
          }
          if (data.data.pageSize === 20) {
            props.count(12)
          }
        }
      })
  }

  const handlePage = (newPage, size) => {
    setPage(newPage)
    setPaging({
      pageNumber: newPage,
      pageSize: size,
    })
  }
  const handleImpersonation = (params) => {
    const payload = {
      id: decoded_jwtToken.id,
      userId: params.row.userId,
      applicationId: localStorage.getItem("applicationId"),
      companyId: params.row.id,
    };
    dispatch(postChildSwitchCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0050') {
          localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
          localStorage.setItem(
            process.env.REACT_APP_ACCESS_TOKEN,
            'Bearer ' + data.data.access_token,
          );
          localStorage.setItem(
            process.env.REACT_APP_REFRESH_TOKEN,
            data.data.refresh_token,
          );
          const decoded = jwt_decode(data.data.access_token);
          localStorage.setItem('companyId', decoded.companyId);
          localStorage.setItem('companyName', decoded.companyName);
          localStorage.setItem('companyType', decoded.companyType);
          localStorage.setItem('companyCode', decoded.companyCode);
          if (decoded.companyType === 'CUSTOMER') {
            window.location.href = REACT_URL_DASHBOARD;
          }
        } else {
          Toast(data.message, 'error');
        }
      });
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 12) {
      return text.substring(0, 12) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.name}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.name}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        field: 'user.profile',
        headerName: '',
        sortable: false,
        width: 5,
        renderCell: (params) => ZoomHandaler(params),
      },
      {
        field: 'companyName',
        headerName: t('CUSTDATABLE01'),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => (
          <Tooltip title={`${params.value}`} placement="bottom-start">
            <span>{handleTextOverflow(params.value)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'email',
        headerName: t('CUSTDATABLE02'),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.email,
        renderCell: (params) => (
          <Tooltip title={`${params.row.email}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.email)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'mobile',
        headerName: t('CUSTDATABLE03'),
        type: 'singleSelect',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.phone,
      },
      {
        field: 'AdminName',
        headerName: t('CUSTDATABLE04'),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.adminName,
        renderCell: (params) => (
          <Tooltip title={`${params.value}`} placement="bottom-start">
            <span>{handleTextOverflow(params.value)}</span>
          </Tooltip>
        ),
      },
    ];

    if (value === 1) {
      baseColumns.push({
        field: 'Impersonation',
        headerName: t('Impersonation'),
        type: 'Impersonation',
        flex: 1,
        renderCell: (params) => (
          <GridActionsCellItem
            icon={<ImpersonationIcon />}
            label={t('Impersonation')}
            onClick={() => handleImpersonation(params)}
          />
        ),
      });
    }

    return baseColumns;
  }, [value]);
  return (
    <>
      <Grid container xs={9}>
        <Grid container spacing={2} style={{ marginLeft: darkMode ? '24px' : '', marginRight: darkMode ? '10px' : '' }}>
          {companyType === 'NONE' ? (
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={dealer_image}
                      height="50px"
                      width="50px"
                      style={{
                        backgroundColor: '#0F5A9C',
                        borderRadius: '5px',
                      }}
                    />
                    <Box>
                      <Typography>{t('DASHBOARDCARDSDEALERCOUNT')}</Typography>
                      <Typography className={classes.typography}>
                        {accountStatics.totalDealerAccounts}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            ''
          )}

          {companyType === 'NONE' ? (
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={dealer_image}
                      height="50px"
                      width="50px"
                      style={{
                        backgroundColor: '#36C96D',
                        borderRadius: '5px',
                      }}
                    />
                    <Box>
                      <Typography>{t('DASHBOARDCARDSACTIVEDEALER')}</Typography>
                      <Typography className={classes.typography}>
                        {accountStatics.totalDealerActiveAccounts}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            ''
          )}

          {companyType === 'NONE' ? (
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={dealer_image}
                      height="50px"
                      width="50px"
                      style={{
                        backgroundColor: '#F84F4F',
                        borderRadius: '5px',
                      }}
                    />
                    <Box>
                      <Typography>{t('DASHBOAEDCARDSINACTIVEDEALER')}</Typography>
                      <Typography className={classes.typography}>
                        {accountStatics.totalDealerInActiveAccounts}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            ''
          )}

          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <img
                    src={customer_image}
                    height="50px"
                    width="50px"
                    style={{
                      backgroundColor: '#2499FF',
                      borderRadius: '5px',
                    }}
                  />
                  <Box>
                    <Typography>{t('DASHBOARDCARDSCUSTOMERCOUNT')}</Typography>
                    <Typography className={classes.typography}>
                      {accountStatics.totalCustomerAccounts}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <img
                    src={customer_image}
                    height="50px"
                    width="50px"
                    style={{
                      backgroundColor: '#16BDBF',
                      borderRadius: '5px',
                    }}
                  />
                  <Box>
                    <Typography>{t('DASHBOARDCARDSACTIVECUSTOMER')}</Typography>
                    <Typography className={classes.typography}>
                      {accountStatics.totalCustomerActiveAccounts}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <img
                    src={customer_image}
                    height="50px"
                    width="50px"
                    style={{
                      backgroundColor: '#FE696C',
                      borderRadius: '5px',
                    }}
                  />
                  <Box>
                    <Typography>{t('DASHBOARDCARDSINACTIVECUSTOMER')}</Typography>
                    <Typography className={classes.typography}>
                      {accountStatics.totalCustomerInActiveAccounts}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Account Creation block */}
        <Typography variant="h3" style={{ marginTop: '20px', marginLeft: darkMode ? '32px' : '', color: darkMode ? '#fff' : '' }}>
          {t('DASHBOARDACCOUNTCREATION')}
        </Typography>

        <Grid
          container
          style={{
            backgroundColor: '#FFFFFF',
            padding: '10px',
            marginTop: '10px',
            marginLeft: darkMode ? '31px' : '',
            marginRight: darkMode ? '10px' : ''
          }}
        >
          <Grid container>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              style={{
                // position: 'absolute',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              {companyType === 'NONE' ? (
                <Tab
                  icon={
                    <DealerIcon fill={value === 0 ? '#F2824C' : '#242424'} />
                  }
                  iconPosition="start"
                  label={t('DASHBOARDTABDEALER')}
                  style={{
                    borderRight: 'solid #00000029',
                    width: '200px',
                    fontSize: '18px',
                    textTransform: 'none',
                    color: value === 0 ? '#F2824C' : '#242424',
                  }}
                />
              ) : (
                ''
              )}
              <Tab
                icon={
                  <CustomersIcon fill={value === 1 ? '#F2824C' : '#242424'} />
                }
                iconPosition="start"
                label={t('DASHBOARDTABCUSTOMER')}
                style={{
                  width: '200px',
                  fontSize: '18px',
                  textTransform: 'none',
                  color: value === 1 ? '#F2824C' : '#242424',
                }}
              />
            </Tabs>
          </Grid>

          {loading && (
            <Box sx={{ display: 'flex' }} className="loader-container">
              <CircularProgress className="loader" />
            </Box>
          )}
          <Grid
            item
            style={{ width: '100%', height: '-webkit-fill-available' }}
          >
            {value === 0 && (
              <TabPanel value={value} index={value}>
                <DataTable
                  columns={columns}
                  rows={accountRows}
                  page={page}
                  count={totalRecords}
                  rowId={(row) => row.id}
                  pageCount={totalPages}
                  pageSize={rowsPerPage}
                  handleChange={(newPage, size) => handlePage(newPage, size)}
                />
              </TabPanel>
            )}

            {value === 1 && (
              <TabPanel value={value} index={value}>
                <DataTable
                  columns={columns}
                  rows={accountRows}
                  page={page}
                  count={totalRecords}
                  rowId={(row) => row.id}
                  pageCount={totalPages}
                  pageSize={rowsPerPage}
                  handleChange={(newPage, size) => handlePage(newPage, size)}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DashBoardData
