import { DialogContent, Grid, Typography } from '@mui/material';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import DeleteDialogBox from '../../components/DialogBoxComponent/DeleteDialogBox';
import { deleteDoorsFromAccessLevel } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice';
import { ThemeContext } from '../../theme/ThemeContext';
import { ReactComponent as DeleteIcon } from '../../../src/assets/DeleteIcon.svg'

export const DeleteDoor = (props) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(props.open);
    const { darkMode } = useContext(ThemeContext);
    const dispatch=useDispatch();

    const handleSubmit = () => {
        const payload={
         accessGroupId:props.doorData.accGroupId,
         doorId:[props.doorData.doorId]
        }
       dispatch(deleteDoorsFromAccessLevel(payload)).unwrap().then((data) => {
         if (data.code === "DMSI0011") {
           toast.success(data.msg)
           props.reloadlist(true);
           handleClose();
         } else {
           toast.error(data.msg)
         }
       })
     }

    const handleClose = () => {
        props.onClose(!open);
    }

  return (
    <div>
         <DeleteDialogBox
                Header={
                  <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                   {t("COMMON004")}
                  </span>
                }
                acceptText={t('CONFIRM')}
                cancelText={t('COMMON008')}
                fullWidth={true}
                onClose={handleClose}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Grid >
                    <DeleteIcon width="60px" height={'50px'}/>
                    </Grid>
                    <Grid p={2} style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Typography gutterBottom>
                            {props.text + props.name}
                        </Typography> &nbsp;
                        <Typography style={{ fontWeight: "bold" }}>
                            {props.doorData.name} ?
                        </Typography>
                    </Grid>
                </DialogContent>
            </DeleteDialogBox>
    </div>
  )
}
