import { makeStyles } from '@material-ui/core';
import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonUtil from "../../../Util/CommonUtils";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import Toast from "../../../components/ToastContainer/CustomToast";
import {
  addSiteType,
  updateSiteType,
} from "../../../slices/Site/SiteTypeSlice";
import { ThemeContext } from '../../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

export default function AddSiteType(props) {
  const { open, handleClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [str, setStr] = useState("");
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    siteTypeIdOrCode: "",
    name: "",
    code: "",
    description: "",
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case "LMSE0009":
          setError({ code: true });
          Toast(data.message, "error");
          break;
        case "LMSE0010":
          setError({ name: true });
          Toast(data.message, "error");
          break;
        default:
          setError({ ...error });
      }
    Toast(data.data.error[0].message, "error");
  };

  const handleSubmit = () => {

    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t("RESTRICTERROR002"), "error");
      setError({ name: true });
      return false;
    }

    if (!CommonUtil.isValidName(payload.name)) {
      Toast(t("NAMEVALIDATE"), "error");
      setError({ name: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.code)) {
      process.env.REACT_APP_ENVIRONMENT === 'USA' ? Toast(t("KEY"), "error") : Toast(t("CODE"), "error")
      setError({ code: true });
      return false;
    }


    const newTemplate = {
      id: props.selectedObject.id,
      name: payload.name,
      code: payload.code,
      description: payload.description,
    };

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(addSiteType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI6004") {
            Toast(t("SITETYPE"), "success");
            handleClose();
          } else {
            Toast(data.message, "error");
            onError(data);
          }
        });
    } else {
      dispatch(updateSiteType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI6005") {
            Toast(t("SITETYPE1"), "success");
            handleClose();
          } else {
            Toast(data.message, "error");
            onError(data);
          }
        });
    }
  };

  useEffect(() => { }, []);

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        siteTypeIdOrCode: props.selectedObject.id,
        name: props.selectedObject.name,
        code: props.selectedObject.code,
        description: props.selectedObject.description,
      });
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {payload.siteTypeIdOrCode ? t("STYPE014") : t("STYPE002")}
          </span>
        }
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Grid item xs={12} sm={10.5} mt={1}>
            <CustomTextfield
              label={t("COMMON014")}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              inputProps={{ className: classes.input, maxLength: 30 }}
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={10.5} mt={1.5}>
            <CustomTextfield
              label={t("COMMON067")}
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
              inputProps={{ className: classes.input, maxLength: 29 }}
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={10.5} mt={1.5}>
            <CustomTextfield
              label={t("STYPE009")}
              error={error.description}
              name="description"
              value={payload.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ className: classes.input, maxLength: 120 }}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
