import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as FilterIcon } from "../../../assets/FilterIcon.svg";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import Toast from "../../../components/ToastContainer/CustomToast";
import { getAllSites } from "../../../slices/Site/SiteSlice";
import { ThemeContext } from '../../../theme/ThemeContext';

export default function SiteFilter(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [sites, setSites] = useState();
  const { t } = useTranslation();
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [filterQuery, setFilterQuery] = useState({
    name: "",
  });

  const payload = {
    page: 1,
    rowsPerPage: 10000,
    code: "",
    name: "",
  };

  const defaultFilter = {
    name: "",
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setFilterQuery({
      ...filterQuery,
      [name]: event.target.value,
    });
  };

  const onSelectChange = (event) => {
    setFilterQuery({
      ...filterQuery,
      parentId: event.id,
    });
  };

  const handleClose = () => {
    setFilterQuery(defaultFilter);
    setOpen(false);
    setDiaLogBoxBlur(false)
  };

  if (open) {
    setDiaLogBoxBlur(true)
  }

  const handleSubmit = () => {
    if (filterQuery.name.trim() === "") {
      Toast(t("COMMON015"), "error");
      return;
    }
    props.onSubmit(filterQuery);
    handleClose();
  };

  const handleOpen = () => {
    dispatch(getAllSites(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "LMSI0000") setSites(data.data.site);
      });

    setOpen(true);
  };

  const reset = () => {
    // if (filterQuery.name.trim() === "") {
    //   Toast(t("COMMON027"), "error");
    //   return;
    // }
    // setFilterQuery(defaultFilter);
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t("COMMON006")}
          </Typography>
        }
      >
        <FilterIcon onClick={handleOpen} style={{ cursor: "pointer" }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        // Header={t("COMMON006")}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t("COMMON006")}
          </span>
        }
        acceptText={t("COMMON024")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}

      >
        <Grid container justifyContent="center" alignItems={"center"} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid item xs={12} sm={9} style={{ marginTop: "30px" }}>
            <CustomTextfield
              label={t("COMMON044")}
              name="name"
              value={filterQuery.name}
              handleChange={(e) => handleChange(e)}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
