import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../../components/DataTable/DataTable'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import { Box, Link, styled, Switch, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'
import FilterChecklistTemplate from '../ChecklistTemplateUSA/FilterChecklistTemplate'
import AddVisitorPurposeTypeIcon from '../../assets/union.svg'
import {
  getPurposeType,
  updatePurposeType,
} from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import PurposeTypeForm from './PurposeTypeForm'
import PurposeTypeView from './PurposeTypeView'
import AccessLevelIconGlobal from '../../assets/USA_Images/AccessLevelIconGlobal'
import { REACT_URL_VISITORACCESSLEVEL } from '../../actions/EndPoints'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import { ThemeContext } from '../../theme/ThemeContext';

export default function PurposeTypeUSA(props) {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [openAccessLevelDialog, setOpenAccessLevelDialog] = useState(false)
  const [enable, setEnable] = useState(true)
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#0F5A9C !important',
      opacity: 1,
    },
  }))

  const [selectedObject, setSelectedObject] = useState({
    id: '',
    name: '',
    companyId: '',
    description: '',
    templateId: '',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    name: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    name: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpen(true)
    setDiaLogBoxBlur(true)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(getPurposeType(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
          setRows(data.data.purposeTypes)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
    })
  }

  const handlePurposeTypeStatusChange = (event, params) => {
    const updatePurposeTypeData = {
      id: params.id,
      name: params.name,
      description: params.description,
      templateId: params.templateId ? params.templateId.id : params.templateId,
      status: params.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      accessLevelIds: params.accessLevelIds ? params.accessLevelIds : [],
    }
    dispatch(updatePurposeType(updatePurposeTypeData))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0044') {
          loadData(filter, paging)
        }
      })
  }

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
    setDiaLogBoxBlur(true)
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        test.description.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.templateId !== null ? test.templateId.name : '')
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleAccessLevel = (params) => {
    setOpenAccessLevelDialog(!openAccessLevelDialog)
    setSelectedObject(params)
    if (!CommonUtil.isEmptyArray(params.accessLevelIds)) {
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      })
    } else {
      Toast(
        t('PURPOSELABELINFO') + params.name + t('PURPOSELABELINFO1'),
        'warning',
      )
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      })
    }
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },
    {
      field: 'name',
      headerName: t('PURPOSELABEL'),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: 'templateId',
      headerName: t('CHECKLIST001'),
      flex: 1,
      width: 110,
      valueGetter: (params) =>
        params.row.templateId !== null ? params.row.templateId.name : '',
    },

    {
      field: 'description',
      headerName: t('ATYPE009'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <PurposeTypeView title={t('PURPOSETYPE1')} rowData={params.row} />
          }
          label="View"
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('COMMON005')}
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<AccessLevelIconGlobal />}
          label={t('COMMON005')}
          onClick={() => handleAccessLevel(params.row)}
        />,

        <Tooltip
          title={params.row.status === 'ACTIVE' ? t('DISABLE') : t('ENABLE')}
        >
          <Android12Switch
            checked={params.row.status === 'ACTIVE'}
            onChange={(event) =>
              handlePurposeTypeStatusChange(event, params.row)
            }
          />
        </Tooltip>,
      ],
    },
  ])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <HeaderToolbar
        src={AddVisitorPurposeTypeIcon}
        onClick={handleOpen}
        title={t('PURPOSETYPE')}

        tooltipTitle={t('VISPURPOSE001')}
      />
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {open && (
        <PurposeTypeForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging))
            setDiaLogBoxBlur(false)
          }}
        />
      )}
      {openEditDialog && (
        <PurposeTypeForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            loadData(filter, paging)
            setDiaLogBoxBlur(false)
            setSelectedObject({
              id: '',
              name: '',
              description: '',
              templateId: '',
            })
          }}
        />
      )}
      ,
    </>
  )
}
