import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Language from '../../assets/images/onboard/Language.svg';
import { IconFlagUS, IconFlagES, IconFlagCN, IconFlagFR} from 'material-ui-flags';
import { useTranslation } from "react-i18next";
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { REACT_URL_DASHBOARD } from "../../actions/EndPoints";
import { useEffect } from "react";

export default function HeaderI18n() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
    } else {
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSelectedLang(event);
    }
    window.location.reload(false);
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng')
    setSelectedLang(currentLanguage)
}, [])

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <img src={Language} alt='Language' className='lang' />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox"
        }}
        PaperProps={{
          elevation: 0,
          paddingRight: 55,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0,
            mr: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              right: 29,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          value={'en'}
          key={'en'}
          onClick={() => {
            handleMenuItemClick('en');
          }}
          sx={[
            (theme) => ({
              '&:hover': {
                background: '#226BAB1A',
              },
              
            }),
            
          ]}
        >
          {' '}
          <IconFlagUS className='lang-flag' />
          &nbsp; {t('i18nLabelEnglishFlag')}
          &nbsp;
                    {selectedLang === 'en' && <CheckIcon style={{ color: 'green' }} />}
        </MenuItem>
        <MenuItem
          value={'es'}
          key={'es'}
          onClick={() => {
            handleMenuItemClick('es');
          }}
          sx={[
            (theme) => ({
              '&:hover': {
                background: '#226BAB1A',
              },
            }),
          ]}
        >
          {' '}
          <IconFlagES className='lang-flag' />
          &nbsp; {t('i18nLabelSpanishFlag')}&nbsp;
          {selectedLang === 'es' && <CheckIcon style={{ color: 'green' }} />}
        </MenuItem>
        <MenuItem
          value={'fr'}
          key={'fr'}
          onClick={() => {
            handleMenuItemClick('fr');
          }}
          sx={[
            (theme) => ({
              '&:hover': {
                background: '#226BAB1A',
              },
            }),
          ]}
        >
          {' '}
        </MenuItem>
      </Menu>
    </div>
  );
}
