import {
  Box,
  Grid,
  Link,
  styled,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import VisitorTypeIcon from '../../assets/key.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import { getAllVisitorType, updateVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'
import FilterChecklistTemplate from '../ChecklistTemplateUSA/FilterChecklistTemplate'
import AddVisitorType from './AddVisitorType'
import VisitorTypeView from './VisitorTypeView'

export default function VisitorType(props) {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#0F5A9C !important',
      opacity: 1,
    },
  }))

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    id: '',
    name: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    id: '',
    name: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpen(true)
    setDiaLogBoxBlur(true)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(getAllVisitorType(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
          setRows(data.data.visitorTypes)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
    })
  }

  const [selectedObject, setSelectedObject] = useState({
    id: '',
    companyId: '',
    name: '',
    description: '',
  })

  const handleVisitorTypeStatusChange = (event, params) => {
    const updateVisitorTypeData = {
      id: params.id,
      name: params.name,
      description: params.description,
      status: params.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    }
    dispatch(updateVisitorType(updateVisitorTypeData))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0039') {
          loadData(filter, paging)
        }
      })
  }

  const handleEditVisitPurposeType = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
    setDiaLogBoxBlur(true)
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.description
          ? test.description.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },
    {
      field: 'name',
      headerName: t('accessLevelName'),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: 'description',
      headerName: t('ATYPE009'),
      flex: 1,
      minWidth: 290,
      valueGetter: (params) => params.row.description,
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <VisitorTypeView title={t('VISITORTYPED')} rowData={params.row} />
          }
          label={t('VIEW')}
        />,
        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('dataTableEdit')}
          onClick={() => handleEditVisitPurposeType(params.row)}
        />,
        <Tooltip
          title={params.row.status === 'ACTIVE' ? t('DISABLE') : t('ENABLE')}
        >
          <Android12Switch
            checked={params.row.status === 'ACTIVE'}
            onChange={(event) =>
              handleVisitorTypeStatusChange(event, params.row)
            }
          />
        </Tooltip>,
      ],
    },
  ])

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: darkMode ? '17px' : ''
        }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}>
            <Typography
              variant="h3"
              style={{
                marginLeft: darkMode ? '33px' : '0px',
                marginRight: darkMode ? '18px' : '12px',
                color: darkMode ? '#fff' : '#3D4977',
                marginTop: darkMode ? '3px' : '4px',
              }}
            >
              {t('COMMON068')}
            </Typography>

          </Box>
        </Grid>
        <Box
          style={{
            textAlign: 'center',
            padding: '10px',
            backgroundColor: process.env.REACT_APP_BG_ICON,
            borderRadius: '50%',
            cursor: 'pointer',
            width: '45px',
            height: '45px',
          }}
        >
          <Tooltip title={t('ADD032')}>
            <img
              alt="..."
              src={VisitorTypeIcon}
              onClick={handleOpen}
              style={{ width: '20px' }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {open && (
        <AddVisitorType
          open={open}
          selectedObject={selectedObject}
          handleClose={() => {
            handleClose(loadData(filter, page))
            setDiaLogBoxBlur(false)
          }}
        />
      )}
      ,
      {openEditDialog && (
        <AddVisitorType
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            setDiaLogBoxBlur(false)
            setSelectedObject({
              id: '',
              companyId: '',
              name: '',
              description: '',
            })
            loadData(filter, paging)
          }}
        />
      )}
      ,
    </>
  )
}
