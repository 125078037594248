import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/USA_Images/login_images/App-logo.svg';
import HeaderLogo from '../../layout-components/HeaderLogo';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderProfileMenu from '../../components/HeaderProfileMenu/HeaderProfileMenu';
import SwitchCompany from '../../components/SwitchCompany';
import logo_smartgurd from '../../assets/USA_Images/login_images/AdminstrationDashBoard.svg';
import Logo_Dealer from '../../assets/USA_Images/dealer/Logo_Dealer.svg';
import jwt_decode from 'jwt-decode';
import { REACT_URL_DASHBOARD, REACT_LOGIN } from '../../actions/EndPoints';
import { ThemeContext } from '../../theme/ThemeContext'; 
import Switch from '@mui/material/Switch'; 
import { styled } from '@mui/material/styles';
import moonIcon from '../../../src/assets/USA_Images/login_images_visitor/moonIcon.svg'
import sunIcon from '../../../src/assets/USA_Images/login_images_visitor/sunIcon.svg'
import SunIconLight from '../../../src/assets/USA_Images/login_images_visitor/SunIconLight.svg'
import { Box as MuiBox } from '@mui/material';
const Icon = styled('img')({
  width: 24,
  height: 24,
});

const Header = (props) => {
  const [decodedJwtToken, setDecodedJwtToken] = useState(null);
  const [sidebarToggleMobile, setSidebarToggleMobile] = useState(props.sidebarToggleMobile);
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

    if (token) {
      try {
        const decoded = jwt_decode(token);
        setDecodedJwtToken(decoded);
      } catch (error) {
        window.location = REACT_LOGIN;
      }
    } else {
      window.location = REACT_LOGIN;
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === process.env.REACT_APP_ACCESS_TOKEN) {
        if (event.newValue) {
          try {
            jwt_decode(event.newValue);
          } catch (error) {
            window.location = REACT_LOGIN;
          }
        } else {
          window.location = REACT_LOGIN;
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
    props.setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const { headerShadow, headerFixed } = props;

  return (
    <Fragment>
      <AppBar
        color="secondary"
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: process.env.REACT_APP_BG_HEADER_LOGO,
        }}
        className={clsx('app-header')}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}
      >
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: darkMode ? '#0F273B' : process.env.REACT_APP_BG_HEADTOOLBAR,
            height: '69px'
          }}
        >
          {decodedJwtToken?.companyType === 'CUSTOMER' ? (
            <>
              <Hidden lgUp>
                <Link to={REACT_URL_DASHBOARD}>
                  <img className="app-logo-img" alt="Cloud Access" src={projectLogo} />
                </Link>
              </Hidden>
              <Hidden mdDown>
                <Box className="d-flex align-items-center"></Box>
              </Hidden>
            </>
          ) : (
            <Hidden>
              <img
                className="app-logo-img-dealer-customer"
                src={decodedJwtToken?.companyType === 'NONE' ? logo_smartgurd : Logo_Dealer}
                alt="company logo"
              />
            </Hidden>
          )}
          <Box className="d-flex align-items-center">
          {decodedJwtToken?.companyType === 'CUSTOMER' &&  ( 
            <MuiBox display="flex" alignItems="center">
              {darkMode ? (
                <Tooltip title="Light Mode">
                <IconButton onClick={handleThemeChange} >
                  <Icon src={sunIcon} alt="Sun Icon" />
                </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Dark Mode">
                <IconButton onClick={handleThemeChange} >
                  <Icon src={moonIcon} alt="Moon Icon" />
                </IconButton>
                </Tooltip>
              )}
            </MuiBox>)} 
            <SwitchCompany />
            <HeaderProfileMenu />
            <Box className="toggle-sidebar-btn-mobile">
              <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
                {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
