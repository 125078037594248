import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { getAllChecklisttemplate } from "../../slices/ChecklistTemplatesUSA/ChecklistTemplateSlice";
import { createPurposeType, updatePurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from "../../Util/CommonUtils";

export default function PurposeTypeForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [checklistTemplates, setChecklistTemplates] = useState([]);
  const [str, setStr] = useState("");
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    id: "",
    name: "",
    description: "",
    templateId: "",
     accessLevelIds: [],
    status : ""
  });

  const [error, setError] = React.useState({
    name: false,
    description: false,
    templateId: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    // if (name === "name" || name === "description") {
    //   value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    // }  
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(payload.name)) {
      Toast(t("PURPOSE"), "error");
      setError({ name: true });
      return;
    }

    if (CommonUtil.checkCharactersGreaterThan250(payload.description)) {
      Toast(t("PURPOSE1"), "error");
      setError({ description: true });
      return;
    }

    const newTemplate = {
      id: props.selectedObject.id,
      name: payload.name,
      description: payload.description,
      templateId: payload.templateId ? payload.templateId.id : payload.templateId,
      accessLevelIds: payload.accessLevelIds ? payload.accessLevelIds : [],
      status : payload.status
    };

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(createPurposeType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0043") {
            Toast(t("INVITATIONFORM018"), "success");
            handleClose();
          } else {
            Toast(data.message, "error");
          }
        });
    } else {
      dispatch(updatePurposeType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0044") {
            Toast(t("PURPOSE2"), "success");
            handleClose();
          } else {
            Toast(data.message, "error");
          }
        });
    }
  };

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        templateId: props.selectedObject.templateId,
        accessLevelIds: props.selectedObject.accessLevelIds,
        status : props.selectedObject.status
      });
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadChecklistTemplates();
  };

  const loadChecklistTemplates = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };
    dispatch(getAllChecklisttemplate(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setChecklistTemplates(data.data.visitors);
        } else {
          setChecklistTemplates([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeDropdown = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const onselectChecklistTemplates = (event) => {
    setPayload({
      ...payload,
      templateId: event.id,
    });
  };

  return (
    <>
      <DialogBox
        // Header={payload.id ? t("VISPURPOSE002") : t("VISPURPOSE001")}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {payload.id ? t("VISPURPOSE002") : t("VISPURPOSE001")}
          </span>
        }
        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Grid item xs={12}>
            <CustomTextfield
              type={"text"}
              label={t('PURPOSELABEL')}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              required={true}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={12} mt={3}>
            <Autocomplete
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='templateId'
              value={payload.templateId}
              options={CommonUtil.isEmptyArray(checklistTemplates) ? [] : checklistTemplates}
              getOptionLabel={(option) => option.name ? option.name : ''}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                  setPayload({ ...payload, templateId: newValue });
                } else {
                  setPayload({ ...payload, templateId: '' });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name='templateId' value={payload.templateId} error={error.templateId} variant='outlined' fullWidth label={t("VISTYPE002")}
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: params.templateId ? '#fff' : '#0F273B' }, // Set text color
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                      },
                      '&:hover fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <CustomTextfield
              type={"text"}
              label={t('ATYPE009')}
              error={error.description}
              name="description"
              value={payload.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
