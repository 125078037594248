import { Grid } from '@material-ui/core'
import isoCountries from 'i18n-iso-countries'
import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from 'react-redux'
import { ThemeContext } from '../../theme/ThemeContext';
import { makeStyles } from '@material-ui/core/styles';

isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    width: '100%',
    height: '52px',
    backgroundColor: (props) => (props.darkMode ? '#0F273B' : '#fff'),
    color: (props) => (props.darkMode ? '#fff' : ''),
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': (props) => `0 0 0 100px ${props.darkMode ? '#0F273B' : '#fff'} inset !important`,
      '-webkit-text-fill-color': (props) => (props.darkMode ? '#fff !important' : '#000 !important'),
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '& input:-webkit-autofill:hover': {
      '-webkit-text-fill-color': (props) => (props.darkMode ? '#fff !important' : '#000 !important'),
      '-webkit-box-shadow': (props) => `0 0 0 100px ${props.darkMode ? '#0F273B' : '#fff'} inset !important`,
    },
    '& input:-webkit-autofill:focus': {
      '-webkit-text-fill-color': (props) => (props.darkMode ? '#fff !important' : '#000 !important'),
      '-webkit-box-shadow': (props) => `0 0 0 100px ${props.darkMode ? '#0F273B' : '#fff'} inset !important`,
    },
    '& input:-webkit-autofill:active': {
      '-webkit-text-fill-color': (props) => (props.darkMode ? '#fff !important' : '#000 !important'),
      '-webkit-box-shadow': (props) => `0 0 0 100px ${props.darkMode ? '#0F273B' : '#fff'} inset !important`,
    },
  },
}));

export default function CustomPhones({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder,
  country,
}) {
  const { darkMode } = useContext(ThemeContext);
  const classes = useStyles({ darkMode });

  return (
    <Grid>
      <PhoneInput
        fullWidth
        placeholder={placeholder}
        specialLabel={specialLabel}
        searchPlaceholder=""
        countryCodeEditable={true}
        enableSearch="true"
        disabled={disabled}
        disableCountryCode={false}
        enableAreaCodes={false}
        isValid={isValid}
        value={value}
        onChange={handleChange}
        inputStyle={{ width: '100%', height: '52px',  backgroundColor: darkMode ? '#0F273B' : '#fff', }} 
        country={country}
        containerClass={classes.phoneInput} 
      />
    </Grid>
  );
}

CustomPhones.propTypes = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
};
