import api from '../../config/http-common'

const buildQuery = (payload) => {
  return '?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize
}

const getAPIKeys = (payload) => {
  return api
    .securedAxios()
    .post('/web/company/integrations/list' + buildQuery(payload), payload)
}

const generateAPIKeys = (apiKeyPayload) => {
  return api
    .securedAxios()
    .post('/web/company/integrations/generate', apiKeyPayload)
}

const IntegrationService = {
  getAPIKeys,
  generateAPIKeys,
}

export default IntegrationService
