import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CompanyBackgroundImg from '../assets/CompanyBackgroundImg.svg'
import CompanyBackgroundImg_blue from '../assets/company_blue.svg'
import vis_CompanyBackgroundImg from '../assets/images/onboard/company-white.svg'
import SwitchImage from '../assets/SwitchImage'
import QRCodeICON from '../assets/QRCode'
import jwt_decode from 'jwt-decode'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompany, switchCompanies } from '../slices/onboard/LoginSlice'
import CommonUtil from '../Util/CommonUtils'
import { useTranslation } from 'react-i18next'
import Toast from './ToastContainer/CustomToast'
import { getCompanyInfo } from '../slices/Company/CompanySlice'
import DialogBox from './DialogBoxComponent/DialogBox'
import { LinkForQRCode } from '../slices/Invitations/InvitationsSlice'
import QRCode from 'qrcode.react'
import { useContext } from 'react'
import ProfileContext from './ProfileContext'
import { ThemeContext } from '../theme/ThemeContext'

function SwitchCompany(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const store = useSelector((state) => state)
  const [isSelected, setIsSelected] = useState(false)
  const [companyCode, setCompanyCode] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [companyName, setCompanyName] = useState('')
  const { darkMode, setDarkMode } = useContext(ThemeContext)

  const {
    companyProfilePic,
    setCompanyProfilePic,
    companyProfile,
  } = useContext(ProfileContext)

  const onMouseOverHandleChange = (event) => {
    setIsSelected(true)
    setCompanyCode(event.target.value)
  }

  const onMouseLeaveHandleChange = (event) => {
    setIsSelected(false)
    setCompanyCode(event.target.value)
  }

  useEffect(() => {
    if (CommonUtil.isEmpty(companyList)) loadCompanies()
  }, [])

  useEffect(() => {
    if (!CommonUtil.isEmptyString(companyCode)) {
      VerifyCompany(companyCode)
    }
  }, [companyCode])

  const loadCompanies = () => {
    dispatch(switchCompanies())
      .unwrap()
      .then((data) => {
        if (decoded_jwtToken.companyType !== 'CUSTOMER') {
          setDarkMode(false)
        }
        const companiesArray = CommonUtil.isEmpty(data.data)
          ? []
          : data.data.companies
        const com = data.data.companies.filter((itm) => {
          return itm.id === localStorage.getItem('companyId')
        })
        const companyId = localStorage.getItem('companyId')
        const indexToMove = companiesArray.findIndex(
          (company) => company.id === companyId,
        )
        if (indexToMove !== -1) {
          const companyToMove = companiesArray.splice(indexToMove, 1)[0]
          companiesArray.unshift(companyToMove)
        }
        setCompanyList(CommonUtil.isEmpty(data.data) ? [] : companiesArray)
        setCompanyProfilePic(
          com[0].companyLogo !== undefined
            ? data.data.imagePreSignedURL + com[0].companyLogo
            : '',
        )
      })
      .catch((er) => {})
  }

  const VerifyCompany = (code) => {
    if (CommonUtil.isEmptyString(code)) {
      Toast(t('VERIFYCOMP0001'), 'error')
      return
    }
    const payload = {
      companyCode: code,
      companyId: '',
      type: '',
      fcmToken: '',
    }
    dispatch(selectCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN)
          localStorage.setItem(
            process.env.REACT_APP_ACCESS_TOKEN,
            'Bearer ' + data.data.access_token,
          )
          localStorage.setItem(
            process.env.REACT_APP_REFRESH_TOKEN,
            data.data.refresh_token,
          )
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('companyId', decoded.companyId)
          localStorage.setItem('companyName', decoded.companyName)
          localStorage.setItem('companyCode', payload.companyCode)
          window.location.reload()

          setCompanyName(decoded.companyName)
          dispatch(getCompanyInfo())
            .unwrap()
            .then((data) => {
              if ((data.code = 'UASI0000')) {
                setCompanyProfilePic(data.data.imagePreSignedURL)
              }
            })
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => {})
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setOpenCreateCompany(false)
    setAnchorEl(event.currentTarget)
  }

  const [openQR, setOpenQR] = React.useState(false)
  const handleOpenQR = () => setOpenQR(true)
  const handleCloseQR = () => setOpenQR(false)
  const [openCreateCompany, setOpenCreateCompany] = React.useState(false)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setOpenCreateCompany(false)
    setAnchorEl(null)
  }

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const [link, setLink] = useState(0)

  var urlValue = window.location.href
  var url = new URL(urlValue)
  var code = url.searchParams.get('code')

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  }

  useEffect(() => {
    loadCompany()
  }, [store.profile.profileUpdated, companyProfile])

  useEffect(() => {
    dispatch(LinkForQRCode(code, config))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0511') {
          setLink(data.data)
        }
      })
  }, [])

  // download QR code
  const handleQRCode = () => {
    const qrCodeURL = document
      .getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    console.log(qrCodeURL)
    let aEl = document.createElement('a')
    aEl.href = qrCodeURL
    aEl.download = 'QR_Code.png'
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  }

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0026') {
          setCompanyProfilePic(data.data.imagePreSignedURL)
          setCompanyName(data.data.name)
        }
      })
      .catch((er) => {})
  }

  return decoded_jwtToken.companyType !== 'NONE' &&
    decoded_jwtToken.partnerType !== 'DEALER' ? (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          onMouseOver={handleClick}
          size="small"
          sx={{ ml: 2, mr: 5 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {companyProfilePic !== '' ? (
            <Box>
              <img src={companyProfilePic} height={25} width={70}></img>
            </Box>
          ) : (
            <Avatar
              src={
                companyProfilePic
                  ? companyProfilePic
                  : process.env.REACT_APP_ENVIRONMENT === 'USA'
                  ? CompanyBackgroundImg_blue
                  : vis_CompanyBackgroundImg
              }
              sx={[
                companyProfilePic === ''
                  ? {
                      width: 40,
                      height: 40,
                      background: process.env.REACT_APP_BG_AVATAR,
                      padding: '10px',
                    }
                  : '',
                open && {
                  background: process.env.REACT_APP_BG_AVATAR,
                  opacity: 0.8,
                },
                (theme) => ({
                  '&:hover': {
                    background: process.env.REACT_APP_BG_AVATAR,
                    opacity: 0.8,
                  },
                }),
              ]}
            ></Avatar>
          )}
          <div style={{ padding: '5px' }}>
            <Typography
              variant="h5"
              style={{ color: darkMode ? '#fff' : '#2281AB' }}
            >
              {companyName >= 20 ? (
                <Tooltip title={companyName}>
                  <Typography
                    variant="h5"
                    style={{ color: darkMode ? '#fff' : '#2281AB' }}
                  >
                    {companyName.length <= 20
                      ? companyName
                      : companyName.substring(0, 17) + '...'}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  variant="h5"
                  style={{ color: darkMode ? '#fff' : '#2281AB' }}
                >
                  {companyName.length <= 20
                    ? companyName
                    : companyName.substring(0, 17) + '...'}
                </Typography>
              )}
            </Typography>
          </div>
          <KeyboardArrowDownIcon
            style={{ paddingTop: '2.5px', color: process.env.REACT_APP_ICON }}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        style={{
          border: '0.5px solid lightgray',
          borderRadius: '5px',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
            height: '89px',
            overflow: 'visible',
            mt: 1.5,
            boxShadow: 'none !importent',
            backgroundImage: `url(${CompanyBackgroundImg})`,
            backgroundPosition: 'bottom right',
            backgroundRepeat: 'no-repeat',
            opacity: 0.8,

            '& .MuiAvatar-root': {
              width: 50,
              height: 50,
              ml: 1,
              mr: 1,
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              backgroundColor: '#f1efef7d',
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          onMouseLeave={() => {
            setAnchorEl(null)

            //
          }}
        >
          <Grid style={{ margin: '8px', display: 'flex', direction: 'row' }}>
            <Avatar>
              <SwitchImage />
            </Avatar>
            <Grid style={{ margin: '2px', marginTop: '-7px' }}>
              <div style={{ color: '#2281AB', fontSize: '8px' }}>.</div>
              {decoded_jwtToken.companyName.length >= 20 ? (
                <Tooltip title={decoded_jwtToken.companyName}>
                  <Typography style={{ fontSize: '16px', color: '#FFFFFF' }}>
                    {decoded_jwtToken.companyName.length <= 20
                      ? decoded_jwtToken.companyName
                      : decoded_jwtToken.companyName.substring(0, 17) + '...'}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography style={{ fontSize: '12px', color: '#FFFFFF' }}>
                  {decoded_jwtToken.companyName.length <= 20
                    ? decoded_jwtToken.companyName
                    : decoded_jwtToken.companyName.substring(0, 17) + '...'}
                </Typography>
              )}
              {decoded_jwtToken.email.length >= 10 ? (
                <Tooltip title={decoded_jwtToken.email}>
                  <Typography style={{ color: '#FFFFFF' }}>
                    {decoded_jwtToken.email.length <= 15
                      ? decoded_jwtToken.email
                      : decoded_jwtToken.email.substring(0, 17) + '...'}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography style={{ color: '#FFFFFF' }}>
                  {decoded_jwtToken.email.length <= 15
                    ? decoded_jwtToken.email
                    : decoded_jwtToken.email.substring(0, 17) + '...'}
                </Typography>
              )}
              <div style={{ color: '#FFFFFF' }}>
                {decoded_jwtToken.roleName}
              </div>
            </Grid>
            <Grid style={{ marginLeft: '20px' }}>
              {' '}
              <Avatar onClick={handleOpenQR}>
                <QRCodeICON />
              </Avatar>
            </Grid>
          </Grid>
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' : <Divider />}
          {process.env.REACT_APP_ENVIRONMENT === 'USA' && (
            <div
              style={{
                height: companyList.length === 1 ? '110px' : '250px',
                width: '237',
                overflowY: 'auto',
                border: '0.5px solid lightgray',
                borderRadius: '3px',
                backgroundColor: darkMode ? '#0F273B' : '#fff',
              }}
            >
              <PerfectScrollbar
                style={{
                  backgroundColor: darkMode ? '#0F273B' : '#fff',
                  color: darkMode ? '#fff' : 'black',
                }}
              >
                <Typography
                  style={{
                    fontSize: '12px',
                    color: darkMode ? '#fff' : '#637381',
                    paddingLeft: '15px',
                    paddingTop: '11px',
                  }}
                >
                  {t('COMP0009')}
                </Typography>

                {companyList.map((item) =>
                  item.name.length > 20 ? (
                    <Tooltip title={item.name}>
                      <div>
                        <MenuItem
                          onClick={() => {
                            setCompanyCode(item.code)
                          }}
                          value={item.code}
                          onMouseOver={() => onMouseOverHandleChange}
                          onMouseLeave={() => onMouseLeaveHandleChange()}
                          style={{
                            padding: '5px',
                            margin: '14px',
                            borderRadius: '1px',
                            fontSize: '14px',
                            border: '0.5px solid lightgray',
                            backgroundColor:
                              isSelected &&
                              decoded_jwtToken.companyCode === item.code
                                ? '#EEFFF4'
                                : '',
                          }}
                        >
                          <Radio
                            checked={decoded_jwtToken.companyCode === item.code}
                            value={item.code}
                            onClick={() => setCompanyCode(item.code)}
                          />
                          {item.name.substring(0, 16) + '...'}
                        </MenuItem>
                      </div>
                    </Tooltip>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        setCompanyCode(item.code)
                      }}
                      value={item.code}
                      onMouseOver={() => onMouseOverHandleChange()}
                      onMouseLeave={() => onMouseLeaveHandleChange()}
                      style={{
                        padding: '5px',
                        margin: '14px',
                        border: '0.5px solid lightgray',
                        borderRadius: '1px',
                        color: darkMode ? '#fff' : 'black',
                        fontSize: '14px',
                        backgroundColor:
                          isSelected &&
                          decoded_jwtToken.companyCode === item.code
                            ? darkMode
                              ? '#021C31'
                              : ''
                            : '',
                      }}
                    >
                      <Radio
                        checked={decoded_jwtToken.companyCode === item.code}
                        value={item.code}
                        onClick={() => {
                          setCompanyCode(item.code)
                        }}
                      />
                      {item.name}
                    </MenuItem>
                  ),
                )}
              </PerfectScrollbar>
            </div>
          )}
        </div>
      </Menu>
      <DialogBox
        // Header="Download QR Code"
        Header={
          <span
            style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}
          >
            Download QR Code
          </span>
        }
        acceptText="Download"
        cancelText="Cancel"
        fullWidth={true}
        onClose={handleCloseQR}
        cancel={handleCloseQR}
        onSubmit={handleQRCode}
        open={openQR}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={'center'}
          style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Grid
            container
            style={{
              minHeight: '10vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40vh',
            }}
          >
            <QRCode
              value={link}
              size={200}
              id="qrCodeEl"
              fgColor={darkMode ? '#FFFFFF' : '#000000'}
              bgColor={darkMode ? '#0F273B' : '#FFFFFF'}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </React.Fragment>
  ) : null
}

export default SwitchCompany
