import { makeStyles } from '@material-ui/core';
import {
  Autocomplete,
  Grid,
  TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { getAllSites } from "../../slices/Site/SiteSlice";
import { createZone, updateZone } from "../../slices/Zone/ZoneSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from "../../Util/CommonUtils";

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))
export default function AddZone(props) {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sites, setSites] = React.useState([]);
  const [siteId, setSiteId] = React.useState('');
  const [str, setStr] = useState("");
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    zoneId: "",
    name: "",
    siteId: "",
  });

  const [error, setError] = React.useState({
    zoneId: false,
    name: false,
    siteId: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(payload.name)) {
      Toast(t("COMMON153"), 'error')
      setError({ name: true });
      return;
    }

    if (CommonUtil.isEmpty(payload.siteId)) {
      Toast(t("SITE2"), 'error')
      setError({ siteId: true });
      return;
    }

    const newTemplate = {
      zoneId: props.selectedObject.id,
      name: payload.name,
      siteId: payload.siteId.id,
    };

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(createZone(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI6016") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    }
    else {
      dispatch(updateZone(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI6017") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    }
  };

  useEffect(() => {
    setSiteId(sites.find((site) => {
      return site.id === props.selectedObject.siteId;
    }))
  }, [sites?.length > 0])

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        zoneId: props.selectedObject.id,
        name: props.selectedObject.zoneName,
        siteId: siteId,
      });
    }
  }, [!CommonUtil.isEmptyString(siteId)]);

  const payloadSite = {
    page: 1,
    rowsPerPage: 10000,
    filter: {
      code: '',
      name: ''
    }
  };

  useEffect(() => {
    dispatch(getAllSites(payloadSite)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setSites(data.data.site);
      });
  }, []);

  return (
    <>
      <DialogBox
        // Header={payload.zoneId ? t("COMMON152") : t("COMMON145")}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {payload.zoneId ? t("COMMON152") : t("COMMON145")}
          </span>
        }
        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '#fff' }}
        >
          <Grid item xs={12} mt={1.5}>
            <CustomTextfield
              type={'text'}
              label={t("COMMON143")}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              required={true}
              inputProps={{ className: classes.input, maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Autocomplete
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='siteId'
              value={payload.siteId}
              options={CommonUtil.isEmptyArray(sites) ? [] : sites}
              getOptionLabel={(option) => option.name ? option.name : ''}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  setPayload({ ...payload, siteId: newValue });
                } else {
                  setPayload({ ...payload, siteId: '' });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='siteId'
                  error={error.siteId}
                  variant='outlined'
                  fullWidth
                  label={t("SITE013") + ' * '}
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B', // Label color
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      color: darkMode ? '#fff' : '#0F273B', // Text color
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: darkMode ? '#fff' : '#b3adad', // Border color
                      },
                      '&:hover fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Border color when focused
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
