import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import eyeIcon from '../../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg';
import { ThemeContext } from '../../../theme/ThemeContext';
import ViewIcon from "../../Invitations/ViewIcon";

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '14px',
    minWidth: '100vh',
    backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...props }) => {

  return (
    <DialogTitle variant="h4" sx={{ m: 0, p: 2, fontSize: '20px', color: '#242424' }} {...props}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


function SiteTypeViewDetails({ heading, description, children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  if (open) {
    setDiaLogBoxBlur(true)
  }

  const handleClose = () => {
    setOpen(false);
    setDiaLogBoxBlur(false)
  };

  return (
    <div>
      <Tooltip title={t('VIEW')}>
        <Grid onClick={handleClickOpen}>
          {darkMode ? (
            <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
          ) : (
            <ViewIcon />
          )}
        </Grid>
      </Tooltip>
      <Grid>
        <BootstrapDialog
          // onClose={handleClose}
          open={open}
          darkMode={darkMode}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(2px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
          }}
        >
          <BootstrapDialogTitle onClose={handleClose} sx={{ color: darkMode ? '#fff' : '' }}>
            {t('STYPE015')}
          </BootstrapDialogTitle>
          <Divider variant="middle" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
          <DialogContent >
            <Grid container style={{ padding: '10px', color: darkMode ? '#fff' : '' }}>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2} ><Typography variant="body">{t("COMMON014")}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={9} >
                  {props.rowData.name}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2}><Typography variant="body">{t("COMMON067")}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={9} >
                  {props.rowData.code}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2}> <Typography variant="body">{t('STYPE009')}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={9} style={{ overflowWrap: 'break-word' }}>
                  {props.rowData.description}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default SiteTypeViewDetails;
