import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const ImpersonationIcon = () => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Tooltip title={t('Impersonation')}>
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                        <path id="Path_7346" data-name="Path 7346" d="M11,2a1,1,0,0,1,0-2h8a1,1,0,0,1,1,1V9a1,1,0,0,1-2,0V3.414L11.707,9.707a1,1,0,0,1-1.414,0L7,6.414,1.707,11.707A1,1,0,0,1,.293,10.293l6-6a1,1,0,0,1,1.414,0L11,7.586,16.586,2Z" transform="translate(0)" fill="#c0c7cc" fill-rule="evenodd"/>
                      </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                        <path id="Path_7346" data-name="Path 7346" d="M11,2a1,1,0,0,1,0-2h8a1,1,0,0,1,1,1V9a1,1,0,0,1-2,0V3.414L11.707,9.707a1,1,0,0,1-1.414,0L7,6.414,1.707,11.707A1,1,0,0,1,.293,10.293l6-6a1,1,0,0,1,1.414,0L11,7.586,16.586,2Z" transform="translate(0)" fill="#c0c7cc" fill-rule="evenodd"/>
                      </svg>
                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default ImpersonationIcon;
