import { Avatar } from '@material-ui/core'
import { Box, IconButton, Link, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import isoCountries from 'i18n-iso-countries'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ImportIcon from '../../../src/assets/USA_Images/dashboard_icons/Import.svg'
import ResendIcon1 from '../../../src/assets/USA_Images/login_images_visitor/ResendIcon1.svg'
import AddPersonIcon from '../../assets/images/Registrations/AddRegistrationIcon.png'
import DataTable from '../../components/DataTable/DataTable'
import BulkEmployee from '../../components/DialogBoxComponent/BulkEmployee'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import {
  deactivateEmployeeAccount,
  listAllEmployees,
  resendActivationLink
} from '../../slices/Employee/EmployeeSlice'
import getMemberShipByUserId from '../../slices/MemberShipSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import ResendIcon from '../Invitations/ResendIcon'
import CreateEmployee from './CreateEmployee'
import DeleteEmployee from './DeleteEmployee'
import EmployeeDeleteIcon from './EmployeeDeleteIcon'
import EmployeeEditIcon from './EmployeeEditIcon'
import EmployeeFilter from './EmployeeFilter'

export default function Employee() {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [updateObject, setUpdateObject] = useState({})
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [clear, setClear] = useState(false)
  const [searchedRows, setSerchedRows] = useState([])
  const [controlRendering, setControlRendering] = useState(false)
  const { t } = useTranslation()
  const [employeeId, setEmployeeId] = useState()
  const [personFirstName, setPersonFirstName] = useState()
  const [openDeletePerson, setOpenDeletePerson] = useState(false)
  const [personUserId, setPersonUserId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const [membershipId, setMembershipId] = useState()
  const [roleId, setRoleId] = useState('')
  const [openImportDialog, setOpenImportDialog] = useState(false)
  const [country, setCountry] = useState('')
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [selectedObject, setSelectedObject] = useState({
    employee: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneCountryCode: '',
      phone: '',
      gender: '',
      profilePhoto: '',
      departmentId: '',
      designationId: '',
      code: '',
      departmentIdOrCode: null,
      designationIdOrCode: null,
      roleIdentifier: '',
      userId: '',
      status: '',
      siteId: '',
    },
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      country: '',
      state: '',
      city: '',
      areaCode: '',
      latitude: '',
      longitude: '',
      radius: '',
      timeZone: '',
      isDefault: true,
      addressTypeId: '',
      employeeId: '',
    },
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = useState({
    firstName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    code: '',
    status: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    firstName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    code: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpenEditDialog(true)
    setDiaLogBoxBlur(true)
  }

  useEffect(() => {
    loadCompany()
  }, [])

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          }
        }
      })
      .catch((er) => { })
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const loadData = (filter, paging) => {
    dispatch(listAllEmployees(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'OMSI0000' && data.data)) {
          setRows(data.data.employees)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      firstName: data.firstName,
      email: data.email,
      phoneCountryCode: data.phoneCountryCode,
      phone: data.phone,
      code: data.code,
      status: data.status,
    })
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    const lowercasedSearchVal = searchedVal.toLowerCase()
    const filteredRows = rows.filter((test) => {
      if (
        lowercasedSearchVal === 'active' ||
        lowercasedSearchVal === 'inactive'
      ) {
        return test.status && test.status.toLowerCase() === lowercasedSearchVal
      }
      return (
        (test.firstName
          ? test.firstName.toLowerCase().includes(lowercasedSearchVal)
          : '') ||
        (test.lastName
          ? test.lastName.toLowerCase().includes(lowercasedSearchVal)
          : '') ||
        test.code.toLowerCase().includes(lowercasedSearchVal) ||
        (test.email ? test.email.includes(lowercasedSearchVal) : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(lowercasedSearchVal)
          : '') ||
        (test.status
          ? test.status.toLowerCase().includes(lowercasedSearchVal)
          : '') ||
        `${test.firstName} ${test.lastName}`
          .toLowerCase()
          .includes(lowercasedSearchVal)
      )
    })

    setRows(filteredRows)
  }

  const handleDeletePerson = (params) => {
    setOpenDeletePerson(true)
    setEmployeeId(params.id)
    setPersonFirstName(params.row.firstName)
    setPersonUserId(params.row.userId)
    setDiaLogBoxBlur(true)
  }

  const handleOpenEditPerson = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
    loadEmployeeRole(params)
    setDiaLogBoxBlur(true)
  }

  const loadEmployeeRole = (payload) => {
    dispatch(getMemberShipByUserId(Object.assign(payload)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0000')) {
          setRoleId(data.data.membership[0].roleId)
        } else {
          setRoleId('')
        }
      })
      .catch((er) => { })
  }

  useEffect(() => {
    setRoleId(roleId)
  }, [roleId])

  const isActive = (params) => {
    if (params.row.status === 'INACTIVE') {
      return true
    }
  }

  const handleResendAccountActivate = (paramValue) => {
    if (paramValue.status === 'ACTIVE') {
      Toast(t('EMPLOYEE_ALREADY_ACTIVATED'), 'error')
      return
    }
    dispatch(resendActivationLink(paramValue))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0001') {
          Toast(t('USERSUCCESS'), 'success')
        }
      })
      .catch((er) => { })
  }

  const handleDeActivateEmployeeAccount = (rowData) => {
    if (rowData.roleName === 'Owner') {
      Toast("Admin can't be deleted!", 'error')
      return
    }

    dispatch(deactivateEmployeeAccount(rowData.id))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0028') {
          Toast('Account deactivated successfully', 'success')
          loadData(filter, paging)
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => { })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.profilePhotoURL !== undefined
              ? params.row.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.profilePhotoURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(
    () => [
      {
        field: 'employees.profile',
        headerName: '',
        sortable: false,
        width: 10,
        renderCell: (params) => {
          return ZoomHandaler(params)
        },
      },
      {
        field: 'employees.firstName',
        headerName: t('COMMON014'),
        flex: 1,
        width: 10,
        valueGetter: (params) =>
          params.row.firstName +
          ' ' +
          (params.row.lastName === undefined ? '' : params.row.lastName),
        renderCell: (params) => (
          <Tooltip
            title={` ${params.row.firstName +
              ' ' +
              (params.row.lastName === undefined ? '' : params.row.lastName)
              }`}
            placement="bottom-start"
          >
            <span>
              {handleTextOverflow(
                params.row.firstName +
                ' ' +
                (params.row.lastName === undefined
                  ? ''
                  : params.row.lastName),
              )}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'employees.code',
        headerName: t('COMMON140'),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.code,
      },
      {
        field: 'employees.phone',
        headerName: t('COMMON063'),
        type: 'singleSelect',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.phone,
      },
      {
        field: 'employees.email',
        headerName: t('PERSON005'),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.email,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.email}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.email)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'employees.status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.status,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.status}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.status)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'Actions',
        headerName: t('ACTION'),
        type: 'actions',
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: 'center',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EmployeeEditIcon />}
            label="Edit"
            onClick={() => handleOpenEditPerson(params.row)}
          />,
          <Tooltip title="Resend">
            <div>
              <GridActionsCellItem
                icon={darkMode ? <img src={ResendIcon1} alt="Resend" /> : <ResendIcon />}
                label="Resend"
                onClick={() => handleResendAccountActivate(params.row)}
                disabled={!isActive(params)}
              />
            </div>
          </Tooltip>
          ,

          <GridActionsCellItem
            icon={<EmployeeDeleteIcon />}
            label="Delete"
            onClick={() => handleDeletePerson(params)}
          />
          ,
        ],
      },
    ],
    [],
  )

  const handleImport = () => {
    setOpenImportDialog(true)
    setDiaLogBoxBlur(true)
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <HeaderToolbar
        src={AddPersonIcon}
        onClick={handleOpen}
        title={t('PERSON0111')}
        tooltipTitle={t('COMMON141')}
      />
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>
        <Box item>
          <EmployeeFilter
            rowsPerPage={rowsPerPage}
            country={country}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item>
          <Tooltip title={t('VISACCLEVEL003')}>
            <IconButton>
              <img src={ImportIcon} onClick={handleImport}></img>
            </IconButton>
          </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openEditDialog && (
        <CreateEmployee
          selectedObject={selectedObject}
          open={openEditDialog}
          country={country}
          roleId={roleId}
          onClose={() => {
            setOpenEditDialog(false)
            loadData(filter, paging)
            setRoleId('')
            setDiaLogBoxBlur(false)
          }}
        />
      )}
      {openEditDialog && (
        <CreateEmployee
          selectedObject={selectedObject}
          roleId={roleId}
          country={country}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            loadData(filter, paging)
            setRoleId('')
            setDiaLogBoxBlur(false)
            setSelectedObject({
              employee: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                gender: '',
                profilePhoto: '',
                departmentId: '',
                designationId: '',
                code: '',
                departmentIdOrCode: null,
                designationIdOrCode: null,
                roleIdentifier: '',
                userId: '',
                status: '',
                siteId: '',
              },
              address: {
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                country: '',
                state: '',
                city: '',
                areaCode: '',
                latitude: '',
                longitude: '',
                radius: '',
                timeZone: '',
                isDefault: true,
                addressTypeId: '',
                employeeId: '',
              },
            })
          }}
        />
      )}
      {openDeletePerson && (
        <DeleteEmployee
          open={openDeletePerson}
          handleClose={(data) => {
            setOpenDeletePerson(data)
            setDiaLogBoxBlur(false)
          }}
          DeleteId={employeeId}
          DeleteFirstName={personFirstName}
          DeleteUserId={personUserId}
          delete={() => setDel(true)}
        />
      )}
      {openImportDialog && (
        <BulkEmployee
          open={openImportDialog}
          handleClose={() => {
            setOpenImportDialog(false)
            loadData(filter, paging)
            setDiaLogBoxBlur(false)
          }}
        />
      )}
    </>
  )
}
