import { Dialog, makeStyles } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CommonUtil from '../../Util/CommonUtils'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { ThemeContext } from '../../theme/ThemeContext'

const useStyles = makeStyles((theme) => ({
  dialogPaper: (props) => ({
    borderRadius: '20px',
    minWidth: '600px',
    backgroundColor: props.darkMode ? '#0F273B' : '#FFFFFF',
  }),
}))

export default function PlanDetailsDialog(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { darkMode } = useContext(ThemeContext)
  const classes = useStyles({ darkMode })
  const [showPassword, setShowPassword] = React.useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const planName = props.planName
  const price = props.price
  const { onSubmit, children, onClose, ...other } = props
  const [payload, setPayload] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 15) + '...'
    } else {
      return text
    }
  }

  const [dateFormat, setDateFormat] = useState('')
  const companyId = localStorage.getItem('companyId')

  const handleClose = () => {
    props.onClose(false)
  }
  useEffect(() => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.dateFormat === 'dd-MM-yyyy') {
            setDateFormat('DD-MM-YYYY')
          }
          if (data.data.dateFormat === 'MM-dd-yyyy') {
            setDateFormat('MM-DD-YYYY')
          }
          if (data.data.dateFormat === 'yyyy-MM-dd') {
            setDateFormat('YYYY-MM-DD')
          }
        }
      })
  }, [])

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        {...props}
        open={props.open}
      >
        <DialogTitle
          variant="h4"
          onClick={handleClose}
          sx={{ color: darkMode ? '#fff' : '#3D4977', fontSize: '28px' }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider style={{ backgroundColor: darkMode ? '#fff' : '' }} />
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              color: darkMode ? '#fff' : '#3D4977',
            }}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {' '}
              {t('SUBSCRIPTIONPLANNAME')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {props.planDetails.subscriptionPurchaseId.subscriptionName}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {' '}
              {t('START_DATE')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {dateFormat === 'DD-MM-YYYY'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.startDate,
                    ),
                  ).format('DD-MM-YYYY HH:mm')
                : dateFormat === 'MM-DD-YYYY'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.startDate,
                    ),
                  ).format('MM-DD-YYYY HH:mm')
                : dateFormat === 'YYYY-MM-DD'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.startDate,
                    ),
                  ).format('YYYY-MM-DD HH:mm')
                : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {t('END_DATE')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {dateFormat === 'DD-MM-YYYY'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.endDate,
                    ),
                  ).format('DD-MM-YYYY HH:mm')
                : dateFormat === 'MM-DD-YYYY'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.endDate,
                    ),
                  ).format('MM-DD-YYYY HH:mm')
                : dateFormat === 'YYYY-MM-DD'
                ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(
                      props.planDetails.subscriptionPurchaseId.endDate,
                    ),
                  ).format('YYYY-MM-DD HH:mm')
                : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {t('RENEWAL_DATE')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}></Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {t('CUSTDATABLE22')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Tooltip title={props.parentDetails.name}>
                {handleTextOverflow(props.parentDetails.name || ' ')}
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {t('DEALEREMAIL')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Tooltip title={props.parentDetails.email}>
                {handleTextOverflow(props.parentDetails.email || ' ')}
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {' '}
              {t('COMMON062')}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ marginTop: '10px' }}
            >
              :
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {props.parentDetails.phone || ' '}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
