import { Box, Grid, Link, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REACT_SMS_EDIT } from '../../actions/EndPoints'
import DataTable from '../../components/DataTable/DataTable'
import SmsTemplateFIlterform from '../../components/DialogBoxComponent/SmsTemplateFIlterform'
import Search from '../../components/SearchTab/Search'
import { getSmsTemplate } from '../../slices/SmsTemplate/SmsTemplateSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'

function SmsTemplate() {
  const { t } = useTranslation()
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const history = useHistory()
  const dispatch = useDispatch()
  const { darkMode } = useContext(ThemeContext)

  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const defaultPaging = {
    pageNumber: 1,
    pageSize: 10,
  }

  const [filter, setFilter] = useState({
    recipient: '',
  })

  const defaultFilter = {
    recipient: '',
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    const payload = {
      recipient: filter.recipient,
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
    }
    dispatch(getSmsTemplate(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAE4001') {
          setRows(data.data.templateList)
          setTotalRecords(data.data.totalCount)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, pageNumber: 1 })
    setFilter({
      ...filter,
      recipient: data.recipient,
    })
  }

  const globalsearch = (searchedValue) => {
    const input = rows.map((item) => item.code.replace(/_/g, ' ').toLowerCase())
    const filteredRows = rows.filter((test) => {
      return (
        (test.recipient !== undefined
          ? test.recipient.toLowerCase().includes(searchedValue.toLowerCase())
          : '') ||
        (test.code !== undefined
          ? test.code
            .replace(/_/g, ' ')
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
          : '') ||
        (test.message !== undefined
          ? test.message
            .replace(/\n/g, '')
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
          : '') ||
        (test.status !== undefined
          ? searchedValue.toLowerCase() === 'enable'
            ? test.status === true
            : searchedValue.toLowerCase() === 'disable'
              ? test.status === false
              : ''
          : '')
      )
    })
    setRows(filteredRows)
  }

  const handleEditSmsTemplate = (params) => {
    history.push({ pathname: REACT_SMS_EDIT, state: params })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 58) + '...'
    } else {
      return text
    }
  }

  const convertString = (inputString) => {
    // Split the input string by underscores
    const parts = inputString.split('_')

    // Convert each part to lowercase and capitalize the first letter
    const convertedParts = parts.map((part) => {
      // Convert the part to lowercase
      const lowercasePart = part.toLowerCase()

      // Capitalize the first letter of the part
      if (lowercasePart.length > 0) {
        return lowercasePart.charAt(0).toUpperCase() + lowercasePart.slice(1)
      }

      return lowercasePart
    })

    // Join the converted parts with spaces
    const result = convertedParts.join(' ')

    return result
  }

  const columns = [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },
    {
      field: 'recipient',
      headerName: t('RECIPIENT'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.recipient,
    },
    {
      field: 'category',
      headerName: t('CATEGORY'),
      flex: 1.5,
      width: 50,
      valueGetter: (params) => convertString(params.row.code),
    },

    {
      field: 'subject',
      headerName: t('SUBJECT'),
      flex: 2.5,
      width: 190,
      valueGetter: (params) => params.row.message,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'stauts',
      headerName: t('Status'),
      flex: 1,
      width: 20,
      valueGetter: (params) => (params.row.status ? 'Enable' : 'Disable'),
    },

    {
      field: 'Action',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('dataTableEdit')}
          onClick={() => handleEditSmsTemplate(params.row)}
        />,
      ],
    },
  ]

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode')
    } else {
      document.body.classList.remove('darkMode')
    }
    return () => {
      document.body.classList.remove('darkMode')
    }
  }, [darkMode])
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}>
            <Typography
              variant="h3"
              style={{
                marginLeft: darkMode ? '33px' : '0px',
                marginRight: darkMode ? '18px' : '12px',
                color: darkMode ? '#fff' : '#3D4977',
                marginTop: darkMode ? '3px' : '4px',
              }}
            >
              {t('COMMON076')}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <SmsTemplateFIlterform
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
    </>
  )
}

export default SmsTemplate
