import React, { useContext, useState } from 'react'
// import CloseIcon from "@mui/icons-material/Close";
import { Dialog, makeStyles } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CustomTextfield from '../../../src/components/CustomInputs/CustomTextfield'
import Toast from '../../components/ToastContainer/CustomToast'
import { getVisitordetailsByCardNumber } from '../../slices/PinNumber/PinNumberSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import SubmitButtons from '../DialogBoxComponent/SubmitButtons'
import PinVisitorDetails from './PinVisitorDetails'

const useStyles = makeStyles((theme) => ({
  dialogPaper: (props) => ({
    borderRadius: '20px',
    backgroundColor: props.darkMode ? '#0F273B' : '#fff',
  }),
}));

export default function PinDialog(props) {
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const classes = useStyles({ darkMode });
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { onSubmit, children, onClose, ...other } = props
  const dispatch = useDispatch()
  const [visitor, setVisitor] = useState({})
  const [purposeType, setPurposeType] = useState({})
  const [visitorType, setVisitorType] = useState({})
  const [hostName, setHostName] = useState('')
  const [CompanyName, setCompanyName] = useState('')
  const [inviteStartDate, setInviteStartDate] = useState('')
  const [inviteEndDate, setInviteEndDate] = useState('')
  const [multipleData, SetmultipleData] = useState({})

  const [payload, setPayload] = useState({
    pinNumber: '',
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setPayload({ ...payload, showPassword: !payload.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  if (props.open) {
    setDiaLogBoxBlur(true)
  }

  const handleOpen = () => {
    const data = {
      pinNumber: payload.pinNumber,
    }

    if (CommonUtil.isEmptyString(payload.pinNumber)) {
      Toast(t('ENTER_PIN_NUMBER'), 'error')

      return
    }

    dispatch(getVisitordetailsByCardNumber(data))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitor(data.data.visitor)
          setPurposeType(data.data.purposeType)
          setVisitorType(data.data.visitorType)
          setHostName(data.data.hostName)
          setCompanyName(localStorage.getItem('companyName'))
          setInviteStartDate(data.data.scheduledStartDate)
          setInviteEndDate(data.data.scheduledEndDate)
          SetmultipleData(data.data)
          setOpen(true)
        } else if (data.code === 'CVAE0309') {
          Toast(data.message, 'error')
        } else {
          Toast(t('NO_RECORDS_FOUND_PIN'), 'error')
          setVisitor({})
          setOpen(false)
          return
        }
      })
  }

  const handleClose1 = () => {
    setOpen(false)
    props.onClose(false)
  }

  const handleClose = () => {
    props.onClose(false)
    setDiaLogBoxBlur(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
  }

  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} {...props}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        BackdropProps={{
          style: {
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
        }}>
        <DialogTitle variant="h4" onClick={handleClose} style={{ color: darkMode ? '#fff' : '' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider variant="middle" />
        {/* {props.children} */}

        <DialogContent>
          <Grid
            container
            justifyContent="center"
            style={{ padding: '16px 32px', width: '100%' }}
            spacing={2}
          >
            <Grid item xs={12} sm={10.5} lg={12}>
              <CustomTextfield
                label={t('LABEL_PIN_NUMBER')}
                type={payload.showPassword ? 'text' : 'Password'}
                name="pinNumber"
                required={true}
                value={payload.pinNumber}
                autoComplete="new-password"
                handleChange={(e) => handleChange(e)}
                validation="numeric"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        className="PasswordIcon"
                      >
                        {payload.showPassword ? (
                          <Visibility className="visibleIcons" />
                        ) : (
                          <VisibilityOff className="visibleIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          <SubmitButtons onClick={handleOpen} type="submit" >
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {props.acceptText}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>

      {open && (
        <PinVisitorDetails
          open={open}
          handleDialogClose={handleClose1}
          data={visitor}
          purposeType={purposeType}
          visitorType={visitorType}
          host={hostName}
          company={CompanyName}
          startDate={inviteStartDate}
          endDate={inviteEndDate}
          pinNumber={payload.pinNumber}
          multipleData={multipleData}
        />
      )}
    </>
  )
}
