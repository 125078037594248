import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InvitationsService from '../../services/Invitations/InvitationService'

const initialState = []
export const createInvitation = createAsyncThunk(
  'invitation/create',
  async (payload) => {
    const res = await InvitationsService.create(payload)
    return res.data
  },
)

export const createMultipleInvitation = createAsyncThunk(
  'multipleInvitation/create',
  async (payload) => {
    const res = await InvitationsService.createMultipleInvitation(payload)
    return res.data
  },
)

export const listInvitations = createAsyncThunk(
  'invitation/retrieve',
  async (payload) => {
    const res = await InvitationsService.get(payload)
    return res.data
  },
)

export const rescheduleVisitor = createAsyncThunk(
  'invitation/reschedule',
  async (payload) => {
    const res = await InvitationsService.reschedule(payload)
    return res.data
  },
)

export const resendInvitation = createAsyncThunk(
  'invitation/resendInvitation',
  async (id) => {
    const res = await InvitationsService.resendInvitation(id)
    return res.data
  },
)

export const forceCheckout = createAsyncThunk(
  'visitor/forceCheckout',
  async (payload) => {
    const res = await InvitationsService.forceCheckout(payload)
    return res.data
  },
)

export const cancelVisitorInvite = createAsyncThunk(
  'invitation/cancel',
  async (payload) => {
    const res = await InvitationsService.cancel(payload)
    return res.data
  },
)

export const updateVisitor = createAsyncThunk(
  'visitor/update',
  async (payload) => {
    const res = await InvitationsService.updatevisitor(payload)
    return res.data
  },
)

export const rejectVisitor = createAsyncThunk(
  'visitor/reject',
  async (payload) => {
    const res = await InvitationsService.rejectVisit(payload)
    return res.data
  },
)

export const confirmVisitor = createAsyncThunk(
  'visitor/confirm',
  async (payload) => {
    const res = await InvitationsService.confirmVisit(payload)
    return res.data
  },
)

export const submitCheckListAnswers = createAsyncThunk(
  'visitor/checklist',
  async (payload) => {
    const res = await InvitationsService.submitCheckListanswers(payload)
    return res.data
  },
)

export const validateInvitation = createAsyncThunk(
  'visitor/validate',
  async (payload) => {
    const res = await InvitationsService.validateinvite(payload)
    return res.data
  },
)

export const rejectApprovalVisit = createAsyncThunk(
  'rejectApprovalVisit/visit',
  async (payload) => {
    const res = await InvitationsService.rejectApprovalVisit(payload)
    return res.data
  },
)

export const acceptApprovalVisit = createAsyncThunk(
  'acceptApprovalVisit/visit',
  async (payload) => {
    const res = await InvitationsService.acceptApprovalVisit(payload)
    return res.data
  },
)

export const QRCodeForVisitors = createAsyncThunk(
  'QRCodeForVisitors/visit',
  async (payload) => {
    const res = await InvitationsService.QRCodeForVisitors(payload)
    return res.data
  },
)

export const LinkForQRCode = createAsyncThunk(
  'LinkForQRCode/visit',
  async (payload) => {
    const res = await InvitationsService.LinkQRCode(payload)
    return res.data
  },
)

export const visitorUpdate = createAsyncThunk(
  'visitorUpdate/visit',
  async (payload) => {
    const res = await InvitationsService.visitorUpdate(payload)
    return res.data
  },
)

export const createSelf = createAsyncThunk(
  'createSelf/visit',
  async (payload) => {
    const res = await InvitationsService.createSelf(payload)
    return res.data
  },
)

export const getvisitorDetails = createAsyncThunk(
  'getVisitorDetails',
  async (payload) => {
    const res = await InvitationsService.getvisitorDetails(payload)
    return res.data
  },
)

export const getDlImages = createAsyncThunk('getDlImage', async (payload) => {
  const res = await InvitationsService.getDlImages(payload)
  return res.data
})

export const getVisitorDetailsById = createAsyncThunk(
  'getVisitorDetailsById',
  async (payload) => {
    const res = await InvitationsService.getVisitorDetailsById(payload)
    return res.data
  },
)
const invitationSlice = createSlice({
  name: 'invitation',
  ...initialState,
  extraReducers: {
    [listInvitations.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },

    [rescheduleVisitor.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },
  },
})

const { reducer } = invitationSlice
export default reducer
