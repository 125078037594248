import { Autocomplete, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../components/Util/CommonUtils";
import { getBlackListType } from "../../slices/BlacklistType/BlacklistTypeSlice";
import { blacklistVisitor } from "../../slices/BlacklistVisitor/BlacklistVisitorSlice";
import { ThemeContext } from '../../theme/ThemeContext';

export default function BlacklistVisitorForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [blacklistData, setBlacklistData] = useState([]);
  const { darkMode } = useContext(ThemeContext);
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = React.useState({
    visitId: props.BlacklistVisitorId,
    restrictedBy: decoded_jwtToken.firstName + " " + decoded_jwtToken.lastName,
    restrictReasonType: "",
    restrictReason: "",
    // removeAccess: false,
  });

  const [error, setError] = React.useState({
    restrictReasonType: false,
    restrictReason: false,
    removeAccess: false,
  });

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setPayload({
  //     ...payload,
  //     [name]: event.target.value,
  //     [name]: event.target.checked,
  //   });
  // };

  const [removeAccess, setRemoveAccess] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleChange1 = (event) => {
    setRemoveAccess(event.target.checked);
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.checked,
    });
  };
  const [selectedBlackList, setSelectedBlackList] = useState(null);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.restrictReasonType)) {
      Toast(t("VISSTATUST"), "error");
      setError({ restrictReasonType: true });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.restrictReason)) {
      Toast(t("VISSTATUST1"), "error");
      setError({ restrictReason: true });
      return false;
    }


    dispatch(blacklistVisitor(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE1046") {
          Toast(t("COMMON073"), "success");
          props.disable();
          handleClose();
        } else if (data.code === "CVAE1049") {
          Toast(data.message, "success");
          props.disable();
          handleClose();
        }
        else {
          Toast(data.message, "error");
          handleClose();
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadBlacklistType();
  };

  const loadBlacklistType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getBlackListType(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setBlacklistData(data.data.purposeTypes);
        } else {
          setBlacklistData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const onselectBlacklistType = (event) => {
  //   setPayload({
  //     ...payload,
  //     restrictReasonType: event.id,
  //   });
  // };
  const onselectBlacklistType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        restrictReasonType: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        restrictReasonType: ''
      })
    }
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <DialogBox
        Header={
          <>
            <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
              {process.env.REACT_APP_ENVIRONMENT === "USA"
                ? t("BLOCKEDVIS004")
                : "Block Visitor"}
            </span>
            {/* <Divider style={{ backgroundColor: darkMode ? '#fff' : '#000', margin: '28px 0' }} /> */}
          </>
        }

        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >

        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid item xs={10} sm={10} style={{ marginTop: "10px" }}>
            {/* <AutocompleteComponent
              label="Blacklist Type *"
              name="restrictReasonType"
              error={error.restrictReasonType}
              value={payload.restrictReasonType}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectBlacklistType(e)}
              options={blacklistData}
            /> */}
            <Autocomplete
              fullWidth
              noOptionsText={'No Options found'}
              options={blacklistData}
              getOptionLabel={(option) =>
                option.name ? option.name : ""
              }
              value={selectedBlackList}
              onChange={(event, newValue) => {
                setSelectedBlackList(newValue)
                onselectBlacklistType(newValue)
                setError({ restrictReasonType: false })
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label={t("BLOCKEDVIS005") + "*"}
                  required={false}
                  palceholder={t("BLOCKEDVIS005") + "*"}
                  name="id"
                  error={error.restrictReasonType}
                />
              )}
            />
          </Grid>

          <Grid item xs={10} sm={10}>
            <CustomTextfield
              type={"text"}
              label={t("BLOCKEDVIS006")}
              error={error.restrictReason}
              name="restrictReason"
              value={payload.restrictReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>

          {/* <Grid item xs={10}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: process.env.REACT_APP_BG_ICON }}
                  name="removeAccess"
                  checked={removeAccess}
                  onChange={handleChange1}
                />
              }
              label={
                <Typography style={{ color: "#3D4977" }}>
                  {t("BLOCKVISAPP001")}
                </Typography>
              }
            />
          </Grid> */}

        </Grid>
      </DialogBox>
    </>
  );
}
