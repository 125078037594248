import { Box, Link } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RestrictTypeAddIcon from "../../../src/assets/BlacklistType.svg";
import DataTable from "../../components/DataTable/DataTable";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { getBlackListType } from "../../slices/BlacklistType/BlacklistTypeSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import DeleteChecklist from "../Checklist/DeleteChecklist";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import ChecklistTemplateUSAView from "../ChecklistTemplateUSA/ChecklistTemplateUSAView";
import FilterChecklistTemplate from "../ChecklistTemplateUSA/FilterChecklistTemplate";
import AddBlacklistType from "./AddBlacklistType";
import DeleteBlacklistType from "./DeleteBlacklistType";

export default function BlacklistType(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openDeleteBlacklistType, setOpenDeletBlacklistType] = useState(false);
  const [blacklistId, setBlacklistId] = useState();
  const [del, setDel] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    description: "",
  });

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
    setDiaLogBoxBlur(true)
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(getBlackListType(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data.data.purposeTypes);
          setPageSize(data.data.pageSize)
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
    setDiaLogBoxBlur(true)
  };

  const handleDeleteblacklistType = (params) => {
    setOpenDeletBlacklistType(!openDeleteBlacklistType);
    setBlacklistId(params.id);
    setDiaLogBoxBlur(true)
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || test.description.toLowerCase().includes(searchedVal.toLowerCase())

    });
    setRows(filteredRows);
  };

  useEffect(() => {

    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage });
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false);
      }
    }
  }, [del]);


  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: t("BLOCKVISITOR001"),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: "description",
      headerName: t("RestrictTypeForm005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<ChecklistTemplateUSAView
            title={t("BLACKLIST")}
            rowData={params.row} />}
          label={t("VIEW")}
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('COMMON005')}
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteChecklist />}
          label={t("dataTableDelete")}
          onClick={() => handleDeleteblacklistType(params)}
        />,

      ],
    },

  ]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>

      <HeaderToolbar
        src={RestrictTypeAddIcon}
        onClick={handleOpen}
        title={t("BLOCKEDVIS005")}
        tooltipTitle={t("ADD033")}
      />



      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddBlacklistType
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            setOpen(false);
            loadData(filter, paging);
            setDiaLogBoxBlur(false)
          }}
        />
      )}

      {openEditDialog && (
        <AddBlacklistType
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setDiaLogBoxBlur(false)
            setSelectedObject({
              id: "",
              name: "",
              description: "",
            });
          }}
        />
      )},

      {openDeleteBlacklistType && (
        <DeleteBlacklistType
          open={openDeleteBlacklistType}
          handleClose={(data) => {
            setOpenDeletBlacklistType(data);
            setDiaLogBoxBlur(false)
          }}
          delete={() => setDel(true)}
          DeleteId={blacklistId}
        />
      )},



    </>
  )
}


