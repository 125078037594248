
import React, { useContext } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import { Dialog, makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import { ThemeContext } from '../../theme/ThemeContext';
import CancelButtons from "../DialogBoxComponent/CancelButtons";
import SubmitButtons from "../DialogBoxComponent/SubmitButtons";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "20px",
    // minWidth:'900px'
  },
}));

export default function DialogBox(props) {
  const classes = useStyles();
  const { darkMode, } = useContext(ThemeContext);
  const { onSubmit, children, onClose, ...other } = props;

  const handleClose = () => {
    props.onClose(false);
  };
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        {...props}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return; 
          }
        }}
        BackdropProps={{
          style: {
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
        }}
      >
        <DialogTitle variant="h4" onClick={handleClose} style={{ backgroundColor: darkMode ? "#0F273B" : "#fff" }} >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}

          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider variant="middle" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
        {props.children}
        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: darkMode ? "0px" : "20px",
            backgroundColor: darkMode ? "#0F273B" : "#fff"
          }}
        >
          <SubmitButtons onClick={onSubmit} type='submit'>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {props.acceptText}
            </Typography>
          </SubmitButtons>

          <CancelButtons onClick={() => props.cancel()}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {props.cancelText}
            </Typography>
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
