import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Divider, Grid, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import eyeIcon from '../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg';
import { ViewDeliveryReportsDetails } from "../../slices/Reports/DeliveryReportSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from "../../Util/CommonUtils";
import ViewIcon from "../Invitations/ViewIcon";

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        overflowY: 'visible',
        borderRadius: '14px',
        minWidth: '120vh',
        height: '70%',
        backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
    },
}));

const BootstrapDialogTitle = ({ children, onClose, ...props }) => {

    return (
        <DialogTitle variant="h4" sx={{ m: 0, p: 2, fontSize: '20px', color: '#242424' }} {...props}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


function DeliveryReportView({ heading, description, children, ...props }) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
    const [payload, setPayload] = useState({
        parcelImageObjectKey: props.rowData.parcelImageObjectKey ? props.rowData.parcelImageObjectKey : "",
        personImageObjectKey: props.rowData.personImageObjectKey ? props.rowData.personImageObjectKey : "",
    })
    const [parcelImagePresignedUrl, setParcelImagePresignedUrl] = useState('');
    const [personImagePresignedUrl, setPersonImagePresignedUrl] = useState('');

    const handleClickOpen = () => {
        if (!CommonUtil.isEmptyString(props.rowData.parcelImageObjectKey) || !CommonUtil.isEmptyString(props.rowData.personImageObjectKey)) {
            loadData();
        }
        setOpen(true);
    };

    if (open) {
        setDiaLogBoxBlur(true)
    }

    const handleClose = () => {

        setOpen(false);
        setDiaLogBoxBlur(false)
    };

    const loadData = () => {
        if (!CommonUtil.isEmptyString(payload.parcelImageObjectKey) || (!CommonUtil.isEmptyString(payload.personImageObjectKey))) {
            let objectKeys = [];
            if (!CommonUtil.isEmptyString(payload.parcelImageObjectKey)) {
                objectKeys.push(payload.parcelImageObjectKey);
            }
            if (!CommonUtil.isEmptyString(payload.personImageObjectKey)) {
                objectKeys.push(payload.personImageObjectKey);
            }

            const dto = {
                objectKeys
            }
            dispatch(ViewDeliveryReportsDetails(dto))
                .unwrap().then((data) => {
                    if (data.code === 'STSI0000') {
                        data.data.map((obj) => {
                            if (payload.parcelImageObjectKey === obj.objectKey) {
                                setParcelImagePresignedUrl(obj.preSignedUrl);
                            }
                            else if (payload.personImageObjectKey === obj.objectKey) {
                                setPersonImagePresignedUrl(obj.preSignedUrl);
                            }
                        })
                    }
                })
        }
    }

    return (
        <div>
            <Tooltip title={t('VIEW')}>
                <Grid onClick={handleClickOpen}>
                    {darkMode ? (
                        <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
                    ) : (
                        <ViewIcon />
                    )}
                </Grid>
            </Tooltip>
            <Grid>
                <BootstrapDialog
                    open={open}
                    darkMode={darkMode}
                    BackdropProps={{
                        style: {
                            backdropFilter: 'blur(2px)',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        },
                    }}
                >
                    <BootstrapDialogTitle onClose={handleClose} style={{ color: darkMode ? '#fff' : '' }}>
                        {props.title}
                    </BootstrapDialogTitle>
                    <Divider variant="middle" style={{ backgroundColor: darkMode ? '#fff' : '' }} />
                    <DialogContent >
                        <Grid container style={{ padding: '10px' }}>
                            <Grid item sm={12} md={6} lg={6} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: '20px', borderStyle: 'solid ', borderRadius: '10px', padding: '35px', color: '#e9e9e9', opacity: 1 }}>
                                    <Avatar
                                        sx={{ width: 250, height: 250, border: 'solid #FFFF', borderWidth: '7px', left: '10%' }}
                                        alt={payload.parcelImageObjectKey}
                                        // src={props.rowData.parcelImageObjectKey !== undefined ? props.rowData.parcelImageObjectKey : null}
                                        src={parcelImagePresignedUrl}
                                    >
                                    </Avatar>
                                    <Grid direction='row' display='flex' alignItems='flex-end' style={{ color: '#C3C5C7', opacity: 1, fontSize: '24px', paddingTop: '34px', justifyContent: 'center' }}>
                                        {t('PERCEL_IMAGE')}
                                    </Grid>

                                </div>
                            </Grid>
                            <Grid item sm={12} md={6} lg={6} >
                                <div style={{ margin: '20px', borderStyle: 'solid ', borderRadius: '10px', padding: '35px', color: '#e9e9e9', opacity: 1 }}>
                                    <Avatar
                                        sx={{ width: 250, height: 250, border: 'solid #FFFF', borderWidth: '7px', left: '10%' }}
                                        alt={props.rowData.personImageObjectKey}
                                        src={personImagePresignedUrl}>
                                    </Avatar>
                                    <Grid direction='row' display='flex' style={{ color: '#C3C5C7', opacity: 1, fontSize: '24px', paddingTop: '34px', justifyContent: 'center' }}>
                                        {t('DELIVERY_MAN')}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default DeliveryReportView;
