import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../../src/assets/FilterIcon.svg'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice"
import { listVisitorTypeZlink } from '../../slices/PurposeTypeUSA/PurposeTypeUSASlice'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'

export default function FilterBlockedVisitors(props) {
  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [visitorData, setVisitorData] = useState([])
  const { darkMode, setDiaLogBoxBlur } = useContext(ThemeContext);
  const [purposeData, setPurposeData] = useState([]);
  const [payload, setPayload] = useState({
    purposeTypeId: '',
    visitorTypeId: '',
    status: props.status,
    visitMode: props.selectedObject,
  })

  const [selectedPurpose, setSelectedPurpose] = useState(null)
  const [selectedVisitor, setSelectedVisitor] = useState(null)

  const handleClose = () => {
    setPayload({
      purposeTypeId: '',
      visitorTypeId: '',
      status: props.status,
      visitMode: props.selectedObject,
    })
    setSelectedPurpose(null)
    setSelectedVisitor(null)
    setOpen(false)
    setDiaLogBoxBlur(false)
  }

  if (open) {
    setDiaLogBoxBlur(true)
  }
  const [error, setError] = useState({
    purposeTypeId: false,
    visitorTypeId: false,
  })



  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const reset = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (payload.purposeTypeId === '' && payload.visitorTypeId === '') {
      Toast(t('COMMON015'), 'error')
      return
    }

    props.applyFilter(payload)
    setPayload({
      purposeTypeId: '',
      visitorTypeId: '',
      visitMode: props.selectedObject,
      status: props.status,
    })
    handleClose()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadVisitType()
    loadVisitorType()
    loadPurposeType();
  }

  const loadVisitType = () => {
    const query = {
      id: '',
      name: '',
      pageNumber: 1,
      pageSize: 0,
      isPageable: true,
    }

    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setData(data.data.purposeTypes)
        } else {
          setData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const selectedPurposeType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        purposeTypeId: newValue.id,
      })
    } else {
      setPayload({
        ...payload,
        purposeTypeId: '',
      })
    }
  }

  const selectedVisitorType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        visitorTypeId: newValue.id,
      })
    } else {
      setPayload({
        ...payload,
        visitorTypeId: '',
      })
    }
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Tooltip title={<Typography>{t('COMMON006')}</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <FilterBlockedVisitors />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6} >
        <Dialog open={open} fullWidth={true}
          BackdropProps={{
            style: {
              backdropFilter: 'blur(2px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
            },
          }}
        >
          <DialogTitle variant="h4" onClick={handleClose} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
              {t('COMMON006')}
            </Typography>
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            <Grid container justifyContent="center" alignItems={'center'}>
              <Grid item xs={12} sm={9} style={{ marginTop: '10px' }}>
                <Autocomplete
                  fullWidth
                  noOptionsText={'No Options found'}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  value={selectedPurpose}
                  onChange={(event, newValue) => {
                    setSelectedPurpose(newValue)
                    selectedPurposeType(newValue)
                    setError({ purposeTypeId: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('PURPOSETYPE')}
                      required={false}
                      palceholder="PurposeType"
                      name="id"
                      error={error.purposeTypeId}
                      InputLabelProps={{
                        style: {
                          color: darkMode ? '#fff' : '#0F273B',
                        },
                      }}

                      InputProps={{
                        ...params.InputProps,
                        style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                          },
                          '&:hover fieldset': {
                            borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                          },
                        },
                      }}
                    />
                  )}

                // onChange={(event, newValue) => {
                //   setSelectedPurpose(newValue)
                //   selectedPurposeType(newValue)
                //   setError({ purposeTypeId: false })
                // }}
                // renderInput={(params) => (
                //   <TextField
                //     {...params}
                //     label={t('PURPOSETYPE')}
                //     required={false}
                //     palceholder="PurposeType"
                //     name="id"
                //     error={error.purposeTypeId}
                //   />
                // )}
                />
              </Grid>

              <Grid item xs={12} sm={9} style={{ marginTop: '10px' }}>
                <Autocomplete
                  fullWidth
                  noOptionsText={'No Options found'}
                  options={visitorData}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  value={selectedVisitor}
                  onChange={(event, newValue) => {
                    setSelectedVisitor(newValue)
                    selectedVisitorType(newValue)
                    setError({ visitorTypeId: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('COMMON068')}
                      required={false}
                      palceholder="Visitor Type"
                      name="id"
                      error={error.visitorTypeId}
                      InputLabelProps={{
                        style: {
                          color: darkMode ? '#fff' : '#0F273B',
                        },
                      }}

                      InputProps={{
                        ...params.InputProps,
                        style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                          },
                          '&:hover fieldset': {
                            borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'center',
              backgroundColor: darkMode ? '#0F273B' : '#fff'
            }}
          >
            <SubmitButtons variant="contained" onClick={handleSubmit}>
              <Typography variant="body1" style={{ textTransform: 'none' }}>
                {t('COMMON024')}
              </Typography>
            </SubmitButtons>
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              <Typography variant="body1" style={{ textTransform: 'none' }}>
                {t('INVITATION003')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  )
}
