import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import loaderGif from '../../assets/mysmart-loader.gif'; 

function Loader() {

    const [loading, setLoading] = React.useState(false);
    const store = useSelector((state) => state);

    useEffect(() => {
        setLoading(store.loader.loader);
    }, [store.loader.loader]);

    return (
        <>
            {!loading ? null :
                (
                    <div className='loader-container'>
                        <div className='loader'>
                            <img src={loaderGif} alt="Loading..." style={{ width: '100px', height: '100px' }} />
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default Loader;