import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, {useContext} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../../theme/ThemeContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
        width: "630px",
    },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    // textAlign: "center",
    marginLeft: "11px"
}));

const CustomDialogTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: "1.15rem",
    textAlign: "center",
    backgroundColor: "rgb(14, 91, 157)",
    color: "white",
    padding: theme.spacing(2),
    fontWeight: "500"
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
}

export default function SessionExpiredModal({ isOpen, onClose }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { darkMode } = useContext(ThemeContext);
    const handleOkClick = () => {
        localStorage.clear();
        history.push('/vis/login');
        onClose(); // Close the modal after redirection
    };

    return (
        <BootstrapDialog
            maxWidth="lg"
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            onClose={null}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
        >
            <CustomDialogTitleTypography>
                {t("SESSION EXPIRED")}
            </CustomDialogTitleTypography>
            <DialogContent>
                <CustomTypography gutterBottom mt={1} style={{color: darkMode ? '#fff' : ''}}>
                    {t('SESSION_EXPIRED')}
                </CustomTypography>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    autoFocus
                    onClick={handleOkClick}
                    variant="contained"
                    style={{ backgroundColor: "rgb(14, 91, 157)", textTransform: "capitalize", width: "139px", height: "39px" }}
                >
                    {t("OK_BUTTON")}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
