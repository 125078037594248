import { Grid } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddDeviceIcon } from '../../assets/images/checkInAndOutDevice/AddDeviceIcon.svg'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { ReactComponent as AddDeviceIconUSA } from '../../assets/USA_Images/adddeviceusa.svg'
import { updateDoorName } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice'
import { toast } from 'react-toastify'
import Toast from '../../components/ToastContainer/CustomToast'
import { ThemeContext } from '../../theme/ThemeContext';

export default function AddDeviceDialog(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [payload, setPayload] = useState({ parameters: { name: doorName } })
  const { darkMode } = useContext(ThemeContext);
  const handleSubmit = () => {
    if (doorName === '') {
      Toast('Door Name is Mandatory', 'error')
      return
    }
    // handleClose()
    // props.handleDevice()

    const state = {
      payload: payload,
      doorId: props.data.doorId,
    }
    dispatch(updateDoorName(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          // toast.success('Door name Updated successfully')
          props.handleDevice();
          props.reloadlist(true);
          handleClose()
        } else {
          // toast.error("There is some issue. ");
        }
      })
    handleClose()
  }

  const [doorName, setDoorName] = useState(props.data.doorName)

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({ parameters: { name: event.target.value } })
    setDoorName(event.target.value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setDoorName(props.data.doorName)
    setOpen(false)
  }

  return (
    <div>
      <div onClick={handleOpen}>
        {/* <AddDeviceIcon /> */}
        {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
          <AddDeviceIconUSA />
        ) : (
          <AddDeviceIcon />
        )}
      </div>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
              {t("Device")}
          </span>
      }
        acceptText="Submit"
        cancelText="Cancel"
        fullWidth={true}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid container justifyContent="center" alignItems={'center'} style={{ backgroundColor: darkMode ? '#0F273B' : '' }}>
          <Grid item xs={12} sm={9} style={{ marginTop: '20px' }}>
            <CustomTextfield
              label="Door Name *"
              name="name"
              value={doorName}
              type={'text'}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={12} sm={9} style={{ marginTop: '20px' }}>
            <CustomTextfield
              label="Door Number *"
              name="sn"
              disabled
              value={props.data.doorNo}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  )
}
