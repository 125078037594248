import { makeStyles } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  styled
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import isoCountries from 'i18n-iso-countries'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import ProfileContext from '../../components/ProfileContext'
import Toast from '../../components/ToastContainer/CustomToast'
import httpCommon from '../../config/http-common'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import { listHost } from '../../slices/HostSlice'
import { createInvitation } from '../../slices/Invitations/InvitationsSlice'
import { listVisitorTypeZlink } from '../../slices/PurposeTypeUSA/PurposeTypeUSASlice'
import { getAllSites } from '../../slices/Site/SiteSlice'
import { getUserInfoPro } from '../../slices/User/UserSlice1'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import CustomPhones from '../OnBoardV2/CustomPhones'
import './customAutocomplete.css'


const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },

}))

// const CustomPopper = styled(Popper)(({ darkMode }) => ({
//   "& .MuiAutocomplete-listbox": {
//     backgroundColor: darkMode ? "#0F273B" : "#fff", // Dropdown background color
//     color: darkMode ? "#fff" : "#000", // Dropdown text color
//   },
//   "& .MuiAutocomplete-option": {
//     backgroundColor: darkMode ? "#0F273B" : "#fff", // Option background color
//     "&[aria-selected='true']": {
//       backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Background color when an option is selected
//     },
//     "&:hover": {
//       backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Hover background color
//     },
//   },
// }));

// // Custom Paper component to apply styles to the "No Options found" text
// const CustomPaper = styled(Paper)(({ darkMode }) => ({
//   backgroundColor: darkMode ? "#0F273B" : "#fff", // Background color for the dropdown container
//   color: darkMode ? "#fff" : "#000", // Text color for the "No Options found" text
// }));

const CustomPaper = styled(Paper)(({ darkMode }) => ({
  backgroundColor: darkMode ? '#6D7473' : '#fff',
  color: darkMode ? '#fff' : '#000',
  '& .MuiAutocomplete-option': {
    backgroundColor: darkMode ? '#0F273B' : '#fff',
    '&[aria-selected="true"]': {
      backgroundColor: darkMode ? '#0D1926' : '#e6f7ff',
    },
    '&:hover': {
      backgroundColor: darkMode ? '#0D1926' : '#e6f7ff',
    },
  },
}));

export default function CreateNewInvitation(props) {
  const classes = useStyles()
  const { open } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedHost, setSelectHost] = useState({})
  const [data, setData] = useState()
  const [hostList, setHostList] = useState([])
  const [siteData, setSiteData] = useState([])
  const [visitorData, setVisitorData] = useState([])
  const [changeDate, setDateChange] = useState(true)
  const [userPhone, setUserPhone] = useState('')
  const { setCurrentTotalVisitCount } = useContext(ProfileContext)
  const [country, setCountry] = useState('')
  const { darkMode } = useContext(ThemeContext);
  const textFieldStyle = {
    backgroundColor: darkMode ? '#0F273B' : '#fff',
    color: darkMode ? '#fff' : '#434547'
  };
  var minutes =
    props.invitationIntervalTime === ''
      ? 30
      : Number(props.invitationIntervalTime)
  const [invitationIntervalTime, setInvitationIntervalTime] = useState(minutes)

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  var today = new Date()

  const zlink = localStorage.getItem('redirectFrom')



  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  }

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    phoneCountryCode: '',
    purposeTypeId: '',
    visitorTypeId: '',
    hostId: '',
    visitorCompanyName: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(
      moment(startDate).add(invitationIntervalTime, 'minutes'),
    ),
    allowLateCheckOut: false,
    visitMode: 'INVITATION',
    earlyCheckin: false,
    approvalReason: '',
    verifyTypeIn: '',
    walkInScan: '',
    invitationScan: '',
    selfScan: '',
    profilePhoto: 'str.jpg',
  })

  const [phoneInput, setPhoneInput] = useState('')

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    visitorTypeId: false,
    hostId: false,
    visitorCompanyName: false,
    siteId: false,
    selectedHost: false,
    profilePhoto: false,
    approvalReason: false,
  })

  const handleClose = () => {
    props.handleDialogClose()
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    loadData(props.open)
  }, [])

  const loadData = () => {
    loadVisitType()
    loadHosts()
    loadSites()
    loadVisitorType()
    loadUser()
    loadCompany()
  }

  const loadUser = () => {
    dispatch(getUserInfoPro(decoded_jwtToken.userId))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000' || data.code === 'UASI0033') {
          setUserPhone(data.data.phone)
        }
      })
      .catch((er) => { })
  }

  const loadVisitType = () => {
    const query = {
      id: '',
      name: '',
      status: 'ACTIVE',
      pageNumber: 0,
      pageSize: 0,
      isPageable: true,
    }
    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setData(data.data.purposeTypes)
          setDateChange(true)
        } else {
          setData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 20,
      email: decoded_jwtToken.email,
    }
    dispatch(listHost(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'OMSI0000') {
          getSelectedItem(data.data.employees[0])
          setHostList(data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  const handleText = (event) => {
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=1&pageSize=0&firstName=' +
        event.target.value,
        config,
      )
      .then((data) => {
        if (data.data.code === 'OMSI0000') {
          setHostList(data.data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email, lastName }) =>
      `${firstName} ${email} ${lastName}`,
    limit: 1,
  })

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      dispatch(getListSettings())
        .unwrap()
        .then((data) => {
          setPayload({
            ...payload,
            verifyTypeIn: data.data.invitationVerifyMode,
            invitationScan: data.data.invitationScanLimit,
            hostId: newValue.id,
          })
        })
      setPayload({
        ...payload,
        hostId: newValue.id,
      })
      setSelectHost(newValue)
    } else {
      setPayload({
        ...payload,
        hostId: '',
      })
      setSelectHost({})
    }
  }

  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
        } else {
          setSiteData([])
        }
      })
  }

  const handleSumbit = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ firstName: true })
      return
    }
    if (!CommonUtil.isValidNames(payload.firstName)) {
      Toast('Please Enter Valid First Name ', 'error')
      setError({ firstName: true })
      return
    }
    if (
      CommonUtil.isEmptyString(payload.emailId) &&
      CommonUtil.isEmptyString(payload.phoneNumber)
    ) {
      Toast(t('USERPROFSCREEN014'), 'error')
      setError({ emailId: true })
      return
    }
    if (
      decoded_jwtToken.email === payload.emailId.trim() ||
      payload.emailId.trim() === selectedHost.email
    ) {
      setError({ emailId: true })
      Toast(t('INVITATIONFORM006'), 'error')
      return
    }
    if (
      payload.phoneNumber !== null &&
      payload.phoneNumber !== undefined &&
      payload.phoneNumber !== ''
    ) {
      if (
        userPhone === payload.phoneCountryCode + '-' + payload.phoneNumber ||
        payload.phoneCountryCode + '-' + payload.phoneNumber ===
        selectedHost.phone
      ) {
        setError({ phoneNumber: false })
        Toast(t('INVITATIONFORM009'), 'error')
        return
      }
    }

    if (CommonUtil.isEmpty(selectedHost)) {
      Toast(t('INVITATIONFORM007'), 'error')
      setError({ selectedHost: true })
      return
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ''
    ) {
      setError({ scheduledStartDate: true })
      Toast(t('INVITATIONFORM011'), 'error')
      return
    }
    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ''
    ) {
      setError({ scheduledEndDate: true })
      Toast(t('INVITATIONFORM012'), 'error')
      return
    }
    if (
      new Date(payload.scheduledStartDate) < new Date() &&
      new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
    ) {
      var dt = new Date()
      var addMinutes = new Date().setMinutes(dt.getMinutes())
      var currentDate = CommonUtil.formatToUtc(addMinutes)
      const dto = {
        firstName: !CommonUtil.isEmpty(payload.firstName)
          ? payload.firstName
          : undefined,
        lastName: !CommonUtil.isEmpty(payload.lastName)
          ? payload.lastName
          : undefined,
        purposeTypeId: payload.purposeTypeId.id,
        visitorTypeId: payload.visitorTypeId.id,
        hostId: payload.hostId,
        phoneCountryCode:
          !CommonUtil.isEmpty(payload.phoneCountryCode) &&
            !CommonUtil.isEmpty(payload.phoneNumber)
            ? payload.phoneCountryCode
            : undefined,
        phoneNumber: !CommonUtil.isEmpty(payload.phoneNumber)
          ? payload.phoneNumber
          : undefined,
        scheduledStartDate: currentDate,
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        emailId: !CommonUtil.isEmpty(payload.emailId)
          ? payload.emailId
          : undefined,
        visitMode: payload.visitMode,
        verifyTypeIn: payload.verifyTypeIn,
        invitationScan: payload.invitationScan,
        walkInScan: payload.walkInScan,
        selfScan: payload.selfScan,
        visitorCompanyName: payload.visitorCompanyName,
        siteId: payload.siteId.id,
        approvalReason: payload.approvalReason,
        profilePhoto: payload.profilePhoto,
      }
      dispatch(createInvitation(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0067') {
            Toast(t('INVSENTSUCCESS'), 'success')
            setCurrentTotalVisitCount(data.data.currentVisitCount)
            props.loadData()
            handleClose()
          } else {
            onError(data)
          }
        })
    } else if (
      new Date(payload.scheduledStartDate) > new Date() &&
      new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
    ) {
      const dto = {
        firstName: !CommonUtil.isEmpty(payload.firstName)
          ? payload.firstName
          : undefined,
        lastName: !CommonUtil.isEmpty(payload.lastName)
          ? payload.lastName
          : undefined,
        purposeTypeId: payload.purposeTypeId.id,
        visitorTypeId: payload.visitorTypeId.id,
        hostId: payload.hostId,
        phoneCountryCode: !CommonUtil.isEmpty(payload.phoneCountryCode)
          ? payload.phoneCountryCode
          : undefined,
        phoneNumber: !CommonUtil.isEmpty(payload.phoneNumber)
          ? payload.phoneNumber
          : undefined,
        scheduledStartDate: currentDate,
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        emailId: !CommonUtil.isEmpty(payload.emailId)
          ? payload.emailId
          : undefined,
        profilePhoto: payload.profilePhoto,
        visitorCompanyName: payload.visitorCompanyName,
        siteId: payload.siteId.id,
        scheduledStartDate: CommonUtil.formatToUtc(payload.scheduledStartDate),
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        visitMode: payload.visitMode,
      }
      dispatch(createInvitation(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0067' || data.code === 'CVAI1007') {
            Toast(t('INVSENTSUCCESS'), 'success')
            props.loadData()
            handleClose()
          } else {
            onError(data)
          }
        })
    } else {
      Toast(t('INVITATIONFORM003'), 'error')
    }
  }

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case 'CVAE1015':
          setError({ emailId: true })
          break
        case 'CVAE1016':
          setError({ emailId: true })
          break
        case 'CVAE1012':
          setError({ firstName: true })
          break
        case 'CVAE1013':
          setError({ firstName: true })
          break
        case 'CVAE1017':
          setError({ phoneNumber: true })
          break
        case 'CVAE1041':
          setError({ purposeTypeId: true })
          break
        case 'CVAE1050':
          setError({ visitorTypeId: true })
          break
        case 'CVAE1051':
          setError({ visitorCompanyName: true })
          break
        case 'CVAE1019':
          setError({ hostId: true })
          break
        case 'CVAE1021':
          setError({ scheduledStartDate: true })
          break
        case 'CVAE1022':
          setError({ scheduledEndDate: true })
          break
        case 'CVAE1023':
          setError({ scheduledEndDate: true })
          break
        case 'CVAE0300':
          setError({ siteId: true })
          break
        default:
          setError({ ...error })
      }
    Toast(data.message, 'error')
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ''),
    })
    setError({ phoneNumber: false })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event,
    })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    })
  }

  const purposeTypeToggle = props.purposeTypeToggle
  const visitorTypeToggle = props.visitorTypeToggle
  const companyNameToggle = props.companyNameToggle
  const siteTypeToggle = props.siteTypeToggle
  const purposeTypeMandatory = props.purposeTypeMandatory
  const visitorTypeMandatory = props.visitorTypeMandatory
  const companyNameMandatory = props.companyNameMandatory
  const siteTypeMandatory = props.siteTypeMandatory


  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);


  return (
    <Dialog
      open={open}
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: '20px !important',
          minWidth: '50vw',
          backgroundColor: darkMode ? '#0F273B' : '#fff',
          color: darkMode ? '#fff' : '#434547'
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
        },
      }}
    >
      <DialogTitle variant="h4" onClick={handleClose}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography style={{ color: darkMode ? '#fff' : 'black', fontSize: '20px', fontWeight: '450' }}>
          {t('buttonVisitorInvitationNewInvite')}
        </Typography>

      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ padding: '16px 32px', width: '100%' }}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('USER0001')}
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
              required={true}
              autoComplete="off"
              // inputProps={{ className: classes.input }}
              InputProps={{
                style: {
                  color: darkMode ? '#fff' : 'black',
                },
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('USER0002')}
              error={error.lastName}
              name="lastName"
              value={payload.lastName}
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              autoComplete="off"
              // inputProps={{ className: classes.input }}
              InputProps={{
                style: {
                  color: darkMode ? '#fff' : 'black',
                },
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('VISUPDATE0001')}
              error={error.emailId}
              name="emailId"
              value={payload.emailId}
              handleChange={(e) => handleChange(e)}
              helperText={error.emailId}
              validation="email"
              autoComplete="off"
              // inputProps={{ className: classes.input }}
              InputProps={{
                style: {
                  color: darkMode ? '#fff' : 'black',
                },
              }}

            />
          </Grid>

          <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
            <CustomPhones
              placeholder="Mobile"
              value={phoneInput}
              country={country}
              specialLabel={false}
              handleChange={(value, data, event, formattedValue) =>
                handlePhoneChange(value, data, event, formattedValue)
              }
            // style={{ backgroundColor: 'red' }} 

            />
          </Grid>

          {purposeTypeToggle ? (
            <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
              <Autocomplete
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name="purposeTypeId"
                value={payload.purposeTypeId}
                options={CommonUtil.isEmptyArray(data) ? [] : data}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    onselectPurposeType(newValue)
                  } else {
                    onselectPurposeType('')
                  }
                  setError({ purposeTypeId: false })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="purposeTypeId"
                    value={payload.purposeTypeId}
                    error={error.purposeTypeId}
                    variant="outlined"
                    fullWidth
                    label={
                      purposeTypeMandatory
                        ? t('PURPOSELABEL1') + ' *'
                        : t('PURPOSELABEL1')
                    }

                    InputLabelProps={{
                      style: {
                        color: darkMode ? '#fff' : '#0F273B',
                      },
                    }}

                    InputProps={{
                      ...params.InputProps,
                      style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                    }}

                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}

          {visitorTypeToggle ? (
            <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
              <Autocomplete
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name="visitorTypeId"
                value={payload.visitorTypeId}
                options={
                  CommonUtil.isEmptyArray(visitorData) ? [] : visitorData
                }
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    onselectVisitorType(newValue)
                  } else {
                    onselectVisitorType('')
                  }
                  setError({ visitorTypeId: false })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="visitorTypeId"
                    value={payload.visitorTypeId}
                    error={error.visitorTypeId}
                    variant="outlined"
                    fullWidth
                    label={
                      visitorTypeMandatory
                        ? t('COMMON068') + ' *'
                        : t('COMMON068')
                    }
                    InputLabelProps={{
                      style: {
                        color: darkMode ? '#fff' : '#0F273B',
                      },
                    }}

                    InputProps={{
                      ...params.InputProps,
                      style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                    }}

                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                        },
                      },
                    }}

                  />
                )}
              />
            </Grid>
          ) : null}

          {companyNameToggle ? (
            <Grid item lg={6} sm={12}>
              <CustomTextfield
                label={
                  companyNameMandatory
                    ? t('VISUPDATE0006') + ' *'
                    : t('VISUPDATE0006')
                }
                error={error.visitorCompanyName}
                name="visitorCompanyName"
                value={payload.visitorCompanyName}
                handleChange={(e) => handleChange(e)}
                helperText={error.visitorCompanyName}
                // inputProps={{ className: classes.input }}
                // validation="alphabets"
                InputProps={{
                  style: {
                    color: darkMode ? '#fff' : 'black',
                  },
                }}
              />
            </Grid>
          ) : null}

          <Grid item lg={6} sm={12} style={{ margin: '16px 0px' }}>
            <Autocomplete
              style={{
                margin: '-15px',
                marginLeft: '1px',
                color: '#242424',
              }}
              id="free-solo-demo"
              freeSolo
              noOptionsText={'No Options found'}
              fullWidth
              filterOptions={filterOptions}
              options={CommonUtil.isEmptyArray(hostList) ? [] : hostList}
              getOptionLabel={(option) =>
                option.lastName
                  ? option.firstName + ' ' + option.lastName
                  : option.firstName
                    ? option.firstName
                    : ''
              }
              value={selectedHost}
              onChange={(event, newValue) => {
                getSelectedItem(newValue);
                setError({ selectedHost: false });
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label={t('commonsTextFeildHost')}

                  required={true}
                  placeholder={t('commonsTextFeildHost')}
                  name="hostId"
                  key="Confirmation Code"
                  id="hostId"
                  handleChange={(e) => handleText(e)}
                  error={error.selectedHost}
                />
              )}
            />
          </Grid>


          <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                  scheduledEndDate: CommonUtil.formatToUtc(
                    moment(scheduledStartDate).add(
                      invitationIntervalTime,
                      'minutes',
                    ),
                  ),
                })
              }
              disablePast
              value={payload.scheduledStartDate}
              label={t('INVITESTARTDATEERROR1')}
              changeDate={changeDate}
              date={props.date}

            />
          </Grid>
          <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
            <CustomDateTimePicker
              onChange={(scheduledEndDate) =>
                setPayload({
                  ...payload,
                  scheduledEndDate: scheduledEndDate,
                })
              }
              disablePast
              value={payload.scheduledEndDate}
              // minDateTime={payload.scheduledStartDate}
              label={t('INVITESTARTDATEERROR2')}
              changeDate={changeDate}
              date={props.date}
            />
          </Grid>

          {siteTypeToggle ? (
            <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
              <Autocomplete
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name="siteId"
                value={payload.siteId}
                options={CommonUtil.isEmptyArray(siteData) ? [] : siteData}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    setPayload({ ...payload, siteId: newValue })
                  } else {
                    setPayload({ ...payload, siteId: '' })
                  }
                  setError({ siteId: false })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="siteId"
                    value={payload.siteId}
                    error={error.siteId}
                    variant="outlined"
                    fullWidth
                    label={
                      siteTypeMandatory ? t('SITE013') + ' *' : t('SITE013')
                    }
                    InputLabelProps={{
                      style: {
                        color: darkMode ? '#fff' : '#0F273B',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                    }}

                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}

          <Grid
            item
            lg={
              (Number(!purposeTypeToggle) +
                Number(!visitorTypeToggle) +
                Number(!companyNameToggle) +
                Number(!siteTypeToggle)) %
                2 ===
                1
                ? 12
                : 6
            }
            sm={12}

            style={{ marginTop: "15px" }}
          >
            <CustomTextfield
              label={t('COMMON016')}
              error={error.approvalReason}
              name="approvalReason"
              value={payload.approvalReason}
              validation="restrictLength"
              handleChange={(e) => handleChange(e)}
              helperText={error.approvalReason}
              style={{ marginTop: "0px" }}
              InputProps={{
                style: {
                  color: darkMode ? '#fff' : 'black',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
        }}
      >
        <SubmitButtons variant="contained" onClick={handleSumbit}>
          <Typography variant="body1" style={{ textTransform: 'none' }}>
            {t('COMMON007')}
          </Typography>
        </SubmitButtons>
        <CancelButtons
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          <Typography variant="body1" style={{ textTransform: 'none' }}>
            {t('COMMON008')}
          </Typography>
        </CancelButtons>
      </DialogActions>
    </Dialog>
  )
}
